import { useLottie } from "lottie-react";
import "./style.less";
import loading from "../../assets/lotties/loading.json";

const LottieWaiting = () => {
    const options = {
        animationData: loading,
        loop: true,
        autoplay: true
    };
    const { View } = useLottie(options);
    return <div className="lottie-loading">{View}</div>
}

export default LottieWaiting