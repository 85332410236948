import { useState, createRef, useEffect } from "react"
import { Button, Drawer, Row, Col, Typography, Form, Select, Input, Divider, Tooltip } from "antd"
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons"
import { useParams } from "react-router-dom"

// external-component
import Notification from "../../../components/Notification/Notification"
import MySpin from "../../../components/Spin/Spin"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listElementCategorysByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateElementCategory,
    onUpdateElementCategory,
    onDeleteElementCategory
} from "../../../graphql/subscriptions"

// mutation-update
import { updateElement } from "../../../graphql/mutations"

const { Title } = Typography
const { Option } = Select;

const UpdateElementDrawer = (props) => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [elementCategories, setElementCategories] = useState(null)
    const [elementCategory, setElementCategory] = useState(null)
    const { id: projectId } = useParams()

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    const [form] = Form.useForm()
    let formRef = createRef()

    const {
        id,
        elementName,
        elementCategoryElementId
    } = props.data || {}

    useEffect(() => {
        form.setFieldsValue({
            elementName: elementName,
            elementCategoryElementId: elementCategoryElementId
        })
    }, [])

    // execute query 
    useEffect(() => {
        queryElementCategories()
    }, [elementCategory])

    // execute subscription
    useEffect(() => {
        subOnCreateElementCategory()
        subOnUpdateElementCategory()
        subOnDeleteElementCategory()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query elementCategories
    const queryElementCategories = async () => {
        try {
            const elementCategoryData = await API.graphql(graphqlOperation(listElementCategorysByCreatedDate, {
                typeCreated: "ElementCategoryCreated",
                sortDirection: "DESC",
            }))
            const { data: { listElementCategorysByCreatedDate: { items } } } = elementCategoryData
            setElementCategories(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create 
    const subOnCreateElementCategory = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateElementCategory))
                .subscribe({
                    next: ({ value }) => {
                        setElementCategory(value.data.onCreateElementCategory)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update 
    const subOnUpdateElementCategory = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateElementCategory))
                .subscribe({
                    next: ({ value }) => {
                        setElementCategory(value.data.onUpdateElementCategory)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete 
    const subOnDeleteElementCategory = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteElementCategory))
                .subscribe({
                    next: ({ value }) => {
                        setElementCategory(value.data.onDeleteElementCategory)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    const validateMessages = {
        required: '${label} is required!'
    };

    const onFinish = async (values) => {
        try {
            values.id = id
            setIsLoading(true)
            await API.graphql(graphqlOperation(updateElement, { input: values }))
            Notification("success", "Success", "Element update successfully!")
            setIsLoading(false)
            closeDrawer()
        } catch (error) {
            console.log(error)
            Notification("error", "Failed", error.message)
        }
    };

    const closeDrawer = () => {
        setVisible(false);
    };
    return (
        <span>
            <Button
                type="link"
                size="large"
                icon={<EditOutlined style={{ fontSize: 20 }} />}
                onClick={() => setVisible(true)}
                style={{ marginLeft: -15, marginRight: 15 }}
            />
            <Drawer
                title="Update Element"
                placement="right"
                onClose={closeDrawer}
                visible={visible}
                width="500"
            >
                {
                    isLoading ? <MySpin /> :
                        <Form form={form} ref={formRef} name="nest-messages" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item name="elementCategoryElementId" label="Category" rules={[{ required: true }]}>
                                        <Select
                                            size="large"
                                        >
                                            {
                                                elementCategories && elementCategories.map((category, key) => {
                                                    const { id, categoryName } = category
                                                    return <Select.Option key={key} value={id}>{categoryName}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="elementName" label="Element Name" rules={[{ required: true }]}>
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button icon={<EditOutlined />} block size="large" type="primary" htmlType="submit">
                                            Update
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </span>
    )
}

export default UpdateElementDrawer