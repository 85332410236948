import { useState, createRef } from "react"
import { Button, Drawer, Row, Col, Form, TimePicker, Divider } from "antd"
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons"
import moment from "moment"

// external-component
import Notification from "../../../components/Notification/Notification"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// mutation-update
import { updateTime } from "../../../graphql/mutations"
import MySpin from "../../../components/Spin/Spin"

const UpdateTimeDrawer = ({ data }) => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()
    let formRef = createRef()

    const { id, time } = data

    const validateMessages = {
        required: '${label} is required!'
    };

    const onFinish = async (values) => {
        try {
            values.id = id
            setIsLoading(true)
            await API.graphql(graphqlOperation(updateTime, { input: values }))
            Notification("success", "Success", "Time update successfully!")
            setIsLoading(false)
            closeDrawer()
        } catch (error) {
            console.log(error)
            Notification("error", "Failed", error.message)
        }
    };

    const closeDrawer = () => {
        setVisible(false);
    };

    return (
        <div>
            <Button
                type="primary"
                icon={<EditOutlined />}
                style={{ float: "right" }}
                onClick={() => setVisible(true)}
            />
            <Drawer
                title="Update Time"
                onClose={closeDrawer}
                visible={visible}
                width="500"
            >
                {
                    isLoading ? <MySpin /> :
                        <Form
                            form={form}
                            ref={formRef}
                            name="nest-messages"
                            layout="vertical"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                            fields={[
                                {
                                    name: ["time"],
                                    value: time ? moment(time) : null
                                }
                            ]}
                        >
                            <Form.Item name="time" label="Time" rules={[{ required: true }]}>
                                <TimePicker defaultValue={moment(time, 'HH:mm')} format="HH:mm" size="large" style={{ width: "100%" }} />
                            </Form.Item>
                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button icon={<EditOutlined />} block size="large" type="primary" htmlType="submit">
                                            Update
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </div>
    )
}

export default UpdateTimeDrawer