import { useState, useEffect } from "react"
import { Col, Collapse, Row, Card, Typography } from "antd"
import "./UserList.less"

// internal-component
import CreateUserDrawer from "./CreateUserDrawer"
import UpdateUserDrawer from "./UpdateUserDrawer"
import UserRoleList from "./UserRoleList"

// external-component
import ViewImage from "../../../components/ViewImage/ViewImage"
import LottieLoading from "../../../components/LottieLoading/LottieLoading"

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listUsersByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateUser,
    onUpdateUser,
    onDeleteUser
} from "../../../graphql/subscriptions"

const { Panel } = Collapse
const { Title, Text } = Typography

const UserList = () => {
    const [users, setUsers] = useState([])
    const [user, setUser] = useState(null)

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    // execute query car parts
    useEffect(() => {
        queryUsers()
    }, [user])

    // execute subscription
    // useEffect(() => {
    //     subOnCreateUser()
    //     subOnUpdateUser()
    //     subOnDeleteUser()
    //     return () => {
    //         subscriptionOnCreate.unsubscribe()
    //         subscriptionOnUpdate.unsubscribe()
    //         subscriptionOnDelete.unsubscribe()
    //     }
    // }, [])

    // query users
    const queryUsers = async (searchParam) => {
        try {
            const userData = await API.graphql(graphqlOperation(listUsersByCreatedDate, {
                typeCreated: "UserCreated",
                sortDirection: "DESC",
            }))
            const { data: { listUsersByCreatedDate: { items } } } = userData
            setUsers(items)
        } catch (error) {
            console.error(error)
        }
    }

    // handle search subModel
    const handleSearch = (e) => {
        try {
            queryUsers(e.target.value)
        } catch (error) {
            console.error(error)
            Notification("error", "Failed", error.message)
        }
    }

    // sub create subModel
    const subOnCreateUser = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onCreateUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update subModel
    const subOnUpdateUser = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onUpdateUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete subModel
    const subOnDeleteUser = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onDeleteUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    if (!users.length) return <LottieLoading />
    if (users.length === 0) return <div>No data</div>
    if (users) {
        return (
            <Row gutter={24}>
                <Col span={23} style={{ float: "right" }}>
                    <CreateUserDrawer />
                </Col>
                <Col span={24} style={{ padding: 30 }}>
                    {
                        users.map((data, index) => {
                            const {
                                firstName,
                                lastName,
                                email,
                                avatar,
                                department,
                                position,
                                role
                            } = data
                            return (
                                <Card
                                    key={index}
                                    className="card-container"
                                    bordered={false}
                                >
                                    <Row gutter={24}>
                                        <Col span={2}>
                                            <ViewImage
                                                fileImage={avatar}
                                                storagePath="avatars"
                                                className="avatar"
                                            />
                                        </Col>
                                        <Col span={3}>
                                            <Title level={5}>First name</Title>
                                            <Text className="user-info-label">{firstName}</Text>
                                        </Col>
                                        <Col span={3}>
                                            <Title level={5}>Last name</Title>
                                            <Text className="user-info-label">{lastName}</Text>
                                        </Col>
                                        <Col span={4}>
                                            <Title level={5}>Email</Title>
                                            <Text className="user-info-label">{email}</Text>
                                        </Col>
                                        <Col span={7}>
                                            <Title level={5}>Department</Title>
                                            <Text className="user-info-label">{department ? department.departmentName : "-"}</Text>
                                        </Col>
                                        <Col span={3}>
                                            <Title level={5}>Position</Title>
                                            <Text className="user-info-label">{position ? position.positionName : "-"}</Text>
                                        </Col>
                                        <Col span={2}>
                                            <UpdateUserDrawer data={data} />
                                        </Col>
                                    </Row>
                                    {
                                        role ? <UserRoleList role={role} /> : null
                                    }
                                </Card>
                            )
                        })
                    }
                </Col>
            </Row>
        )
    }
}

export default UserList