import { useState, createRef } from "react"
import { Button, Tooltip, Drawer, Row, Col, Typography, Form, Select, Divider } from "antd"
import { CloseCircleOutlined, SaveOutlined, DeleteOutlined, WarningOutlined } from "@ant-design/icons"

// external-component
import Notification from "../../../components/Notification/Notification"
import MySpin from "../../../components/Spin/Spin"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listUsersByCreatedDate } from "../../../graphql/queries"

// mutation-delete
import { deleteRole } from "../../../graphql/mutations"

const { Text, Title } = Typography

const DeleteRoleDrawer = (props) => {
    const { data, title, visible, closeDrawer } = props
    // const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()
    let formRef = createRef()

    const validateMessages = {
        required: '${label} is required!'
    };

    const onFinish = async () => {
        const id = data && data.id
        try {
            const checkRoleUsaged = await API.graphql(graphqlOperation(listUsersByCreatedDate, {
                typeCreated: "UserCreated",
                filter: {
                    roleUserId: {
                        eq: id
                    }
                }
            }))
            const { data: { listUsersByCreatedDate: { items } } } = checkRoleUsaged
            if (items.length >= 1) {
                Notification("warning", "Warning", "Role already usaged. You can't delete it.")
            }
            if (items.length === 0) {
                setIsLoading(true)
                await API.graphql(graphqlOperation(deleteRole, { input: { id } }))
                Notification("success", "Success", "Role delete successfully!")
                closeDrawer()
                setIsLoading(false)
            }
        } catch (error) {
            console.error(error)
            Notification("error", "Failed", error)
        }
    };

    return (
        <span>
            <Drawer
                onClose={closeDrawer}
                visible={visible}
                width="500"
                placement={"right"}
                style={{ position: "absolute" }}
            >
                {
                    isLoading ? <MySpin /> :
                        <Form form={form} ref={formRef} name="nest-messages" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <div className="msg-wrapper">
                                <WarningOutlined style={{ fontSize: 70, marginBottom: 20, color: "#ff787" }} />
                                {/* <WarningOutlined /> */}
                                <Title level={4}>{title}</Title>
                                <Text level={5}>Role: {data && data.roleName}</Text>
                            </div>

                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Button onClick={closeDrawer} icon={<CloseCircleOutlined />} block size="large" type="primary">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button danger icon={<SaveOutlined />} block size="large" htmlType="submit">
                                            Yes
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </span>
    )
}

export default DeleteRoleDrawer