import { useEffect } from "react"
import { Tag } from "antd"

// external-component
import LottieLoading from "../../../components/LottieLoading/LottieLoading"

// apollo-client
import { useQuery } from "@apollo/client"

// graphql-query
import { listSubElementsByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateSubElementByElementId,
    onDeleteSubElementByElementId
} from "../../../graphql/subscriptions"

const CountSubElementByElement = ({ elementId }) => {
    // query element
    const { subscribeToMore, loading, error, data } = useQuery(listSubElementsByCreatedDate, {
        variables: {
            typeCreated: "SubElementCreated",
            filter: {
                elementSubElementId: { eq: elementId }
            },
            limit: 500
        }
    })

    // on create sub-element
    useEffect(() =>
        subscribeToMore({
            document: onCreateSubElementByElementId,
            variables: { elementSubElementId: elementId },
            updateQuery: (prev, { subscriptionData }) => {
                const { listSubElementsByCreatedDate: { items } } = prev
                if (!subscriptionData.data) return prev
                const { onCreateSubElementByElementId: subElementData } = subscriptionData.data
                return {
                    listSubElementsByCreatedDate: {
                        items: [subElementData, ...items],
                        nextToken: null,
                        __typename: "SubElement"
                    }
                }
            }
        }), [subscribeToMore, elementId]
    )

    // on delete sub-element
    useEffect(() =>
        subscribeToMore({
            document: onDeleteSubElementByElementId,
            variables: { elementSubElementId: elementId },
            updateQuery: (prev, { subscriptionData }) => {
                const { listSubElementsByCreatedDate: { items } } = prev
                if (subscriptionData) {
                    const { onDeleteSubElementByElementId: subElementData } = subscriptionData.data
                    const lastItems = items.filter(doc => doc.id !== subElementData.id)
                    return {
                        listSubElementsByCreatedDate: {
                            items: [...lastItems],
                            nextToken: null,
                            __typename: "SubElement"
                        }
                    }
                }
            }
        }), [subscribeToMore, elementId]
    )

    if (loading) return <Tag color="blue">...</Tag>
    if (error) console.log(error)
    if (data) {
        const { listSubElementsByCreatedDate: { items } } = data
        return (
            <Tag color="blue">{items.length}</Tag>
        )
    }
}

export default CountSubElementByElement