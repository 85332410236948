import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom"

// aws-amplify
import { API, Auth, graphqlOperation } from "aws-amplify"

// graphql-query
import { listUsers } from "../../graphql/queries"

const cookies = new Cookies();

export const checkUser = async () => {
    // const navigate = useNavigate();
    try {
        const user = await Auth.currentAuthenticatedUser()
        const { attributes: { email } } = user
        if (user) {
            const userData = await API.graphql(graphqlOperation(listUsers, {
                filter: {
                    email: { eq: email }
                }
            }))
            const { data: { listUsers: { items: userItems } } } = userData
            if (userItems) {
                cookies.set('isAuthenticated', true, { path: '/' });
                cookies.set('userEmail', email, { path: '/' });
                cookies.set('userId', userItems[0].id, { path: '/' });
                cookies.set('roleId', userItems[0].roleUserId, { path: '/' });
            }
        } else {
            cookies.set('isAuthenticated', false, { path: '/' });
        }
    } catch (err) {
        cookies.set('isAuthenticated', false, { path: '/' });
        console.log(err)
    }
}