import { Layout, Typography, Row, Col } from 'antd';
import "../LoginLayout/LoginLayout.less"
import logo from "../../assets/images/batigc-logo.png"

// external-component
import RegisterForm from '../LoginLayout/components/RegisterForm/RegisterForm';

const { Title } = Typography

const RootLayout = () => {
    return (
        <Layout className="login-wrapper">
            <Row>
                <Col span={12}>
                    <img src={logo} className="login-logo" />
                </Col>
                <Col span={12} style={{ marginTop: "8%" }}>
                    <Title level={2}>Root User Register</Title>
                    <RegisterForm />
                </Col>
            </Row>
        </Layout>
    )
}

export default RootLayout