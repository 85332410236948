import { useState, useEffect } from "react"
import { Table, Space, Form, Input, Col, Row, Divider, Button } from "antd"
import { FilterOutlined } from '@ant-design/icons'
import moment from "moment"
import { useParams } from "react-router-dom"
import Cookies from 'universal-cookie';

// external-component
import LottieLoading from "../../../../components/LottieLoading/LottieLoading"
import SelectProject from "../../../../components/Select/SelectProject"
import SelectElement from "../../../../components/Select/SelectElement"
import ViewProjectInfo from "../../../../components/ViewProjectInfo/ViewProjectInfo"
import ViewElementInfo from "../../../../components/ViewElementInfo/ViewElementInfo"

// internal-component
import ExportToExcel from "./ExportToExcel"

// apollo-client
import { useQuery, useLazyQuery } from "@apollo/client"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import {
    listSubElementsByCreatedDate,
    listUsersByCreatedDate,
    listRolesByCreatedDate
} from "../../../../graphql/queries"

// graphql-sub
import {
    onCreateSubElement,
    onUpdateSubElement,
    onDeleteSubElement,
    onUpdateUser,
    onUpdateRole
} from "../../../../graphql/subscriptions"

const { Column, ColumnGroup } = Table
const cookies = new Cookies();

const SubElementReport = (props) => {
    const [user, setUser] = useState(null)
    const [roleId, setRoleId] = useState(null)
    const [roles, setRoles] = useState(null)
    const [role, setRole] = useState(null)
    const userEmail = cookies.get('userEmail')

    const { projectId, elementCategoryId, elementId } = useParams()

    let subscriptionOnUpdateUser;
    let subscriptionOnUpdateRole;

    // execute-user-and-role
    useEffect(() => {
        subOnUpdateUser()
        subOnUpdateRole()
        return () => {
            subscriptionOnUpdateUser.unsubscribe()
            subscriptionOnUpdateRole.unsubscribe()
        }
    }, [])

    useEffect(() => {
        queryUser()
    }, [user])

    useEffect(() => {
        queryRole()
    }, [role, roleId])

    const queryUser = async () => {
        try {
            const userData = await API.graphql(graphqlOperation(listUsersByCreatedDate, {
                typeCreated: "UserCreated",
                filter: {
                    email: { eq: userEmail }
                }
            }))
            const userItem = userData.data.listUsersByCreatedDate.items[0]
            setRoleId(userItem.roleUserId)
        } catch (err) {
            console.log(err)
        }
    }

    const queryRole = async () => {
        try {
            const roleData = await API.graphql(graphqlOperation(listRolesByCreatedDate, {
                typeCreated: "RoleCreated",
                filter: {
                    id: { eq: roleId ? roleId : null }
                }
            }))
            const roleItem = roleData.data.listRolesByCreatedDate.items[0]
            setRoles(roleItem)
        } catch (err) {
            console.log(err)
        }
    }

    // sub-update-user
    const subOnUpdateUser = async () => {
        try {
            subscriptionOnUpdateUser = await API.graphql(graphqlOperation(onUpdateUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onUpdateUser)
                    }
                })
        } catch (err) {
            console.log(err)
        }
    }

    // sub-update-role
    const subOnUpdateRole = async () => {
        try {
            subscriptionOnUpdateRole = await API.graphql(graphqlOperation(onUpdateRole))
                .subscribe({
                    next: ({ value }) => {
                        setRole(value.data.onUpdateRole)
                    }
                })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        loadSubElement()
    }, [elementId, projectId])

    // query sub element
    const [loadSubElement, { subscribeToMore, loading, error, data }] = useLazyQuery(listSubElementsByCreatedDate, {
        variables: {
            typeCreated: "SubElementCreated",
            sortDirection: "DESC",
            filter: {
                projectSubElementId: { eq: projectId ? projectId : null },
                elementSubElementId: { contains: !elementId ? "" : elementId !== "0" ? elementId : "" }
                // projectSubElementId: { contains: !projectId ? "" : projectId !== "0" ? projectId : "" },
                // elementSubElementId: { contains: !elementId ? "" : elementId !== "0" ? elementId : "" }
            },
            limit: 900
        }
    })

    // on create sub element
    useEffect(() =>
        subscribeToMore({
            document: onCreateSubElement,
            updateQuery: (prev, { subscriptionData }) => {
                const { listSubElementsByCreatedDate: { items } } = prev
                if (!subscriptionData.data) return prev
                const { onCreateSubElement: subElementData } = subscriptionData.data
                return {
                    listSubElementsByCreatedDate: {
                        items: [subElementData, ...items],
                        nextToken: null,
                        __typename: "SubElement"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on update sub element
    useEffect(() =>
        subscribeToMore({
            document: onUpdateSubElement,
            updateQuery: (prev) => {
                const { listSubElementsByCreatedDate: { items } } = prev
                return {
                    listSubElementsByCreatedDate: {
                        items: [...items],
                        nextToken: null,
                        __typename: "SubElement"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on delete sub element
    useEffect(() =>
        subscribeToMore({
            document: onDeleteSubElement,
            updateQuery: (prev, { subscriptionData }) => {
                const { listSubElementsByCreatedDate: { items } } = prev
                if (subscriptionData) {
                    const { onDeleteSubElement: subElementData } = subscriptionData.data
                    const lastItems = items.filter(doc => doc.id !== subElementData.id)
                    return {
                        listSubElementsByCreatedDate: {
                            items: [...lastItems],
                            nextToken: null,
                            __typename: "SubElement"
                        }
                    }
                }
            }
        }), [subscribeToMore]
    )

    if (loading) return <LottieLoading />
    if (error) console.log(error)
    if (data) {
        const { listSubElementsByCreatedDate: { items } } = data
        return (
            <div>
                <ExportToExcel dataSource={items} />
                <Table
                    size="small"
                    // scroll={{ y: 900 }}
                    bordered
                    dataSource={items}
                    pagination={{
                        pageSize: 100,
                    }}
                    scroll={{ y: 490 }}
                >
                    <Column
                        title='Priority'
                        dataIndex='priority'
                        key='priority'
                        fixed='left'
                        align='center'
                    />
                    <Column
                        title='Number of Drawing'
                        dataIndex='numberOfDrawing'
                        key='numberOfDrawing'
                        fixed='left'
                        align='center'
                    // width={30}
                    />
                    <Column
                        title='Ind'
                        dataIndex='index'
                        key='index'
                        align='center'
                    />
                    <Column
                        title='Prov'
                        dataIndex='index'
                        key='index'
                        align='center'
                        render={(_, record) => {
                            const provNumber = record.provNumber ? record.provNumber : ""
                            const provAlphabet = record.provAlphabet ? record.provAlphabet : ""
                            return (
                                `${provNumber}${provAlphabet}`
                            )
                        }}
                    />
                    <ColumnGroup title="CTC" align="center">
                        <Column
                            title="Predicted Date"
                            dataIndex="ctcPredictedDate"
                            key="ctcPredictedDate"
                            align="center"
                            render={(_, record) => {
                                return (
                                    record.ctcPredictedDate ?
                                        moment(record.ctcPredictedDate).format('DD/MM/YYYY') : ""
                                )
                            }}
                        />
                    </ColumnGroup>
                    <ColumnGroup title="Client received on">
                        <Column
                            title="Paper Version"
                            dataIndex="entreprisPaperVersion"
                            key="entreprisPaperVersion"
                            align="center"
                            render={(_, record) => (
                                record.entreprisPaperVersion ?
                                    moment(record.entreprisPaperVersion).format('DD/MM/YYYY') : ""
                            )}
                        />
                        <Column
                            title="Nbr"
                            dataIndex="entreprisNumber"
                            key="entreprisNumber"
                            align="center"
                            render={(_, record) => (
                                record.entreprisNumber ? record.entreprisNumber : ""
                            )}
                        />
                        <Column
                            title="Fax/Internet"
                            dataIndex="entreprisFax"
                            key="entreprisFax"
                            align="center"
                            render={(_, record) => (
                                record.entreprisFax ?
                                    moment(record.entreprisFax).format('DD/MM/YYYY') : ""
                            )}
                        />
                    </ColumnGroup>
                    <ColumnGroup title="Controller received on">
                        <Column
                            title="Paper Version"
                            dataIndex="contractorPaperVersion"
                            key="contractorPaperVersion"
                            align="center"
                            render={(_, record) => (
                                record.contractorPaperVersion ?
                                    moment(record.contractorPaperVersion).format('DD/MM/YYYY') : ""
                            )}
                        />
                        <Column
                            title="Nbr"
                            dataIndex="contractorNumber"
                            key="contractorNumber"
                            align="center"
                            render={(_, record) => (
                                record.contractorNumber ? record.contractorNumber : ""
                            )}
                        />
                        <Column
                            title="Fax/Internet"
                            dataIndex="contractorFax"
                            key="contractorFax"
                            align="center"
                            render={(_, record) => (
                                record.contractorFax ?
                                    moment(record.contractorFax).format('DD/MM/YYYY') : ""
                            )}
                        />
                    </ColumnGroup>
                    <ColumnGroup title="Remard received on">
                        <Column
                            title="Client"
                            dataIndex="observationsEntrepris"
                            key="observationsEntrepris"
                            align="center"
                            render={(_, record) => (
                                record.observationsEntrepris ?
                                    moment(record.observationsEntrepris).format('DD/MM/YYYY') : ""
                            )}
                        />
                        <Column
                            title="Designer"
                            dataIndex="observationsProjectDirector"
                            key="observationsProjectDirector"
                            align="center"
                            render={(_, record) => (
                                record.observationsProjectDirector ?
                                    moment(record.observationsProjectDirector).format('DD/MM/YYYY') : ""
                            )}
                        />
                        <Column
                            title="Controller"
                            dataIndex="observationsConsultant"
                            key="observationsConsultant"
                            align="center"
                            render={(_, record) => (
                                record.observationsConsultant ?
                                    moment(record.observationsConsultant).format('DD/MM/YYYY') : ""
                            )}
                        />
                    </ColumnGroup>
                    <Column
                        title="Dscription"
                        dataIndex="description"
                        key="description"
                        align="center"
                    />
                </Table>
            </div>
        )
    }
    if (!data) {
        return (
            <Table
                size="small"
                bordered
                pagination={{
                    pageSize: 100,
                }}
                scroll={{ y: 490 }}
            >
                <Column
                    title='Priority'
                    fixed='left'
                    align='center'
                />
                <Column
                    title='Number of Drawing'
                    fixed='left'
                    align='center'
                />
                <Column
                    title='Ind'
                    align='center'
                />
                <Column
                    title='Prov'
                    align='center'
                />
                <ColumnGroup title="CTC" align="center">
                    <Column
                        title="Predicted Date"
                        align="center"
                    />
                </ColumnGroup>
                <ColumnGroup title="Client received on">
                    <Column
                        title="Paper Version"
                        align="center"
                    />
                    <Column
                        title="Nbr"
                        align="center"
                    />
                    <Column
                        title="Fax/Internet"
                        align="center"
                    />
                </ColumnGroup>
                <ColumnGroup title="Controller received on">
                    <Column
                        title="Paper Version"
                        align="center"
                    />
                    <Column
                        title="Nbr"
                        align="center"
                    />
                    <Column
                        title="Fax/Internet"
                        align="center"
                    />
                </ColumnGroup>
                <ColumnGroup title="Remard received on">
                    <Column
                        title="Client"
                        align="center"
                    />
                    <Column
                        title="Designer"
                        align="center"
                    />
                    <Column
                        title="Controller"
                        align="center"
                    />
                </ColumnGroup>
                <Column
                    title="Dscription"
                    align="center"
                />
            </Table>
        )
    }
}

export default SubElementReport