import { Timeline, Col, Typography } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const { Title } = Typography

const SubElementRole = ({ item }) => {
    const {
        readAllSubElement,
        readOwnSubElement,
        createSubElement,
        updateSubElement,
        deleteSubElement
    } = item

    return (
        <Col span={5}>
            <Title level={5} className="timeline-header">Sub Element</Title>
            <Timeline>
                <Timeline.Item
                    color={readAllSubElement ? "green" : "red"}
                    dot={readAllSubElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read All Sub Element
                </Timeline.Item>
                <Timeline.Item
                    color={readOwnSubElement ? "green" : "red"}
                    dot={readOwnSubElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read Own Sub Element
                </Timeline.Item>
                <Timeline.Item
                    color={createSubElement ? "green" : "red"}
                    dot={createSubElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Create
                </Timeline.Item>
                <Timeline.Item
                    color={updateSubElement ? "green" : "red"}
                    dot={updateSubElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Update
                </Timeline.Item>
                <Timeline.Item
                    color={deleteSubElement ? "green" : "red"}
                    dot={deleteSubElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Delete
                </Timeline.Item>
            </Timeline>
        </Col>
    )
}

export default SubElementRole