import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

// external-components
import UpdateElementDrawer from "../../Element/Drawers/UpdateElementDrawer"

// aws-amplify
import { API, graphqlOperation } from 'aws-amplify'

// graphql-query
import { getElement } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateElement,
    onUpdateElement,
    onDeleteElement
} from "../../../graphql/subscriptions"

const ProjectElementHeader = () => {
    const [element, setElement] = useState(null)
    const [subElement, setSubElement] = useState(null)
    const { elementId } = useParams()

    // subscribe element
    let subscriptionElementOnCreate;
    let subscriptionElementOnUpdate;
    let subscriptionElementOnDelete;

    // execute query element
    useEffect(() => {
        queryElementById()
    }, [subElement, elementId])

    // execute sub element
    useEffect(() => {
        subOnCreateElement()
        subOnUpdateElement()
        subOnDeleteElement()
        return () => {
            subscriptionElementOnCreate.unsubscribe()
            subscriptionElementOnUpdate.unsubscribe()
            subscriptionElementOnDelete.unsubscribe()
        }
    }, [])

    // query element
    const queryElementById = async () => {
        try {
            if (elementId) {
                const elementData = await API.graphql(graphqlOperation(getElement, {
                    id: elementId
                }))
                setElement(elementData.data.getElement)
            } else {
                setElement(null)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // sub create element
    const subOnCreateElement = async () => {
        try {
            subscriptionElementOnCreate = await API.graphql(graphqlOperation(onCreateElement))
                .subscribe({
                    next: ({ value }) => {
                        setSubElement(value.data.onCreateElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update element
    const subOnUpdateElement = async () => {
        try {
            subscriptionElementOnUpdate = await API.graphql(graphqlOperation(onUpdateElement))
                .subscribe({
                    next: ({ value }) => {
                        setSubElement(value.data.onUpdateElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub delete element
    const subOnDeleteElement = async () => {
        try {
            subscriptionElementOnDelete = await API.graphql(graphqlOperation(onDeleteElement))
                .subscribe({
                    next: ({ value }) => {
                        setSubElement(value.data.onDeleteElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <p>
            {
                element && <>
                    {/* <DeleteRecordDrawer
                                            btnType="link"
                                            iconSize={20}
                                            id={element.id}
                                            module="element"
                                            title={`Delete the project number ${element.elementName}?`}
                                        /> */}
                    {/* <UpdateElementDrawer data={element} /> */}
                    <span style={{ fontSize: 18, fontWeight: "600" }}>
                        Element:
                        <span style={{ color: "#0052F6", marginLeft: 20 }}>
                            {element.elementCategory.categoryName} - {element.elementName}
                        </span>
                    </span>
                </>
            }
        </p>
    )
}

export default ProjectElementHeader