import { useState, useEffect, createRef } from "react"
import { Button, Drawer, Row, Col, Typography, Form, Select, Input, Divider } from "antd"
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons"

// external-component
import Notification from "../../../components/Notification/Notification"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// mutation-update
import { updateDepartment } from "../../../graphql/mutations"
import MySpin from "../../../components/Spin/Spin"

const { Title } = Typography
const { Option } = Select;

const UpdateDepartmentDrawer = (props) => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()
    let formRef = createRef()

    const {
        id,
        departmentName,
    } = props.data || {}

    useEffect(() => {
        form.setFieldsValue({
            departmentName: departmentName
        })
    }, [])

    const validateMessages = {
        required: '${label} is required!'
    };

    const onFinish = async (values) => {
        try {
            values.id = id
            setIsLoading(true)
            await API.graphql(graphqlOperation(updateDepartment, { input: values }))
            Notification("success", "Success", "Department update successfully!")
            setIsLoading(false)
            closeDrawer()
        } catch (error) {
            console.log(error)
            Notification("error", "Failed", error.message)
        }
    };

    const closeDrawer = () => {
        setVisible(false);
    };

    return (
        <div>
            <Button
                type="primary"
                icon={<EditOutlined />}
                style={{ float: "right" }}
                onClick={() => setVisible(true)}
            />
            <Drawer
                title="Update Department"
                onClose={closeDrawer}
                visible={visible}
                width="500"
            >
                {
                    isLoading ? <MySpin /> :
                        <Form form={form} ref={formRef} name="nest-messages" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <Form.Item name="departmentName" label="Department Name" rules={[{ required: true }]}>
                                <Input size="large" />
                            </Form.Item>
                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button icon={<EditOutlined />} block size="large" type="primary" htmlType="submit">
                                            Update
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </div>
    )
}

export default UpdateDepartmentDrawer