import { Timeline, Col, Typography } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const { Title } = Typography

const PositionRole = ({ item }) => {
    const {
        // positionPrivilege,
        readPosition,
        createPosition,
        updatePosition,
        deletePosition,
    } = item

    return (
        <Col span={6}>
            <Title level={5} className="timeline-header">Position</Title>
            <Timeline>
                <Timeline.Item
                    color={readPosition ? "green" : "red"}
                    dot={readPosition ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read
                </Timeline.Item>
                <Timeline.Item
                    color={createPosition ? "green" : "red"}
                    dot={createPosition ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Create
                </Timeline.Item>
                <Timeline.Item
                    color={updatePosition ? "green" : "red"}
                    dot={updatePosition ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Update
                </Timeline.Item>
                <Timeline.Item
                    color={deletePosition ? "green" : "red"}
                    dot={deletePosition ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Delete
                </Timeline.Item>
            </Timeline>
        </Col>
    )
}

export default PositionRole