import { useState, useEffect } from "react"
import { Row, Col, Form, Select } from "antd"
import { useParams } from "react-router-dom"

// external-component
import MySpin from "../../../components/Spin/Spin"

// internal-component
import ElementList from "./ElementList"

// apollo-client
import { useQuery } from "@apollo/client"

// graphql-query
import { listElementCategorysByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateElementCategory,
    onUpdateElementCategory,
    onDeleteElementCategory
} from "../../../graphql/subscriptions"

const { Option } = Select

const SelectElementCategory = ({ collapsed }) => {
    const [elementCategoryId, setElementCategoryId] = useState(null)
    const { id: projectId } = useParams()

    const handleSelectElementCategory = async (values) => {
        setElementCategoryId(values)
    }

    // query element category
    const { subscribeToMore, loading, error, data } = useQuery(listElementCategorysByCreatedDate, {
        variables: {
            typeCreated: "ElementCategoryCreated",
            sortDirection: "DESC"
        }
    })

    // on create element category
    useEffect(() =>
        subscribeToMore({
            document: onCreateElementCategory,
            updateQuery: (prev, { subscriptionData }) => {
                const { listElementCategorysByCreatedDate: { items } } = prev
                if (!subscriptionData.data) return prev
                const { onCreateElementCategory: elementCategoryData } = subscriptionData.data
                return {
                    listElementCategorysByCreatedDate: {
                        items: [elementCategoryData, ...items],
                        nextToken: null,
                        __typename: "ElementCategory"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on update element category
    useEffect(() =>
        subscribeToMore({
            document: onUpdateElementCategory,
            updateQuery: (prev) => {
                const { listElementCategorysByCreatedDate: { items } } = prev
                return {
                    listElementCategorysByCreatedDate: {
                        items: [...items],
                        nextToken: null,
                        __typename: "ElementCategory"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on delete element category
    useEffect(() =>
        subscribeToMore({
            document: onDeleteElementCategory,
            updateQuery: (prev, { subscriptionData }) => {
                const { listElementCategorysByCreatedDate: { items } } = prev
                if (subscriptionData) {
                    const { onUpdateElementCategory: elementCategoryData } = subscriptionData.data
                    const lastItems = items.filter(doc => doc.id !== elementCategoryData.id)
                    return {
                        listElementCategorysByCreatedDate: {
                            items: [...lastItems],
                            nextToken: null,
                            __typename: "Element"
                        }
                    }
                }
            }
        }), [subscribeToMore]
    )

    if (loading) return <MySpin />
    if (error) console.log(error)
    if (data) {
        const { listElementCategorysByCreatedDate: { items } } = data
        return (
            <>
                {/* {!collapsed && projectId && projectId !== "project" && */}
                {projectId && projectId !== "project" &&
                    <Row style={{ marginTop: 60 }}>
                        {
                            !collapsed &&
                            <div style={{ position: "fixed", paddingTop: 5, height: 80, width: 420, background: '#ffffff', zIndex: 99 }}>
                                <Col span={24}>
                                    <Form layout="vertical">
                                        <Form.Item label="Element Category">
                                            <Select
                                                size="large"
                                                style={{ width: "100%" }}
                                                onChange={handleSelectElementCategory}
                                                allowClear={true}
                                            >
                                                {
                                                    items.map((category, key) => {
                                                        const { id, categoryName } = category
                                                        return <Option key={key} value={id}>{categoryName}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </div>
                        }
                        <Col span={24} style={{ marginTop: 90 }}>
                            <ElementList elementCategoryId={elementCategoryId} />
                        </Col>
                    </Row>
                }
            </>
        )
    }
}

export default SelectElementCategory