import { Button } from "antd"
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'

const ButtonCollapse = (props) => {
    const {
        onCollapse,
        collapsed
    } = props

    return (
        <Button
            onClick={onCollapse}
            type={collapsed ? null : "primary"}
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            style={{ marginBottom: 10 }}
        />
    )
}

export default ButtonCollapse