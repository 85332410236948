import { useEffect } from "react"
import { Tag } from "antd"

// external-component
import LottieLoading from "../../../components/LottieLoading/LottieLoading"

// apollo-client
import { useQuery } from "@apollo/client"

// graphql-query
import { listElementsByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateElementByProjectId,
    onDeleteElementByProjectId
} from "../../../graphql/subscriptions"

const CountElementByProject = ({ projectId }) => {
    // query element
    const { subscribeToMore, loading, error, data } = useQuery(listElementsByCreatedDate, {
        variables: {
            typeCreated: "ElementCreated",
            sortDirection: "DESC",
            filter: {
                projectElementId: { eq: projectId }
            },
            limit: 700
        }
    })

    // on create element
    useEffect(() =>
        subscribeToMore({
            document: onCreateElementByProjectId,
            variables: { projectElementId: projectId },
            updateQuery: (prev, { subscriptionData }) => {
                const { listElementsByCreatedDate: { items } } = prev
                if (!subscriptionData.data) return prev
                const { onCreateElementByProjectId: elementData } = subscriptionData.data
                return {
                    listElementsByCreatedDate: {
                        items: [elementData, ...items],
                        nextToken: null,
                        __typename: "Element"
                    }
                }
            }
        }), [subscribeToMore, projectId]
    )

    // on delete element
    useEffect(() =>
        subscribeToMore({
            document: onDeleteElementByProjectId,
            variables: { projectElementId: projectId },
            updateQuery: (prev, { subscriptionData }) => {
                const { listElementsByCreatedDate: { items } } = prev
                if (subscriptionData) {
                    const { onDeleteElementByProjectId: elementData } = subscriptionData.data
                    const lastItems = items.filter(doc => doc.id !== elementData.id)
                    return {
                        listElementsByCreatedDate: {
                            items: [...lastItems],
                            nextToken: null,
                            __typename: "Element"
                        }
                    }
                }
            }
        }), [subscribeToMore, projectId]
    )

    if (loading) return <Tag color="blue">...</Tag>
    if (error) console.log(error)
    if (data) {
        const { listElementsByCreatedDate: { items } } = data
        return (
            <Tag color="blue">{items.length}</Tag>
        )
    }
}

export default CountElementByProject