import { useState, useEffect } from "react"
import { Avatar, Typography } from "antd"

// react-router
import { useParams } from 'react-router-dom'

// aws-amplify
import { API, graphqlOperation, Storage } from "aws-amplify"

// graphql-query
import {
    listProjects,
    listUsers,
    getUser
} from "../../../graphql/queries"

// graphql-sub
import {
    onCreateProject,
    onUpdateProject,
    onDeleteProject
} from "../../../graphql/subscriptions"

const { Text } = Typography

const ProjectMemberAvatar = () => {
    const [projectMembers, setProjectMembers] = useState([])
    const [projectMember, setProjectMember] = useState(null)
    const [users, setUsers] = useState([])
    const [user, setUser] = useState(null)
    const { id: projectId } = useParams()

    // subscribe project member
    let subscriptionProjectMemberOnCreate;
    let subscriptionProjectMemberOnUpdate;
    let subscriptionProjectMemberOnDelete;

    // execute query element
    useEffect(() => {
        queryProjectMemberByProjectId()
    }, [projectId, projectMember])

    // execute project memebr
    useEffect(() => {
        subOnCreateProjectMember()
        subOnUpdateProjectMember()
        subOnDeleteProjectMember()
        return () => {
            subscriptionProjectMemberOnCreate.unsubscribe()
            subscriptionProjectMemberOnUpdate.unsubscribe()
            subscriptionProjectMemberOnDelete.unsubscribe()
        }
    }, [])

    // query project member
    const queryProjectMemberByProjectId = async () => {
        try {
            if (projectId) {
                const projectMemberData = await API.graphql(graphqlOperation(listProjects, {
                    filter: {
                        id: { contains: projectId ? projectId : "" }
                    }
                }))
                const { data: { listProjects: { items } } } = projectMemberData
                // const projectMemberId = items[0].projectMemberId
                // projectMemberId.forEach(async (item) => {
                //     const userData = await API.graphql(graphqlOperation(listUsers, {
                //         filter: {
                //             id: { eq: item }
                //         }
                //     }))
                //     const { data: { listUsers: { items: userItems } } } = userData
                //     console.log(userItems)
                // });
                setProjectMembers(items[0].projectMemberId)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // sub create project manager
    const subOnCreateProjectMember = async () => {
        try {
            subscriptionProjectMemberOnCreate = await API.graphql(graphqlOperation(onCreateProject))
                .subscribe({
                    next: ({ value }) => {
                        setProjectMember(value.data.onCreateProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update element
    const subOnUpdateProjectMember = async () => {
        try {
            subscriptionProjectMemberOnUpdate = await API.graphql(graphqlOperation(onUpdateProject))
                .subscribe({
                    next: ({ value }) => {
                        setProjectMember(value.data.onUpdateProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub delete project member
    const subOnDeleteProjectMember = async () => {
        try {
            subscriptionProjectMemberOnDelete = await API.graphql(graphqlOperation(onDeleteProject))
                .subscribe({
                    next: ({ value }) => {
                        setProjectMember(value.data.onDeleteProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <Text level={5}>Project Memebers</Text><br></br>
            <Avatar.Group style={{ marginTop: 10 }}>
                {projectMembers && projectMembers.map((data, index) => {
                    return <ViewAvatar key={index} userId={data} />
                })}
            </Avatar.Group>
        </>
    )
}

const ViewAvatar = (props) => {
    const { userId } = props
    const [viewAvatar, setViewAvatar] = useState(null)

    useEffect(() => {
        queryUser()
    }, [])

    const queryUser = async () => {
        try {
            if (userId) {
                const userData = await API.graphql(graphqlOperation(listUsers, {
                    filter: {
                        id: { eq: userId }
                    }
                }))
                const { data: { listUsers: { items } } } = userData
                const avatar = await Storage.get(`avatars/${items[0].avatar}`)
                setViewAvatar(avatar)
            }
        } catch (err) {
            console.log(err)
        }
    }
    return <Avatar src={viewAvatar} size="large" />
}

export default ProjectMemberAvatar