import { Timeline, Col, Typography } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const { Title } = Typography

const ElementRole = ({ item }) => {
    const {
        readAllElement,
        readOwnElement,
        createElement,
        updateElement,
        deleteElement
    } = item

    return (
        <Col span={5}>
            <Title level={5} className="timeline-header">Element</Title>
            <Timeline>
                <Timeline.Item
                    color={readAllElement ? "green" : "red"}
                    dot={readAllElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read All Element
                </Timeline.Item>
                <Timeline.Item
                    color={readOwnElement ? "green" : "red"}
                    dot={readOwnElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read Own Element
                </Timeline.Item>
                <Timeline.Item
                    color={createElement ? "green" : "red"}
                    dot={createElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Create
                </Timeline.Item>
                <Timeline.Item
                    color={updateElement ? "green" : "red"}
                    dot={updateElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Update
                </Timeline.Item>
                <Timeline.Item
                    color={deleteElement ? "green" : "red"}
                    dot={deleteElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Delete
                </Timeline.Item>
            </Timeline>
        </Col>
    )
}

export default ElementRole