import { API, graphqlOperation } from "aws-amplify"
import { deleteTimetable } from "../../graphql/mutations"
import { listTimetables } from "../../graphql/queries"

export const deleteTimetableById = async (id) => {
    try {
        await API.graphql(graphqlOperation(deleteTimetable, { input: { id } }))
    } catch (err) {
        console.log(err)
    }
}

export const deleteTimetableBySubElementId = async (subElementId) => {
    try {
        const timetableData = await API.graphql(graphqlOperation(listTimetables, {
            filter: {
                subElementTimetableId: { eq: subElementId }
            }
        }))
        const { data: { listTimetables: { items: timetableItems } } } = timetableData
        if (timetableItems.length > 0) {
            timetableItems.forEach(async (value) => {
                const timetableId = value.id
                await API.graphql(graphqlOperation(deleteTimetable, { input: { id: timetableId } }))
            })
        }
    } catch (err) {
        console.log(err)
    }
}