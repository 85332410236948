import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

// external-components
import UpdateProjectDrawer from "../../ProjectListContainer/components/Drawers/UpdateProjectDrawer"

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { getProject } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateProject,
    onUpdateProject,
    onDeleteProject
} from "../../../graphql/subscriptions"

const ProjectTitleHeader = () => {
    const [project, setProject] = useState(null)
    const [subProject, setSubProject] = useState(null)
    const { id: projectId } = useParams()

    // subscribe project
    let subscriptionProjectOnCreate;
    let subscriptionProjectOnUpdate;

    // execute query project
    useEffect(() => {
        queryProjectById()
    }, [subProject, projectId])

    // execute sub project
    useEffect(() => {
        subOnCreateProject()
        subOnUpdateProject()
        return () => {
            subscriptionProjectOnCreate.unsubscribe()
            subscriptionProjectOnUpdate.unsubscribe()
        }
    }, [])

    // query project by id
    const queryProjectById = async () => {
        try {
            if (projectId) {
                const projectData = await API.graphql(graphqlOperation(getProject, { id: projectId }))
                setProject(projectData.data.getProject)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // sub create project
    const subOnCreateProject = async () => {
        try {
            subscriptionProjectOnCreate = await API.graphql(graphqlOperation(onCreateProject))
                .subscribe({
                    next: ({ value }) => {
                        setSubProject(value.data.onCreateProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update project
    const subOnUpdateProject = async () => {
        try {
            subscriptionProjectOnUpdate = await API.graphql(graphqlOperation(onUpdateProject))
                .subscribe({
                    next: ({ value }) => {
                        setSubProject(value.data.onUpdateProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            {
                project &&
                <>
                    {/* <DeleteRecordDrawer
                                    btnType="link"
                                    iconSize={23}
                                    id={project.id}
                                    module="project"
                                    title={`Delete the project number ${project.projectNumber}?`}
                                /> */}
                    {/* <UpdateProjectDrawer data={project} /> */}
                    <span style={{ fontSize: 24, fontWeight: "600" }}>
                        Project:
                        <span style={{ color: "#0052F6", marginLeft: 10 }}>
                            {project && `${project.projectNumber} - ${project.projectTitle}`}
                        </span>
                    </span>
                </>
            }

        </>
    )
}

export default ProjectTitleHeader