import UserDatatable from "./components/UserDatatable"
import UserList from "./components/UserList"

const User = () => {
    return (
        // <UserDatatable />
        <UserList />
    )
}

export default User