import { useState, useEffect } from "react"
import { Image } from "antd"

// amplify
import { Storage } from "aws-amplify"

const ViewImage = (props) => {
    const [view, setView] = useState(null)
    const { fileImage, storagePath, height, width, className } = props
    useEffect(() => {
        viewImage()
    }, [fileImage])
    const viewImage = async () => {
        if (fileImage) {
            const rs = await Storage.get(`${storagePath}/${fileImage}`)
            setView(rs)
        }
    }
    return <Image src={view} height={height} width={width} className={className} />
}

export default ViewImage