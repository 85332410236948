import { useState, createRef, useRef } from "react"
import { Button, Drawer, Row, Col, Form, Input, Divider, Upload, message, Image } from "antd"
import { CloseCircleOutlined, SaveOutlined, UploadOutlined, CloseOutlined } from "@ant-design/icons"
import { v4 as uuidv4 } from 'uuid';

// external-components
import Notification from "../../../components/Notification/Notification"
import Spin from "../../../components/Spin/Spin"

// internal-components
import SelectDepartment from "./SelectOptions/SelectDepartment";
import SelectPosition from "./SelectOptions/SelectPosition";
import SelectRole from "./SelectOptions/SelectRole";

// amplify
import { API, Auth, Storage, graphqlOperation } from "aws-amplify"

// mutation-create
import { createUser } from "../../../graphql/mutations"

// shared-context
import { DepartmentIdProvider } from "../../../shared/context/DepartmentIdContext"

const CreateUserDrawer = () => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [fileList, setFileList] = useState([]);
    const [departmentIdContext, setDepartmentIdContext] = useState(null)
    const [form] = Form.useForm()
    let formRef = createRef()
    const inputRef = useRef(null);
    const sharedProps = {
        ref: inputRef,
    };

    const validateMessages = {
        required: '${label} is required!'
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        if (e && e.fileList.length > 1) {
            e.fileList.shift()
        }
        return e && e.fileList
    };

    const handleRemove = (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        newFileList.map((data) => {
            form.setFieldsValue({ logo: data });
        })
        form.setFieldsValue({ logo: newFileList });
        setFileList(newFileList)
    }

    const uploadProps = {
        beforeUpload: file => {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error('You can only upload JPG/PNG file!');
            }
            const isLt2M = file.size / 1024 / 1024 < 1;
            if (!isLt2M) {
                message.error('Image must smaller than 1MB!');
            }
            if (isJpgOrPng && isLt2M) {
                setFileList([file]);
            }
            return false
        },
        fileList
    };

    const uploadButton = (
        <div>
            <UploadOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onFinish = async (values) => {
        try {
            const { email, password, avatar } = values
            const originFileObj = avatar[0].originFileObj
            const splitFileName = originFileObj.name.split(".")
            const fileName = uuidv4() + "." + splitFileName[1]
            delete values.confirmPassword
            delete values.password
            values.avatar = fileName
            values.typeCreated = "UserCreated"
            values.key = uuidv4()
            setIsLoading(true)
            await Auth.signUp({ username: email, password, attributes: { email } })
            await Storage.put(`avatars/${fileName}`, originFileObj)
            await API.graphql(graphqlOperation(createUser, { input: values }))
            Notification("success", "Success", "User create successfully!")
            setIsLoading(false)
            form.resetFields()

            // values.typeCreated = "UserCreated"
            // values.key = uuidv4()
            // setIsLoading(true)
            // await API.graphql(graphqlOperation(createUser, { input: values }))
            // Notification("success", "Success", "User create successfully!")
            // setIsLoading(false)
            // form.resetFields()
            // inputRef.current.focus({
            //     cursor: "start"
            // })
        } catch (error) {
            console.log(error)
        }
    };

    const onClose = () => {
        setVisible(false);
    };

    return (
        <div>
            <Button
                type="primary"
                size="large"
                onClick={() => setVisible(true)}
                style={{ float: "right" }}
            >
                Create User
            </Button>
            <Drawer
                title="Create User"
                onClose={onClose}
                visible={visible}
                width="800"
            >
                {
                    isLoading ? <Spin /> :
                        <Form form={form} ref={formRef} name="nest-messages" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <Row gutter={24}>
                                <Col span={11}>
                                    <Form.Item name="firstName" label="First Name" rules={[{ required: true }]}>
                                        <Input size="large" {...sharedProps} />
                                    </Form.Item>
                                    <Form.Item name="lastName" label="Last Name" rules={[{ required: true }]}>
                                        <Input size="large" {...sharedProps} />
                                    </Form.Item>
                                    <Form.Item name={'email'} label="Email" rules={[{ type: 'email', required: true }]}>
                                        <Input size="large" />
                                    </Form.Item>
                                    <Form.Item name={'password'} label="Password" rules={[{ required: true }]}>
                                        <Input.Password size="large" />
                                    </Form.Item>
                                    <Form.Item name={'confirmPassword'} label="Confirm Password" rules={[{ required: true }]}>
                                        <Input.Password size="large" />
                                    </Form.Item>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="avatar"
                                                label="Profile Picture"
                                                getValueFromEvent={normFile}
                                                valuePropName="file"
                                                rules={[{ required: true }]}
                                            >
                                                <Upload
                                                    {...uploadProps}
                                                    listType="picture-card"
                                                    showUploadList={false}
                                                >
                                                    {uploadButton}
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item>
                                                <div>
                                                    {
                                                        fileList.map((file, index) => {
                                                            const src = URL.createObjectURL(file)
                                                            return (
                                                                <div key={index} className="banner-image-wrapper">
                                                                    <Image src={src} key={index} style={{ height: 130 }} />
                                                                    <Button
                                                                        shape="circle"
                                                                        icon={<CloseOutlined />}
                                                                        onClick={() => handleRemove(file)}
                                                                        className="delete-button"
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={11} offset={2}>
                                    <DepartmentIdProvider value={{ departmentIdContext, setDepartmentIdContext }}>
                                        <SelectDepartment form={form} />
                                        <SelectPosition form={form} />
                                    </DepartmentIdProvider>
                                    <SelectRole form={form} />
                                </Col>
                            </Row>
                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={6} offset={12}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={6}>
                                        <Button icon={<SaveOutlined />} block size="large" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </div>
    )
}

export default CreateUserDrawer