import { useState, forwardRef, useImperativeHandle } from "react"
import { Row, Col, Typography, Form, Divider, Checkbox, Card } from "antd"
import "../CreateRoleDrawer.less"

const { Title } = Typography
const CheckboxGroup = Checkbox.Group;

// position-options
const positionOptions = [
    {
        label: 'Read',
        value: 'readPosition'
    },
    {
        label: 'Create',
        value: 'createPosition'
    },
    {
        label: 'Update',
        value: 'updatePosition'
    },
    {
        label: 'Delete',
        value: 'deletePosition'
    }
];

const CheckPositionGroup = forwardRef((props, ref) => {
    const [checkedPositionGroup, setCheckedPositionGroup] = useState(null);
    const {
        form,
        disabled,
        setIndeterminate,
        setCheckAll,
        checkedPosition,
        setCheckedPosition
    } = props

    useImperativeHandle(ref, () => ({
        onChangePosition(checked) {
            setCheckedPositionGroup(checked ? positionOptions.map(v => v.value) : []);
            setCheckedPosition(checked)
            form.setFieldsValue({
                positionGroup: checked ? positionOptions.map(v => v.value) : []
            })
        }
    }))

    const onChangePosition = (e) => {
        const checked = e.target.checked
        setCheckedPositionGroup(checked ? positionOptions.map(v => v.value) : []);
        setCheckedPosition(checked)
        setIndeterminate(false)
        form.setFieldsValue({
            positionPrivilege: checked,
            positionGroup: checked ? positionOptions.map(v => v.value) : []
        })
    }
    const onChangePositionGroup = (list) => {
        setIndeterminate(!!list.length && list.length < positionOptions.length);
        setCheckAll(list.length === positionOptions.length);
    };

    return (
        <Col span={6}>
            <Card bordered={false} className="small-card-container">
                <Form.Item name="positionPrivilege" valuePropName="checked" style={{ marginBottom: -10, marginTop: 0 }}>
                    <Checkbox
                        onChange={onChangePosition}
                        checked={checkedPosition}
                        disabled={disabled ? false : true}
                    >
                        <span className={disabled ? 'child-checkbox-label' : 'child-checkbox-label-disabled'}>
                            Position
                        </span>
                    </Checkbox>
                </Form.Item>
                <Divider />
                <Form.Item name="positionGroup" style={{ marginBottom: 0, marginTop: -10 }}>
                    <Checkbox.Group>
                        <Row>
                            <Col span={24}>
                                <Checkbox
                                    value="readPosition"
                                    disabled={checkedPosition ? false : true}
                                    onChange={onChangePositionGroup}
                                >
                                    Read
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="createPosition"
                                    disabled={checkedPosition ? false : true}
                                    onChange={onChangePositionGroup}
                                >
                                    Creat
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="updatePosition"
                                    disabled={checkedPosition ? false : true}
                                    onChange={onChangePositionGroup}
                                >
                                    Update
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="deletePosition"
                                    disabled={checkedPosition ? false : true}
                                    onChange={onChangePositionGroup}
                                >
                                    Delete
                                </Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                    {/* <CheckboxGroup
                        disabled={checkedPosition ? false : true}
                        options={positionOptions}
                        value={checkedPositionGroup}
                        onChange={onChangePositionGroup}
                    /> */}
                </Form.Item>
            </Card>
        </Col>
    )
})

export default CheckPositionGroup