import { Timeline, Col, Typography } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const { Title } = Typography

const DepartmentRole = ({ item }) => {
    const {
        // departmentPrivilege,
        readDepartment,
        createDepartment,
        updateDepartment,
        deleteDepartment,
    } = item

    return (
        <Col span={6}>
            <Title level={5} className="timeline-header">Department</Title>
            <Timeline>
                <Timeline.Item
                    color={readDepartment ? "green" : "red"}
                    dot={readDepartment ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read
                </Timeline.Item>
                <Timeline.Item
                    color={createDepartment ? "green" : "red"}
                    dot={createDepartment ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Create
                </Timeline.Item>
                <Timeline.Item
                    color={updateDepartment ? "green" : "red"}
                    dot={updateDepartment ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Update
                </Timeline.Item>
                <Timeline.Item
                    color={deleteDepartment ? "green" : "red"}
                    dot={deleteDepartment ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Delete
                </Timeline.Item>
            </Timeline>
        </Col>
    )
}

export default DepartmentRole