import { useState, useEffect } from "react"
import { Layout, Menu, Row, Col, Typography, Tag, Button } from 'antd'
import {
    DesktopOutlined,
    FieldTimeOutlined,
    SettingOutlined,
    InsertRowAboveOutlined,
    LogoutOutlined,
    PicRightOutlined
} from '@ant-design/icons';
import Cookies from 'universal-cookie';
import { Link, useNavigate } from "react-router-dom"
import "./Header.less"

// asset
import logo from "../../../../assets/images/batigc-header-logo.png"

// external-component
import ViewImage from '../../../../components/ViewImage/ViewImage';

// aws-amplify
import { API, Auth, graphqlOperation } from "aws-amplify"

// graphql-query
import { listUsersByCreatedDate, listRolesByCreatedDate } from "../../../../graphql/queries"

// graphql-sub
import { onUpdateUser, onUpdateRole } from "../../../../graphql/subscriptions"

const { Header } = Layout
const { Text } = Typography
const cookies = new Cookies();

const MyHeader = () => {
    const [users, setUsers] = useState(null)
    const [user, setUser] = useState(null)
    const [roleId, setRoleId] = useState(null)
    const [role, setRole] = useState(null)
    const [roles, setRoles] = useState(null)
    const userId = cookies.get('userId')
    const navigate = useNavigate();

    let subscriptionOnUpdateUser;
    let subscriptionOnUpdateRole;

    // execute subscription
    useEffect(() => {
        subOnUpdateUser()
        subOnUpdateRole()
        return () => {
            subscriptionOnUpdateUser.unsubscribe()
            subscriptionOnUpdateRole.unsubscribe()
        }
    }, [])

    useEffect(() => {
        queryUser()
    }, [user])

    useEffect(() => {
        queryRole()
    }, [role, roleId])

    const queryUser = async () => {
        try {
            const userData = await API.graphql(graphqlOperation(listUsersByCreatedDate, {
                typeCreated: "UserCreated",
                filter: {
                    id: { eq: userId }
                }
            }))
            const userItem = userData.data.listUsersByCreatedDate.items[0]
            if (userItem) {
                setUsers(userItem)
                setRoleId(userItem.roleUserId)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const queryRole = async () => {
        try {
            const roleData = await API.graphql(graphqlOperation(listRolesByCreatedDate, {
                typeCreated: "RoleCreated",
                filter: {
                    id: { eq: roleId ? roleId : null }
                }
            }))
            const roleItem = roleData.data.listRolesByCreatedDate.items[0]
            setRoles(roleItem)
        } catch (err) {
            console.log(err)
        }
    }

    // sub-update-user
    const subOnUpdateUser = async () => {
        try {
            subscriptionOnUpdateUser = await API.graphql(graphqlOperation(onUpdateUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onUpdateUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub-update-role
    const subOnUpdateRole = async () => {
        try {
            subscriptionOnUpdateRole = await API.graphql(graphqlOperation(onUpdateRole))
                .subscribe({
                    next: ({ value }) => {
                        setRole(value.data.onUpdateRole)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    const handleLogout = async () => {
        await Auth.signOut()
        cookies.remove('userEmail', { path: '/' })
        cookies.set('isAuthenticated', false, { path: '/' });
        navigate("/login")
    }

    return (
        <Header className="header" style={{ paddingLeft: 20, color: "black", padding: 0 }}>
            <Row>
                <Col span={21}>
                    <div className="logo" >
                        <img src={logo} />
                    </div>
                    {
                        roles &&
                        <Menu mode="horizontal">
                            {
                                roles.projectPrivilege === true &&
                                <Menu.Item key="project" icon={<DesktopOutlined />}>
                                    <Link to={"/project"}>
                                        Project
                                    </Link>
                                </Menu.Item>
                            }
                            {
                                roles.planningPrivilege === true &&
                                <Menu.Item key="planning" icon={<InsertRowAboveOutlined />}>
                                    <Link to={"/planning"}>
                                        Planning
                                    </Link>
                                </Menu.Item>
                            }
                            {
                                roles.timetablePrivilege === true &&
                                <Menu.Item key="timetable" icon={<FieldTimeOutlined />}>
                                    <Link to={"/timetable"}>
                                        Timetable
                                    </Link>
                                </Menu.Item>
                            }
                            <Menu.Item key="elementList" icon={<PicRightOutlined />}>
                                <Link to={"/elementList"}>
                                    Element List
                                </Link>
                            </Menu.Item>
                            {
                                roles.settingPrivilege === true &&
                                <Menu.Item key="setting" icon={<SettingOutlined />}>
                                    <Link to={"/setting"}>
                                        Setting
                                    </Link>
                                </Menu.Item>
                            }
                        </Menu>
                    }
                </Col>
                <Col span={3}>
                    <Row gutter={24}>
                        <Col span={12}>
                            {
                                users &&
                                <>
                                    <ViewImage
                                        fileImage={users.avatar}
                                        storagePath="avatars"
                                        className="user-avatar"
                                    />
                                </>
                            }
                        </Col>
                        <Col span={12} >
                            <Button
                                type="danger"
                                shape="round"
                                icon={<LogoutOutlined />}
                                style={{ float: "right", marginTop: 16, marginRight: 24 }}
                                onClick={handleLogout}
                            >
                                Logout
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Header>
    )
}

export default MyHeader