import { Timeline, Col, Typography } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const { Title } = Typography

const ProjectRole = ({ item }) => {
    const {
        readAllProject,
        readOwnProject,
        createProject,
        updateProject,
        deleteProject
    } = item

    return (
        <Col span={5}>
            <Title level={5} className="timeline-header">Project</Title>
            <Timeline>
                <Timeline.Item
                    color={readAllProject ? "green" : "red"}
                    dot={readAllProject ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read All Project
                </Timeline.Item>
                <Timeline.Item
                    color={readOwnProject ? "green" : "red"}
                    dot={readOwnProject ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read Own Project
                </Timeline.Item>
                <Timeline.Item
                    color={createProject ? "green" : "red"}
                    dot={createProject ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Create
                </Timeline.Item>
                <Timeline.Item
                    color={updateProject ? "green" : "red"}
                    dot={updateProject ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Update
                </Timeline.Item>
                <Timeline.Item
                    color={deleteProject ? "green" : "red"}
                    dot={deleteProject ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Delete
                </Timeline.Item>
            </Timeline>
        </Col>
    )
}

export default ProjectRole