import { useState, forwardRef, useImperativeHandle } from "react"
import { Row, Col, Typography, Form, Divider, Checkbox, Card } from "antd"
import "../CreateRoleDrawer.less"

const { Title } = Typography
const CheckboxGroup = Checkbox.Group;

// elementCategory-options
const elementCategoryOptions = [
    {
        label: 'Read',
        value: 'readElementCategory'
    },
    {
        label: 'Create',
        value: 'createElementCategory'
    },
    {
        label: 'Update',
        value: 'updateElementCategory'
    },
    {
        label: 'Delete',
        value: 'deleteElementCategory'
    }
];

const CheckElementCategoryGroup = forwardRef((props, ref) => {
    const [checkedElementCategoryGroup, setCheckedElementCategoryGroup] = useState(null);
    const {
        form,
        disabled,
        setIndeterminate,
        setCheckAll,
        checkedElementCategory,
        setCheckedElementCategory
    } = props

    useImperativeHandle(ref, () => ({
        onChangeElementCategory(checked) {
            setCheckedElementCategoryGroup(checked ? elementCategoryOptions.map(v => v.value) : []);
            setCheckedElementCategory(checked)
            form.setFieldsValue({
                elementCategoryGroup: checked ? elementCategoryOptions.map(v => v.value) : []
            })
        }
    }))

    const onChangeElementCategory = (e) => {
        const checked = e.target.checked
        setCheckedElementCategoryGroup(checked ? elementCategoryOptions.map(v => v.value) : []);
        setCheckedElementCategory(checked)
        setIndeterminate(false)
        form.setFieldsValue({
            elementCategoryPrivilege: checked,
            elementCategoryGroup: checked ? elementCategoryOptions.map(v => v.value) : []
        })
    }
    const onChangeElementCategoryGroup = (list) => {
        setIndeterminate(!!list.length && list.length < elementCategoryOptions.length);
        setCheckAll(list.length === elementCategoryOptions.length);
    };

    return (
        <Col span={12}>
            <Card bordered={false} className="small-card-container">
                <Form.Item name="elementCategoryPrivilege" valuePropName="checked" style={{ marginBottom: -10, marginTop: 0 }}>
                    <Checkbox
                        onChange={onChangeElementCategory}
                        checked={checkedElementCategory}
                        disabled={disabled ? false : true}
                    >
                        <span className={disabled ? 'child-checkbox-label' : 'child-checkbox-label-disabled'}>
                            Element Category
                        </span>
                    </Checkbox>
                </Form.Item>
                <Divider />
                <Form.Item name="elementCategoryGroup" style={{ marginBottom: 0, marginTop: -10 }}>
                    <Checkbox.Group>
                        <Row>
                            <Col span={24}>
                                <Checkbox
                                    value="readElementCategory"
                                    disabled={checkedElementCategory ? false : true}
                                    onChange={onChangeElementCategoryGroup}
                                >
                                    Read
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="createElementCategory"
                                    disabled={checkedElementCategory ? false : true}
                                    onChange={onChangeElementCategoryGroup}
                                >
                                    Create
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="updateElementCategory"
                                    disabled={checkedElementCategory ? false : true}
                                    onChange={onChangeElementCategoryGroup}
                                >
                                    Update
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="deleteElementCategory"
                                    disabled={checkedElementCategory ? false : true}
                                    onChange={onChangeElementCategoryGroup}
                                >
                                    Delete
                                </Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                    {/* <CheckboxGroup
                        disabled={checkedElementCategory ? false : true}
                        options={elementCategoryOptions}
                        value={checkedElementCategoryGroup}
                        onChange={onChangeElementCategoryGroup}
                    /> */}
                </Form.Item>
            </Card>
        </Col>
    )
})

export default CheckElementCategoryGroup