import { Tabs } from "antd"
import { PicLeftOutlined } from "@ant-design/icons"

// internal-component
import SubElementList from "./components/SubElementList/SubElementList";

const { TabPane } = Tabs;

const ElementList = () => {
    return (
        <div style={{ padding: 20 }}>
            <Tabs defaultActiveKey="2">
                <TabPane
                    tab={
                        <span>
                            <PicLeftOutlined />
                            Sub Element List
                        </span>
                    }
                    key="1"
                >
                    <SubElementList />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default ElementList