import { Timeline, Col, Typography } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const { Title } = Typography

const Role = ({ item }) => {
    const {
        // rolePrivilege,
        readRole,
        createRole,
        updateRole,
        deleteRole
    } = item

    return (
        <Col span={6}>
            <Title level={5} className="timeline-header">Role</Title>
            <Timeline>
                <Timeline.Item
                    color={readRole ? "green" : "red"}
                    dot={readRole ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read
                </Timeline.Item>
                <Timeline.Item
                    color={createRole ? "green" : "red"}
                    dot={createRole ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Create
                </Timeline.Item>
                <Timeline.Item
                    color={updateRole ? "green" : "red"}
                    dot={updateRole ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Update
                </Timeline.Item>
                <Timeline.Item
                    color={deleteRole ? "green" : "red"}
                    dot={deleteRole ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Delete
                </Timeline.Item>
            </Timeline>
        </Col>
    )
}

export default Role