import { Form, Input } from "antd"
import { SearchOutlined } from '@ant-design/icons'

const SearchProject = (props) => {
    const { handleSearch, collapsed } = props

    return (
        <>
            {
                !collapsed ?
                    <Form name="control-hooks" style={{ marginTop: 20 }}>
                        <Form.Item name="note">
                            <Input size="large" onChange={handleSearch} placeholder="Search project" prefix={<SearchOutlined />} />
                        </Form.Item>
                    </Form> : null
            }
        </>
    )
}

export default SearchProject