import { useEffect } from "react"
import { useParams } from "react-router-dom"

// apollo-client
import { useLazyQuery } from "@apollo/client"

// graphql-query
import { listUsers } from "../../graphql/queries"

// graphql-sub
import { onUpdateUser } from "../../graphql/subscriptions"

const ViewUserInfo = () => {
    const { userId } = useParams()

    useEffect(() => {
        if (userId) {
            loadUser()
        }
    }, [userId])

    // query user
    const [loadUser, { subscribeToMore, loading, error, data }] = useLazyQuery(listUsers, {
        variables: {
            filter: {
                id: { eq: userId ? userId : null }
            }
        }
    })

    // on update user
    useEffect(() =>
        subscribeToMore({
            document: onUpdateUser,
            updateQuery: (prev) => {
                const { listUsers: { items } } = prev
                return {
                    listUsers: {
                        items: [...items],
                        nextToken: null,
                        __typename: "User"
                    }
                }
            }
        }), [subscribeToMore]
    )

    if (loading) return <div>...</div>
    if (error) console.log(error)
    if (data) {
        const { listUsers: { items } } = data
        return (
            <span>
                {
                    items.length > 0 &&
                    <span style={{ marginLeft: 5 }}>
                        / User: <b>{items[0].firstName} {items[0].lastName}</b>
                    </span>
                }
            </span>
        )
    }
}

export default ViewUserInfo