import { useState, useEffect } from "react"
import { Form, Select } from "antd"
import { useParams, useNavigate } from "react-router-dom"

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import {
    listUsersByCreatedDate,
    listRolesByCreatedDate
} from "../../graphql/queries"

// graphql-sub
import {
    onCreateUser,
    onUpdateUser,
    onDeleteUser
} from "../../graphql/subscriptions"

const SelectUser = ({ path }) => {
    const [users, setUsers] = useState([])
    const [user, setUser] = useState(null)
    const [role, setRole] = useState(null)

    // router
    const navigate = useNavigate()
    const { projectId, elementId, userId } = useParams()

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    // execute query 
    useEffect(() => {
        queryUsers()
    }, [user])

    // execute subscription
    useEffect(() => {
        subOnCreateUser()
        subOnUpdateUser()
        subOnDeleteUser()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query users
    const queryUsers = async () => {
        try {
            const userData = await API.graphql(graphqlOperation(listUsersByCreatedDate, {
                typeCreated: "UserCreated",
                sortDirection: "DESC"
            }))
            const { data: { listUsersByCreatedDate: { items } } } = userData
            setUsers(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create user
    const subOnCreateUser = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onCreateUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update user
    const subOnUpdateUser = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onUpdateUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete user
    const subOnDeleteUser = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onDeleteUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Form.Item label="User">
            <Select
                showSearch
                allowClear
                size="large"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
                onSelect={(value) => navigate(`/${path}/${projectId ? projectId : "0"}/${elementId ? elementId : "0"}/${value}`)}
                onClear={() => navigate(`/${path}/${projectId ? projectId : "0"}/${elementId ? elementId : "0"}/0`)}
                defaultValue={userId !== "0" ? userId : null}
            >
                {
                    users && users.map((item, key) => {
                        const { id, firstName, lastName } = item
                        return <Select.Option key={key} value={id}>{firstName} {lastName}</Select.Option>
                    })
                }
            </Select>
        </Form.Item>
    )
}

export default SelectUser