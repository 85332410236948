/* eslint-disable */
// this is an auto generated file. This will be overwritten
import { gql } from "@apollo/client"

export const onCreateProjectByProjectMemberId = gql`
  subscription OnCreateProjectByProjectMemberId($projectMemberId: String!) {
    onCreateProjectByProjectMemberId(projectMemberId: $projectMemberId) {
      id
      key
      projectNumber
      projectTitle
      companyName
      location
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      projectManagerId
      projectManager {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      projectMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userProjectManagerId
      userProjectId
    }
  }
`;
export const onUpdateProjectByProjectMemberId = gql`
  subscription OnUpdateProjectByProjectMemberId($projectMemberId: String!) {
    onUpdateProjectByProjectMemberId(projectMemberId: $projectMemberId) {
      id
      key
      projectNumber
      projectTitle
      companyName
      location
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      projectManagerId
      projectManager {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      projectMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userProjectManagerId
      userProjectId
    }
  }
`;
export const onDeleteProjectByProjectMemberId = gql`
  subscription OnDeleteProjectByProjectMemberId($projectMemberId: String!) {
    onDeleteProjectByProjectMemberId(projectMemberId: $projectMemberId) {
      id
      key
      projectNumber
      projectTitle
      companyName
      location
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      projectManagerId
      projectManager {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      projectMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userProjectManagerId
      userProjectId
    }
  }
`;
export const onCreateProjectByProjectManagerId = gql`
  subscription OnCreateProjectByProjectManagerId($projectManagerId: String!) {
    onCreateProjectByProjectManagerId(projectManagerId: $projectManagerId) {
      id
      key
      projectNumber
      projectTitle
      companyName
      location
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      projectManagerId
      projectManager {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      projectMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userProjectManagerId
      userProjectId
    }
  }
`;
export const onUpdateProjectByProjectManagerId = gql`
  subscription OnUpdateProjectByProjectManagerId($projectManagerId: String!) {
    onUpdateProjectByProjectManagerId(projectManagerId: $projectManagerId) {
      id
      key
      projectNumber
      projectTitle
      companyName
      location
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      projectManagerId
      projectManager {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      projectMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userProjectManagerId
      userProjectId
    }
  }
`;
export const onDeleteProjectByProjectManagerId = gql`
  subscription OnDeleteProjectByProjectManagerId($projectManagerId: String!) {
    onDeleteProjectByProjectManagerId(projectManagerId: $projectManagerId) {
      id
      key
      projectNumber
      projectTitle
      companyName
      location
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      projectManagerId
      projectManager {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      projectMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userProjectManagerId
      userProjectId
    }
  }
`;
export const onCreateSubElementByUserId = gql`
  subscription OnCreateSubElementByUserId($userSubElementId: String!) {
    onCreateSubElementByUserId(userSubElementId: $userSubElementId) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      priority
      numberOfDrawing
      index
      description
      ctcPredictedDate
      provNumber
      provAlphabet
      entreprisPaperVersion
      entreprisNumber
      entreprisFax
      contractorPaperVersion
      contractorNumber
      contractorFax
      observationsEntrepris
      observationsProjectDirector
      observationsConsultant
      subElementMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectSubElementId
      elementSubElementId
      elementCategorySubElementId
      userSubElementId
    }
  }
`;
export const onUpdateSubElementByUserId = gql`
  subscription OnUpdateSubElementByUserId($userSubElementId: String!) {
    onUpdateSubElementByUserId(userSubElementId: $userSubElementId) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      priority
      numberOfDrawing
      index
      description
      ctcPredictedDate
      provNumber
      provAlphabet
      entreprisPaperVersion
      entreprisNumber
      entreprisFax
      contractorPaperVersion
      contractorNumber
      contractorFax
      observationsEntrepris
      observationsProjectDirector
      observationsConsultant
      subElementMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectSubElementId
      elementSubElementId
      elementCategorySubElementId
      userSubElementId
    }
  }
`;
export const onDeleteSubElementByUserId = gql`
  subscription OnDeleteSubElementByUserId($userSubElementId: String!) {
    onDeleteSubElementByUserId(userSubElementId: $userSubElementId) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      priority
      numberOfDrawing
      index
      description
      ctcPredictedDate
      provNumber
      provAlphabet
      entreprisPaperVersion
      entreprisNumber
      entreprisFax
      contractorPaperVersion
      contractorNumber
      contractorFax
      observationsEntrepris
      observationsProjectDirector
      observationsConsultant
      subElementMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectSubElementId
      elementSubElementId
      elementCategorySubElementId
      userSubElementId
    }
  }
`;
export const onCreateSubElementByElementId = gql`
  subscription OnCreateSubElementByElementId($elementSubElementId: String!) {
    onCreateSubElementByElementId(elementSubElementId: $elementSubElementId) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      priority
      numberOfDrawing
      index
      description
      ctcPredictedDate
      provNumber
      provAlphabet
      entreprisPaperVersion
      entreprisNumber
      entreprisFax
      contractorPaperVersion
      contractorNumber
      contractorFax
      observationsEntrepris
      observationsProjectDirector
      observationsConsultant
      subElementMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectSubElementId
      elementSubElementId
      elementCategorySubElementId
      userSubElementId
    }
  }
`;
export const onUpdateSubElementByElementId = gql`
  subscription OnUpdateSubElementByElementId($elementSubElementId: String!) {
    onUpdateSubElementByElementId(elementSubElementId: $elementSubElementId) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      priority
      numberOfDrawing
      index
      description
      ctcPredictedDate
      provNumber
      provAlphabet
      entreprisPaperVersion
      entreprisNumber
      entreprisFax
      contractorPaperVersion
      contractorNumber
      contractorFax
      observationsEntrepris
      observationsProjectDirector
      observationsConsultant
      subElementMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectSubElementId
      elementSubElementId
      elementCategorySubElementId
      userSubElementId
    }
  }
`;
export const onDeleteSubElementByElementId = gql`
  subscription OnDeleteSubElementByElementId($elementSubElementId: String!) {
    onDeleteSubElementByElementId(elementSubElementId: $elementSubElementId) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      priority
      numberOfDrawing
      index
      description
      ctcPredictedDate
      provNumber
      provAlphabet
      entreprisPaperVersion
      entreprisNumber
      entreprisFax
      contractorPaperVersion
      contractorNumber
      contractorFax
      observationsEntrepris
      observationsProjectDirector
      observationsConsultant
      subElementMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectSubElementId
      elementSubElementId
      elementCategorySubElementId
      userSubElementId
    }
  }
`;
export const onCreateElementByProjectId = gql`
  subscription OnCreateElementByProjectId($projectElementId: String!) {
    onCreateElementByProjectId(projectElementId: $projectElementId) {
      id
      key
      elementName
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectElementId
      elementCategoryElementId
      userElementId
    }
  }
`;
export const onUpdateElementByProjectId = gql`
  subscription OnUpdateElementByProjectId($projectElementId: String!) {
    onUpdateElementByProjectId(projectElementId: $projectElementId) {
      id
      key
      elementName
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectElementId
      elementCategoryElementId
      userElementId
    }
  }
`;
export const onDeleteElementByProjectId = gql`
  subscription OnDeleteElementByProjectId($projectElementId: String!) {
    onDeleteElementByProjectId(projectElementId: $projectElementId) {
      id
      key
      elementName
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectElementId
      elementCategoryElementId
      userElementId
    }
  }
`;
export const onCreatePlanningByUserId = gql`
  subscription OnCreatePlanningByUserId($userPlanningId: String!) {
    onCreatePlanningByUserId(userPlanningId: $userPlanningId) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      subElement {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      planningDate
      status
      planningMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectPlanningId
      elementPlanningId
      subElementPlanningId
      userPlanningId
    }
  }
`;
export const onUpdatePlanningByUserId = gql`
  subscription OnUpdatePlanningByUserId($userPlanningId: String!) {
    onUpdatePlanningByUserId(userPlanningId: $userPlanningId) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      subElement {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      planningDate
      status
      planningMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectPlanningId
      elementPlanningId
      subElementPlanningId
      userPlanningId
    }
  }
`;
export const onDeletePlanningByUserId = gql`
  subscription OnDeletePlanningByUserId($userPlanningId: String!) {
    onDeletePlanningByUserId(userPlanningId: $userPlanningId) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      subElement {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      planningDate
      status
      planningMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectPlanningId
      elementPlanningId
      subElementPlanningId
      userPlanningId
    }
  }
`;
export const onCreateTimetableByUserId = gql`
  subscription OnCreateTimetableByUserId($userTimetableId: String!) {
    onCreateTimetableByUserId(userTimetableId: $userTimetableId) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      subElement {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      planning {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        timetable {
          nextToken
        }
        planningDate
        status
        planningMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectPlanningId
        elementPlanningId
        subElementPlanningId
        userPlanningId
      }
      timetableMemberId
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectTimetableId
      elementTimetableId
      subElementTimetableId
      planningTimetableId
      userTimetableId
    }
  }
`;
export const onUpdateTimetableByUserId = gql`
  subscription OnUpdateTimetableByUserId($userTimetableId: String!) {
    onUpdateTimetableByUserId(userTimetableId: $userTimetableId) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      subElement {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      planning {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        timetable {
          nextToken
        }
        planningDate
        status
        planningMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectPlanningId
        elementPlanningId
        subElementPlanningId
        userPlanningId
      }
      timetableMemberId
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectTimetableId
      elementTimetableId
      subElementTimetableId
      planningTimetableId
      userTimetableId
    }
  }
`;
export const onDeleteTimetableByUserId = gql`
  subscription OnDeleteTimetableByUserId($userTimetableId: String!) {
    onDeleteTimetableByUserId(userTimetableId: $userTimetableId) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      subElement {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      planning {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        timetable {
          nextToken
        }
        planningDate
        status
        planningMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectPlanningId
        elementPlanningId
        subElementPlanningId
        userPlanningId
      }
      timetableMemberId
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectTimetableId
      elementTimetableId
      subElementTimetableId
      planningTimetableId
      userTimetableId
    }
  }
`;
export const onCreateTimetableDetailByTimetableId = gql`
  subscription OnCreateTimetableDetailByTimetableId(
    $timetableTimetableDetailId: String!
  ) {
    onCreateTimetableDetailByTimetableId(
      timetableTimetableDetailId: $timetableTimetableDetailId
    ) {
      id
      key
      date
      time {
        id
        key
        time
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userTimeId
      }
      timetable {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        planning {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        timetableMemberId
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectTimetableId
        elementTimetableId
        subElementTimetableId
        planningTimetableId
        userTimetableId
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      timetableTimetableDetailId
      userTimetableDetailId
      timeTimetableDetailId
    }
  }
`;
export const onUpdateTimetableDetailByTimetableId = gql`
  subscription OnUpdateTimetableDetailByTimetableId(
    $timetableTimetableDetailId: String!
  ) {
    onUpdateTimetableDetailByTimetableId(
      timetableTimetableDetailId: $timetableTimetableDetailId
    ) {
      id
      key
      date
      time {
        id
        key
        time
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userTimeId
      }
      timetable {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        planning {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        timetableMemberId
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectTimetableId
        elementTimetableId
        subElementTimetableId
        planningTimetableId
        userTimetableId
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      timetableTimetableDetailId
      userTimetableDetailId
      timeTimetableDetailId
    }
  }
`;
export const onDeleteTimetableDetailByTimetableId = gql`
  subscription OnDeleteTimetableDetailByTimetableId(
    $timetableTimetableDetailId: String!
  ) {
    onDeleteTimetableDetailByTimetableId(
      timetableTimetableDetailId: $timetableTimetableDetailId
    ) {
      id
      key
      date
      time {
        id
        key
        time
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userTimeId
      }
      timetable {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        planning {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        timetableMemberId
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectTimetableId
        elementTimetableId
        subElementTimetableId
        planningTimetableId
        userTimetableId
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      timetableTimetableDetailId
      userTimetableDetailId
      timeTimetableDetailId
    }
  }
`;
export const onCreateProject = gql`
  subscription OnCreateProject {
    onCreateProject {
      id
      key
      projectNumber
      projectTitle
      companyName
      location
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      projectManagerId
      projectManager {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      projectMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userProjectManagerId
      userProjectId
    }
  }
`;
export const onUpdateProject = gql`
  subscription OnUpdateProject {
    onUpdateProject {
      id
      key
      projectNumber
      projectTitle
      companyName
      location
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      projectManagerId
      projectManager {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      projectMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userProjectManagerId
      userProjectId
    }
  }
`;
export const onDeleteProject = gql`
  subscription OnDeleteProject {
    onDeleteProject {
      id
      key
      projectNumber
      projectTitle
      companyName
      location
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      projectManagerId
      projectManager {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      projectMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userProjectManagerId
      userProjectId
    }
  }
`;
export const onCreateElement = gql`
  subscription OnCreateElement {
    onCreateElement {
      id
      key
      elementName
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectElementId
      elementCategoryElementId
      userElementId
    }
  }
`;
export const onUpdateElement = gql`
  subscription OnUpdateElement {
    onUpdateElement {
      id
      key
      elementName
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectElementId
      elementCategoryElementId
      userElementId
    }
  }
`;
export const onDeleteElement = gql`
  subscription OnDeleteElement {
    onDeleteElement {
      id
      key
      elementName
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectElementId
      elementCategoryElementId
      userElementId
    }
  }
`;
export const onCreateElementCategory = gql`
  subscription OnCreateElementCategory {
    onCreateElementCategory {
      id
      key
      categoryName
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userElementCategoryId
    }
  }
`;
export const onUpdateElementCategory = gql`
  subscription OnUpdateElementCategory {
    onUpdateElementCategory {
      id
      key
      categoryName
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userElementCategoryId
    }
  }
`;
export const onDeleteElementCategory = gql`
  subscription OnDeleteElementCategory {
    onDeleteElementCategory {
      id
      key
      categoryName
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userElementCategoryId
    }
  }
`;
export const onCreateSubElement = gql`
  subscription OnCreateSubElement {
    onCreateSubElement {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      priority
      numberOfDrawing
      index
      description
      ctcPredictedDate
      provNumber
      provAlphabet
      entreprisPaperVersion
      entreprisNumber
      entreprisFax
      contractorPaperVersion
      contractorNumber
      contractorFax
      observationsEntrepris
      observationsProjectDirector
      observationsConsultant
      subElementMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectSubElementId
      elementSubElementId
      elementCategorySubElementId
      userSubElementId
    }
  }
`;
export const onUpdateSubElement = gql`
  subscription OnUpdateSubElement {
    onUpdateSubElement {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      priority
      numberOfDrawing
      index
      description
      ctcPredictedDate
      provNumber
      provAlphabet
      entreprisPaperVersion
      entreprisNumber
      entreprisFax
      contractorPaperVersion
      contractorNumber
      contractorFax
      observationsEntrepris
      observationsProjectDirector
      observationsConsultant
      subElementMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectSubElementId
      elementSubElementId
      elementCategorySubElementId
      userSubElementId
    }
  }
`;
export const onDeleteSubElement = gql`
  subscription OnDeleteSubElement {
    onDeleteSubElement {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      priority
      numberOfDrawing
      index
      description
      ctcPredictedDate
      provNumber
      provAlphabet
      entreprisPaperVersion
      entreprisNumber
      entreprisFax
      contractorPaperVersion
      contractorNumber
      contractorFax
      observationsEntrepris
      observationsProjectDirector
      observationsConsultant
      subElementMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectSubElementId
      elementSubElementId
      elementCategorySubElementId
      userSubElementId
    }
  }
`;
export const onCreateDepartment = gql`
  subscription OnCreateDepartment {
    onCreateDepartment {
      id
      key
      departmentName
      departmentRefs
      archive
      position {
        items {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        nextToken
      }
      user {
        items {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        nextToken
      }
      createdAt
      typeCreated
      updatedAt
    }
  }
`;
export const onUpdateDepartment = gql`
  subscription OnUpdateDepartment {
    onUpdateDepartment {
      id
      key
      departmentName
      departmentRefs
      archive
      position {
        items {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        nextToken
      }
      user {
        items {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        nextToken
      }
      createdAt
      typeCreated
      updatedAt
    }
  }
`;
export const onDeleteDepartment = gql`
  subscription OnDeleteDepartment {
    onDeleteDepartment {
      id
      key
      departmentName
      departmentRefs
      archive
      position {
        items {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        nextToken
      }
      user {
        items {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        nextToken
      }
      createdAt
      typeCreated
      updatedAt
    }
  }
`;
export const onCreatePosition = gql`
  subscription OnCreatePosition {
    onCreatePosition {
      id
      key
      positionName
      positionRefs
      archive
      department {
        id
        key
        departmentName
        departmentRefs
        archive
        position {
          nextToken
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
      }
      user {
        items {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        nextToken
      }
      createdAt
      typeCreated
      updatedAt
      departmentPositionId
    }
  }
`;
export const onUpdatePosition = gql`
  subscription OnUpdatePosition {
    onUpdatePosition {
      id
      key
      positionName
      positionRefs
      archive
      department {
        id
        key
        departmentName
        departmentRefs
        archive
        position {
          nextToken
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
      }
      user {
        items {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        nextToken
      }
      createdAt
      typeCreated
      updatedAt
      departmentPositionId
    }
  }
`;
export const onDeletePosition = gql`
  subscription OnDeletePosition {
    onDeletePosition {
      id
      key
      positionName
      positionRefs
      archive
      department {
        id
        key
        departmentName
        departmentRefs
        archive
        position {
          nextToken
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
      }
      user {
        items {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        nextToken
      }
      createdAt
      typeCreated
      updatedAt
      departmentPositionId
    }
  }
`;
export const onCreatePlanning = gql`
  subscription OnCreatePlanning {
    onCreatePlanning {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      subElement {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      planningDate
      status
      planningMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectPlanningId
      elementPlanningId
      subElementPlanningId
      userPlanningId
    }
  }
`;
export const onUpdatePlanning = gql`
  subscription OnUpdatePlanning {
    onUpdatePlanning {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      subElement {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      planningDate
      status
      planningMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectPlanningId
      elementPlanningId
      subElementPlanningId
      userPlanningId
    }
  }
`;
export const onDeletePlanning = gql`
  subscription OnDeletePlanning {
    onDeletePlanning {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      subElement {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      planningDate
      status
      planningMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectPlanningId
      elementPlanningId
      subElementPlanningId
      userPlanningId
    }
  }
`;
export const onCreateTimetable = gql`
  subscription OnCreateTimetable {
    onCreateTimetable {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      subElement {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      planning {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        timetable {
          nextToken
        }
        planningDate
        status
        planningMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectPlanningId
        elementPlanningId
        subElementPlanningId
        userPlanningId
      }
      timetableMemberId
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectTimetableId
      elementTimetableId
      subElementTimetableId
      planningTimetableId
      userTimetableId
    }
  }
`;
export const onUpdateTimetable = gql`
  subscription OnUpdateTimetable {
    onUpdateTimetable {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      subElement {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      planning {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        timetable {
          nextToken
        }
        planningDate
        status
        planningMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectPlanningId
        elementPlanningId
        subElementPlanningId
        userPlanningId
      }
      timetableMemberId
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectTimetableId
      elementTimetableId
      subElementTimetableId
      planningTimetableId
      userTimetableId
    }
  }
`;
export const onDeleteTimetable = gql`
  subscription OnDeleteTimetable {
    onDeleteTimetable {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      subElement {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      planning {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        timetable {
          nextToken
        }
        planningDate
        status
        planningMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectPlanningId
        elementPlanningId
        subElementPlanningId
        userPlanningId
      }
      timetableMemberId
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectTimetableId
      elementTimetableId
      subElementTimetableId
      planningTimetableId
      userTimetableId
    }
  }
`;
export const onCreateTimetableDetail = gql`
  subscription OnCreateTimetableDetail {
    onCreateTimetableDetail {
      id
      key
      date
      time {
        id
        key
        time
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userTimeId
      }
      timetable {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        planning {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        timetableMemberId
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectTimetableId
        elementTimetableId
        subElementTimetableId
        planningTimetableId
        userTimetableId
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      timetableTimetableDetailId
      userTimetableDetailId
      timeTimetableDetailId
    }
  }
`;
export const onUpdateTimetableDetail = gql`
  subscription OnUpdateTimetableDetail {
    onUpdateTimetableDetail {
      id
      key
      date
      time {
        id
        key
        time
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userTimeId
      }
      timetable {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        planning {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        timetableMemberId
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectTimetableId
        elementTimetableId
        subElementTimetableId
        planningTimetableId
        userTimetableId
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      timetableTimetableDetailId
      userTimetableDetailId
      timeTimetableDetailId
    }
  }
`;
export const onDeleteTimetableDetail = gql`
  subscription OnDeleteTimetableDetail {
    onDeleteTimetableDetail {
      id
      key
      date
      time {
        id
        key
        time
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userTimeId
      }
      timetable {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        planning {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        timetableMemberId
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectTimetableId
        elementTimetableId
        subElementTimetableId
        planningTimetableId
        userTimetableId
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      timetableTimetableDetailId
      userTimetableDetailId
      timeTimetableDetailId
    }
  }
`;
export const onCreateUser = gql`
  subscription OnCreateUser {
    onCreateUser {
      id
      key
      firstName
      lastName
      email
      projectManager {
        items {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        nextToken
      }
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      elementCategory {
        items {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      time {
        items {
          id
          key
          time
          createdAt
          typeCreated
          updatedAt
          userTimeId
        }
        nextToken
      }
      department {
        id
        key
        departmentName
        departmentRefs
        archive
        position {
          nextToken
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
      }
      position {
        id
        key
        positionName
        positionRefs
        archive
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentPositionId
      }
      role {
        id
        key
        roleName
        roleRefs
        user {
          nextToken
        }
        projectPrivilege
        projectManager
        projectMember
        readAllProject
        readOwnProject
        createProject
        updateProject
        deleteProject
        elementPrivilege
        readAllElement
        readOwnElement
        createElement
        updateElement
        deleteElement
        subElementPrivilege
        readAllSubElement
        readOwnSubElement
        createSubElement
        updateSubElement
        deleteSubElement
        departmentPrivilege
        readDepartment
        createDepartment
        updateDepartment
        deleteDepartment
        positionPrivilege
        readPosition
        createPosition
        updatePosition
        deletePosition
        planningPrivilege
        readAllPlanning
        readOwnPlanning
        createPlanning
        updatePlanning
        deletePlanning
        timetablePrivilege
        readAllTimetable
        readOwnTimetable
        createTimetable
        updateTimetable
        deleteTimetable
        userPrivilege
        readUser
        createUser
        updateUser
        deleteUser
        rolePrivilege
        readRole
        createRole
        updateRole
        deleteRole
        settingPrivilege
        technicalPrivilege
        managementPrivilege
        elementCategoryPrivilege
        readElementCategory
        createElementCategory
        updateElementCategory
        deleteElementCategory
        manageTimetablePrivilege
        readManageTimetable
        createManageTimetable
        updateManageTimetable
        deleteManageTimetable
        createdAt
        typeCreated
        updatedAt
      }
      avatar
      project {
        items {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        nextToken
      }
      createdAt
      typeCreated
      updatedAt
      departmentUserId
      positionUserId
      roleUserId
    }
  }
`;
export const onUpdateUser = gql`
  subscription OnUpdateUser {
    onUpdateUser {
      id
      key
      firstName
      lastName
      email
      projectManager {
        items {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        nextToken
      }
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      elementCategory {
        items {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      time {
        items {
          id
          key
          time
          createdAt
          typeCreated
          updatedAt
          userTimeId
        }
        nextToken
      }
      department {
        id
        key
        departmentName
        departmentRefs
        archive
        position {
          nextToken
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
      }
      position {
        id
        key
        positionName
        positionRefs
        archive
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentPositionId
      }
      role {
        id
        key
        roleName
        roleRefs
        user {
          nextToken
        }
        projectPrivilege
        projectManager
        projectMember
        readAllProject
        readOwnProject
        createProject
        updateProject
        deleteProject
        elementPrivilege
        readAllElement
        readOwnElement
        createElement
        updateElement
        deleteElement
        subElementPrivilege
        readAllSubElement
        readOwnSubElement
        createSubElement
        updateSubElement
        deleteSubElement
        departmentPrivilege
        readDepartment
        createDepartment
        updateDepartment
        deleteDepartment
        positionPrivilege
        readPosition
        createPosition
        updatePosition
        deletePosition
        planningPrivilege
        readAllPlanning
        readOwnPlanning
        createPlanning
        updatePlanning
        deletePlanning
        timetablePrivilege
        readAllTimetable
        readOwnTimetable
        createTimetable
        updateTimetable
        deleteTimetable
        userPrivilege
        readUser
        createUser
        updateUser
        deleteUser
        rolePrivilege
        readRole
        createRole
        updateRole
        deleteRole
        settingPrivilege
        technicalPrivilege
        managementPrivilege
        elementCategoryPrivilege
        readElementCategory
        createElementCategory
        updateElementCategory
        deleteElementCategory
        manageTimetablePrivilege
        readManageTimetable
        createManageTimetable
        updateManageTimetable
        deleteManageTimetable
        createdAt
        typeCreated
        updatedAt
      }
      avatar
      project {
        items {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        nextToken
      }
      createdAt
      typeCreated
      updatedAt
      departmentUserId
      positionUserId
      roleUserId
    }
  }
`;
export const onDeleteUser = gql`
  subscription OnDeleteUser {
    onDeleteUser {
      id
      key
      firstName
      lastName
      email
      projectManager {
        items {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        nextToken
      }
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      elementCategory {
        items {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      time {
        items {
          id
          key
          time
          createdAt
          typeCreated
          updatedAt
          userTimeId
        }
        nextToken
      }
      department {
        id
        key
        departmentName
        departmentRefs
        archive
        position {
          nextToken
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
      }
      position {
        id
        key
        positionName
        positionRefs
        archive
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentPositionId
      }
      role {
        id
        key
        roleName
        roleRefs
        user {
          nextToken
        }
        projectPrivilege
        projectManager
        projectMember
        readAllProject
        readOwnProject
        createProject
        updateProject
        deleteProject
        elementPrivilege
        readAllElement
        readOwnElement
        createElement
        updateElement
        deleteElement
        subElementPrivilege
        readAllSubElement
        readOwnSubElement
        createSubElement
        updateSubElement
        deleteSubElement
        departmentPrivilege
        readDepartment
        createDepartment
        updateDepartment
        deleteDepartment
        positionPrivilege
        readPosition
        createPosition
        updatePosition
        deletePosition
        planningPrivilege
        readAllPlanning
        readOwnPlanning
        createPlanning
        updatePlanning
        deletePlanning
        timetablePrivilege
        readAllTimetable
        readOwnTimetable
        createTimetable
        updateTimetable
        deleteTimetable
        userPrivilege
        readUser
        createUser
        updateUser
        deleteUser
        rolePrivilege
        readRole
        createRole
        updateRole
        deleteRole
        settingPrivilege
        technicalPrivilege
        managementPrivilege
        elementCategoryPrivilege
        readElementCategory
        createElementCategory
        updateElementCategory
        deleteElementCategory
        manageTimetablePrivilege
        readManageTimetable
        createManageTimetable
        updateManageTimetable
        deleteManageTimetable
        createdAt
        typeCreated
        updatedAt
      }
      avatar
      project {
        items {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        nextToken
      }
      createdAt
      typeCreated
      updatedAt
      departmentUserId
      positionUserId
      roleUserId
    }
  }
`;
export const onCreateTime = gql`
  subscription OnCreateTime {
    onCreateTime {
      id
      key
      time
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userTimeId
    }
  }
`;
export const onUpdateTime = gql`
  subscription OnUpdateTime {
    onUpdateTime {
      id
      key
      time
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userTimeId
    }
  }
`;
export const onDeleteTime = gql`
  subscription OnDeleteTime {
    onDeleteTime {
      id
      key
      time
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userTimeId
    }
  }
`;
export const onCreateRole = gql`
  subscription OnCreateRole {
    onCreateRole {
      id
      key
      roleName
      roleRefs
      user {
        items {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        nextToken
      }
      projectPrivilege
      projectManager
      projectMember
      readAllProject
      readOwnProject
      createProject
      updateProject
      deleteProject
      elementPrivilege
      readAllElement
      readOwnElement
      createElement
      updateElement
      deleteElement
      subElementPrivilege
      readAllSubElement
      readOwnSubElement
      createSubElement
      updateSubElement
      deleteSubElement
      departmentPrivilege
      readDepartment
      createDepartment
      updateDepartment
      deleteDepartment
      positionPrivilege
      readPosition
      createPosition
      updatePosition
      deletePosition
      planningPrivilege
      readAllPlanning
      readOwnPlanning
      createPlanning
      updatePlanning
      deletePlanning
      timetablePrivilege
      readAllTimetable
      readOwnTimetable
      createTimetable
      updateTimetable
      deleteTimetable
      userPrivilege
      readUser
      createUser
      updateUser
      deleteUser
      rolePrivilege
      readRole
      createRole
      updateRole
      deleteRole
      settingPrivilege
      technicalPrivilege
      managementPrivilege
      elementCategoryPrivilege
      readElementCategory
      createElementCategory
      updateElementCategory
      deleteElementCategory
      manageTimetablePrivilege
      readManageTimetable
      createManageTimetable
      updateManageTimetable
      deleteManageTimetable
      createdAt
      typeCreated
      updatedAt
    }
  }
`;
export const onUpdateRole = gql`
  subscription OnUpdateRole {
    onUpdateRole {
      id
      key
      roleName
      roleRefs
      user {
        items {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        nextToken
      }
      projectPrivilege
      projectManager
      projectMember
      readAllProject
      readOwnProject
      createProject
      updateProject
      deleteProject
      elementPrivilege
      readAllElement
      readOwnElement
      createElement
      updateElement
      deleteElement
      subElementPrivilege
      readAllSubElement
      readOwnSubElement
      createSubElement
      updateSubElement
      deleteSubElement
      departmentPrivilege
      readDepartment
      createDepartment
      updateDepartment
      deleteDepartment
      positionPrivilege
      readPosition
      createPosition
      updatePosition
      deletePosition
      planningPrivilege
      readAllPlanning
      readOwnPlanning
      createPlanning
      updatePlanning
      deletePlanning
      timetablePrivilege
      readAllTimetable
      readOwnTimetable
      createTimetable
      updateTimetable
      deleteTimetable
      userPrivilege
      readUser
      createUser
      updateUser
      deleteUser
      rolePrivilege
      readRole
      createRole
      updateRole
      deleteRole
      settingPrivilege
      technicalPrivilege
      managementPrivilege
      elementCategoryPrivilege
      readElementCategory
      createElementCategory
      updateElementCategory
      deleteElementCategory
      manageTimetablePrivilege
      readManageTimetable
      createManageTimetable
      updateManageTimetable
      deleteManageTimetable
      createdAt
      typeCreated
      updatedAt
    }
  }
`;
export const onDeleteRole = gql`
  subscription OnDeleteRole {
    onDeleteRole {
      id
      key
      roleName
      roleRefs
      user {
        items {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        nextToken
      }
      projectPrivilege
      projectManager
      projectMember
      readAllProject
      readOwnProject
      createProject
      updateProject
      deleteProject
      elementPrivilege
      readAllElement
      readOwnElement
      createElement
      updateElement
      deleteElement
      subElementPrivilege
      readAllSubElement
      readOwnSubElement
      createSubElement
      updateSubElement
      deleteSubElement
      departmentPrivilege
      readDepartment
      createDepartment
      updateDepartment
      deleteDepartment
      positionPrivilege
      readPosition
      createPosition
      updatePosition
      deletePosition
      planningPrivilege
      readAllPlanning
      readOwnPlanning
      createPlanning
      updatePlanning
      deletePlanning
      timetablePrivilege
      readAllTimetable
      readOwnTimetable
      createTimetable
      updateTimetable
      deleteTimetable
      userPrivilege
      readUser
      createUser
      updateUser
      deleteUser
      rolePrivilege
      readRole
      createRole
      updateRole
      deleteRole
      settingPrivilege
      technicalPrivilege
      managementPrivilege
      elementCategoryPrivilege
      readElementCategory
      createElementCategory
      updateElementCategory
      deleteElementCategory
      manageTimetablePrivilege
      readManageTimetable
      createManageTimetable
      updateManageTimetable
      deleteManageTimetable
      createdAt
      typeCreated
      updatedAt
    }
  }
`;
