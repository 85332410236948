import { useState, useEffect, createRef } from "react"
import { Modal, Row, Col, Button, Form, Input, Typography, Alert, Steps } from "antd"
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom"

// external-component
import Notification from "../../../../../components/Notification/Notification"
import MySpin from "../../../../../components/Spin/Spin";

// aws-amplify
import { Auth } from "aws-amplify"

// shared-method
import { checkUser } from "../../../../../shared/methods/CheckUserAuth"

const { Text, Title } = Typography
const cookies = new Cookies();

const VerifyEmailModal = (props) => {
    const [counter, setCounter] = useState(90);
    const [isLoading, setIsLoading] = useState(false)
    const [checkAuthorized, setCheckAuthorized] = useState(false)
    const [checkMismatchConfirmCode, setCheckMismatchConfirmCode] = useState(false);
    const {
        visibleModal,
        handleVisibleModal,
        verifyEmail
    } = props
    const userEmail = cookies.get('userEmail')
    const userPassword = cookies.get('userPassword')
    const navigate = useNavigate();

    const [form] = Form.useForm()
    let formRef = createRef()

    useEffect(() => {
        if (visibleModal) {
            const timeout = setTimeout(() => {
                if (counter !== 0)
                    setCounter(counter - 1);
            }, 1000);

            return () => {
                if (counter !== 0)
                    clearTimeout(timeout);
            };
        }
    }, [counter, visibleModal]);

    const handleClose = () => {
        handleVisibleModal(false)
    }
    const onFinish = async (values) => {
        const { verifyCode } = values
        const username = userEmail
        const password = userPassword
        const authCode = verifyCode
        try {
            setIsLoading(true)
            const confirm = await Auth.confirmSignUp(username, authCode)
            if (confirm === "SUCCESS") {
                const checkSignin = await Auth.signIn({ username: username, password })
                if (checkSignin) {
                    setIsLoading(false)
                    cookies.remove('userPassword', { path: '/' })
                    cookies.set('isAuthenticated', true, { path: '/' });
                    checkUser()
                    navigate(`/`);
                    handleClose()
                    Notification("success", "Success", "Verification successful!")
                }
            }
        } catch (err) {
            const { code } = err
            console.log(err)
            if (code === "CodeMismatchException") {
                setIsLoading(false)
                setCheckMismatchConfirmCode(true)
            }
            if (code === "LimitExceededException") {
                console.log(`limit exceeded`)
            }
            if (code === "NotAuthorizedException") {
                setIsLoading(false)
                setCheckAuthorized(true)
            }
        }
    }

    const handleResend = async () => {
        const username = userEmail
        try {
            await Auth.resendSignUp(username)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Modal
            title="Verify Email"
            centered
            visible={visibleModal}
            style={{ left: "8%" }}
            maskClosable={false}
            footer={
                <Row gutter={24}>
                    <Col span={8} offset={16}>
                        <Button form="formVerifyEmail" block size="large" type="primary" htmlType="submit">
                            Verify
                        </Button>
                    </Col>
                </Row>
            }
            onCancel={handleClose}
        >
            {
                isLoading ? <MySpin /> :
                    <Form id="formVerifyEmail" form={form} ref={formRef} layout="vertical" name="nest-messages" onFinish={onFinish}>
                        <Row>
                            <Title level={5}>Email: {userEmail}</Title>
                            <Text>Your verification code has been sent to your email, please check.</Text>
                            <Col span={14} style={{ top: 30 }}>
                                <Form.Item name={'verifyCode'} label="Verify code" rules={[{ required: true }]}>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                            <Col span={7} offset={3} style={{ marginTop: 60 }}>
                                <Button
                                    block size="large"
                                    disabled={counter === 0 ? false : true}
                                    onClick={handleResend}
                                >
                                    {`Resend (${counter}s)`}
                                </Button>
                            </Col>
                            {
                                checkMismatchConfirmCode &&
                                <Alert
                                    description="Invalid verification code provided, please try again."
                                    type="error"
                                    showIcon
                                    style={{ marginTop: 20 }}
                                />
                            }
                            {
                                checkAuthorized &&
                                <Alert
                                    description="Incorrect email or password."
                                    type="error"
                                    showIcon
                                    style={{ marginBottom: 20 }}
                                />
                            }
                        </Row>
                    </Form>
            }
        </Modal>
    )
}

export default VerifyEmailModal