import { API, graphqlOperation } from "aws-amplify"
import { createRole } from "../../graphql/mutations"
import { listRoles } from "../../graphql/queries"
// shared
import { roleValues } from "../../shared/Role"

export const queryRoles = async () => {
    try {
        const rolesData = await API.graphql(graphqlOperation(listRoles))
        const { data: { listRoles: { items } } } = rolesData
        if (items.length === 0) {
            await API.graphql(graphqlOperation(createRole, { input: roleValues }))
        }
    } catch (err) {
        console.log(err)
    }
}