/* eslint-disable */
// this is an auto generated file. This will be overwritten
import { gql } from "@apollo/client"

export const getProject = gql`
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      key
      projectNumber
      projectTitle
      companyName
      location
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      projectManagerId
      projectManager {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      projectMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userProjectManagerId
      userProjectId
    }
  }
`;
export const listProjects = gql`
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      nextToken
    }
  }
`;
export const getElement = gql`
  query GetElement($id: ID!) {
    getElement(id: $id) {
      id
      key
      elementName
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectElementId
      elementCategoryElementId
      userElementId
    }
  }
`;
export const listElements = gql`
  query ListElements(
    $filter: ModelElementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listElements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      nextToken
    }
  }
`;
export const getElementCategory = gql`
  query GetElementCategory($id: ID!) {
    getElementCategory(id: $id) {
      id
      key
      categoryName
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userElementCategoryId
    }
  }
`;
export const listElementCategories = gql`
  query ListElementCategories(
    $filter: ModelElementCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listElementCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      nextToken
    }
  }
`;
export const getSubElement = gql`
  query GetSubElement($id: ID!) {
    getSubElement(id: $id) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      elementCategory {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      priority
      numberOfDrawing
      index
      description
      ctcPredictedDate
      provNumber
      provAlphabet
      entreprisPaperVersion
      entreprisNumber
      entreprisFax
      contractorPaperVersion
      contractorNumber
      contractorFax
      observationsEntrepris
      observationsProjectDirector
      observationsConsultant
      subElementMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectSubElementId
      elementSubElementId
      elementCategorySubElementId
      userSubElementId
    }
  }
`;
export const listSubElements = gql`
  query ListSubElements(
    $filter: ModelSubElementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubElements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      nextToken
    }
  }
`;
export const getDepartment = gql`
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      key
      departmentName
      departmentRefs
      archive
      position {
        items {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        nextToken
      }
      user {
        items {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        nextToken
      }
      createdAt
      typeCreated
      updatedAt
    }
  }
`;
export const listDepartments = gql`
  query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        departmentName
        departmentRefs
        archive
        position {
          nextToken
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPosition = gql`
  query GetPosition($id: ID!) {
    getPosition(id: $id) {
      id
      key
      positionName
      positionRefs
      archive
      department {
        id
        key
        departmentName
        departmentRefs
        archive
        position {
          nextToken
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
      }
      user {
        items {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        nextToken
      }
      createdAt
      typeCreated
      updatedAt
      departmentPositionId
    }
  }
`;
export const listPositions = gql`
  query ListPositions(
    $filter: ModelPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPositions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        positionName
        positionRefs
        archive
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentPositionId
      }
      nextToken
    }
  }
`;
export const getPlanning = gql`
  query GetPlanning($id: ID!) {
    getPlanning(id: $id) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      subElement {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      planningDate
      status
      planningMemberId
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectPlanningId
      elementPlanningId
      subElementPlanningId
      userPlanningId
    }
  }
`;
export const listPlannings = gql`
  query ListPlannings(
    $filter: ModelPlanningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlannings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        timetable {
          nextToken
        }
        planningDate
        status
        planningMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectPlanningId
        elementPlanningId
        subElementPlanningId
        userPlanningId
      }
      nextToken
    }
  }
`;
export const getTimetable = gql`
  query GetTimetable($id: ID!) {
    getTimetable(id: $id) {
      id
      key
      project {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      element {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      subElement {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      planning {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        timetable {
          nextToken
        }
        planningDate
        status
        planningMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectPlanningId
        elementPlanningId
        subElementPlanningId
        userPlanningId
      }
      timetableMemberId
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      projectTimetableId
      elementTimetableId
      subElementTimetableId
      planningTimetableId
      userTimetableId
    }
  }
`;
export const listTimetables = gql`
  query ListTimetables(
    $filter: ModelTimetableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimetables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        planning {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        timetableMemberId
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectTimetableId
        elementTimetableId
        subElementTimetableId
        planningTimetableId
        userTimetableId
      }
      nextToken
    }
  }
`;
export const getTimetableDetail = gql`
  query GetTimetableDetail($id: ID!) {
    getTimetableDetail(id: $id) {
      id
      key
      date
      time {
        id
        key
        time
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userTimeId
      }
      timetable {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        planning {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        timetableMemberId
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectTimetableId
        elementTimetableId
        subElementTimetableId
        planningTimetableId
        userTimetableId
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      timetableTimetableDetailId
      userTimetableDetailId
      timeTimetableDetailId
    }
  }
`;
export const listTimetableDetails = gql`
  query ListTimetableDetails(
    $filter: ModelTimetableDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimetableDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        date
        time {
          id
          key
          time
          createdAt
          typeCreated
          updatedAt
          userTimeId
        }
        timetable {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        timetableTimetableDetailId
        userTimetableDetailId
        timeTimetableDetailId
      }
      nextToken
    }
  }
`;
export const getUser = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      key
      firstName
      lastName
      email
      projectManager {
        items {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        nextToken
      }
      element {
        items {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        nextToken
      }
      elementCategory {
        items {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        nextToken
      }
      subElement {
        items {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        nextToken
      }
      planning {
        items {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        nextToken
      }
      timetable {
        items {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        nextToken
      }
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      time {
        items {
          id
          key
          time
          createdAt
          typeCreated
          updatedAt
          userTimeId
        }
        nextToken
      }
      department {
        id
        key
        departmentName
        departmentRefs
        archive
        position {
          nextToken
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
      }
      position {
        id
        key
        positionName
        positionRefs
        archive
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentPositionId
      }
      role {
        id
        key
        roleName
        roleRefs
        user {
          nextToken
        }
        projectPrivilege
        projectManager
        projectMember
        readAllProject
        readOwnProject
        createProject
        updateProject
        deleteProject
        elementPrivilege
        readAllElement
        readOwnElement
        createElement
        updateElement
        deleteElement
        subElementPrivilege
        readAllSubElement
        readOwnSubElement
        createSubElement
        updateSubElement
        deleteSubElement
        departmentPrivilege
        readDepartment
        createDepartment
        updateDepartment
        deleteDepartment
        positionPrivilege
        readPosition
        createPosition
        updatePosition
        deletePosition
        planningPrivilege
        readAllPlanning
        readOwnPlanning
        createPlanning
        updatePlanning
        deletePlanning
        timetablePrivilege
        readAllTimetable
        readOwnTimetable
        createTimetable
        updateTimetable
        deleteTimetable
        userPrivilege
        readUser
        createUser
        updateUser
        deleteUser
        rolePrivilege
        readRole
        createRole
        updateRole
        deleteRole
        settingPrivilege
        technicalPrivilege
        managementPrivilege
        elementCategoryPrivilege
        readElementCategory
        createElementCategory
        updateElementCategory
        deleteElementCategory
        manageTimetablePrivilege
        readManageTimetable
        createManageTimetable
        updateManageTimetable
        deleteManageTimetable
        createdAt
        typeCreated
        updatedAt
      }
      avatar
      project {
        items {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        nextToken
      }
      createdAt
      typeCreated
      updatedAt
      departmentUserId
      positionUserId
      roleUserId
    }
  }
`;
export const listUsers = gql`
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      nextToken
    }
  }
`;
export const getTime = gql`
  query GetTime($id: ID!) {
    getTime(id: $id) {
      id
      key
      time
      timetableDetail {
        items {
          id
          key
          date
          createdAt
          typeCreated
          updatedAt
          timetableTimetableDetailId
          userTimetableDetailId
          timeTimetableDetailId
        }
        nextToken
      }
      user {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      createdAt
      typeCreated
      updatedAt
      userTimeId
    }
  }
`;
export const listTimes = gql`
  query ListTimes(
    $filter: ModelTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        time
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userTimeId
      }
      nextToken
    }
  }
`;
export const getRole = gql`
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      key
      roleName
      roleRefs
      user {
        items {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        nextToken
      }
      projectPrivilege
      projectManager
      projectMember
      readAllProject
      readOwnProject
      createProject
      updateProject
      deleteProject
      elementPrivilege
      readAllElement
      readOwnElement
      createElement
      updateElement
      deleteElement
      subElementPrivilege
      readAllSubElement
      readOwnSubElement
      createSubElement
      updateSubElement
      deleteSubElement
      departmentPrivilege
      readDepartment
      createDepartment
      updateDepartment
      deleteDepartment
      positionPrivilege
      readPosition
      createPosition
      updatePosition
      deletePosition
      planningPrivilege
      readAllPlanning
      readOwnPlanning
      createPlanning
      updatePlanning
      deletePlanning
      timetablePrivilege
      readAllTimetable
      readOwnTimetable
      createTimetable
      updateTimetable
      deleteTimetable
      userPrivilege
      readUser
      createUser
      updateUser
      deleteUser
      rolePrivilege
      readRole
      createRole
      updateRole
      deleteRole
      settingPrivilege
      technicalPrivilege
      managementPrivilege
      elementCategoryPrivilege
      readElementCategory
      createElementCategory
      updateElementCategory
      deleteElementCategory
      manageTimetablePrivilege
      readManageTimetable
      createManageTimetable
      updateManageTimetable
      deleteManageTimetable
      createdAt
      typeCreated
      updatedAt
    }
  }
`;
export const listRoles = gql`
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        roleName
        roleRefs
        user {
          nextToken
        }
        projectPrivilege
        projectManager
        projectMember
        readAllProject
        readOwnProject
        createProject
        updateProject
        deleteProject
        elementPrivilege
        readAllElement
        readOwnElement
        createElement
        updateElement
        deleteElement
        subElementPrivilege
        readAllSubElement
        readOwnSubElement
        createSubElement
        updateSubElement
        deleteSubElement
        departmentPrivilege
        readDepartment
        createDepartment
        updateDepartment
        deleteDepartment
        positionPrivilege
        readPosition
        createPosition
        updatePosition
        deletePosition
        planningPrivilege
        readAllPlanning
        readOwnPlanning
        createPlanning
        updatePlanning
        deletePlanning
        timetablePrivilege
        readAllTimetable
        readOwnTimetable
        createTimetable
        updateTimetable
        deleteTimetable
        userPrivilege
        readUser
        createUser
        updateUser
        deleteUser
        rolePrivilege
        readRole
        createRole
        updateRole
        deleteRole
        settingPrivilege
        technicalPrivilege
        managementPrivilege
        elementCategoryPrivilege
        readElementCategory
        createElementCategory
        updateElementCategory
        deleteElementCategory
        manageTimetablePrivilege
        readManageTimetable
        createManageTimetable
        updateManageTimetable
        deleteManageTimetable
        createdAt
        typeCreated
        updatedAt
      }
      nextToken
    }
  }
`;
export const listProjectsByCreatedDate = gql`
  query ListProjectsByCreatedDate(
    $typeCreated: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByCreatedDate(
      typeCreated: $typeCreated
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        projectNumber
        projectTitle
        companyName
        location
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        projectManagerId
        projectManager {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        projectMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userProjectManagerId
        userProjectId
      }
      nextToken
    }
  }
`;
export const listElementsByCreatedDate = gql`
  query ListElementsByCreatedDate(
    $typeCreated: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listElementsByCreatedDate(
      typeCreated: $typeCreated
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        elementName
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectElementId
        elementCategoryElementId
        userElementId
      }
      nextToken
    }
  }
`;
export const listElementCategorysByCreatedDate = gql`
  query ListElementCategorysByCreatedDate(
    $typeCreated: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElementCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listElementCategorysByCreatedDate(
      typeCreated: $typeCreated
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        categoryName
        element {
          nextToken
        }
        subElement {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userElementCategoryId
      }
      nextToken
    }
  }
`;
export const listSubElementsByCreatedDate = gql`
  query ListSubElementsByCreatedDate(
    $typeCreated: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubElementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubElementsByCreatedDate(
      typeCreated: $typeCreated
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        elementCategory {
          id
          key
          categoryName
          createdAt
          typeCreated
          updatedAt
          userElementCategoryId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        priority
        numberOfDrawing
        index
        description
        ctcPredictedDate
        provNumber
        provAlphabet
        entreprisPaperVersion
        entreprisNumber
        entreprisFax
        contractorPaperVersion
        contractorNumber
        contractorFax
        observationsEntrepris
        observationsProjectDirector
        observationsConsultant
        subElementMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectSubElementId
        elementSubElementId
        elementCategorySubElementId
        userSubElementId
      }
      nextToken
    }
  }
`;
export const listDepartmentsByCreatedDate = gql`
  query ListDepartmentsByCreatedDate(
    $typeCreated: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartmentsByCreatedDate(
      typeCreated: $typeCreated
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        departmentName
        departmentRefs
        archive
        position {
          nextToken
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPositionsByCreatedDate = gql`
  query ListPositionsByCreatedDate(
    $typeCreated: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPositionsByCreatedDate(
      typeCreated: $typeCreated
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        positionName
        positionRefs
        archive
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        user {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentPositionId
      }
      nextToken
    }
  }
`;
export const listPlanningsByCreatedDate = gql`
  query ListPlanningsByCreatedDate(
    $typeCreated: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlanningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlanningsByCreatedDate(
      typeCreated: $typeCreated
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        timetable {
          nextToken
        }
        planningDate
        status
        planningMemberId
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectPlanningId
        elementPlanningId
        subElementPlanningId
        userPlanningId
      }
      nextToken
    }
  }
`;
export const listTimetablesByCreatedDate = gql`
  query ListTimetablesByCreatedDate(
    $typeCreated: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimetableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimetablesByCreatedDate(
      typeCreated: $typeCreated
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        project {
          id
          key
          projectNumber
          projectTitle
          companyName
          location
          projectManagerId
          projectMemberId
          createdAt
          typeCreated
          updatedAt
          userProjectManagerId
          userProjectId
        }
        element {
          id
          key
          elementName
          createdAt
          typeCreated
          updatedAt
          projectElementId
          elementCategoryElementId
          userElementId
        }
        subElement {
          id
          key
          priority
          numberOfDrawing
          index
          description
          ctcPredictedDate
          provNumber
          provAlphabet
          entreprisPaperVersion
          entreprisNumber
          entreprisFax
          contractorPaperVersion
          contractorNumber
          contractorFax
          observationsEntrepris
          observationsProjectDirector
          observationsConsultant
          subElementMemberId
          createdAt
          typeCreated
          updatedAt
          projectSubElementId
          elementSubElementId
          elementCategorySubElementId
          userSubElementId
        }
        planning {
          id
          key
          planningDate
          status
          planningMemberId
          createdAt
          typeCreated
          updatedAt
          projectPlanningId
          elementPlanningId
          subElementPlanningId
          userPlanningId
        }
        timetableMemberId
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        projectTimetableId
        elementTimetableId
        subElementTimetableId
        planningTimetableId
        userTimetableId
      }
      nextToken
    }
  }
`;
export const listTimetableDetailsByCreatedDate = gql`
  query ListTimetableDetailsByCreatedDate(
    $typeCreated: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimetableDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimetableDetailsByCreatedDate(
      typeCreated: $typeCreated
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        date
        time {
          id
          key
          time
          createdAt
          typeCreated
          updatedAt
          userTimeId
        }
        timetable {
          id
          key
          timetableMemberId
          createdAt
          typeCreated
          updatedAt
          projectTimetableId
          elementTimetableId
          subElementTimetableId
          planningTimetableId
          userTimetableId
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        timetableTimetableDetailId
        userTimetableDetailId
        timeTimetableDetailId
      }
      nextToken
    }
  }
`;
export const listUsersByCreatedDate = gql`
  query ListUsersByCreatedDate(
    $typeCreated: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByCreatedDate(
      typeCreated: $typeCreated
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        firstName
        lastName
        email
        projectManager {
          nextToken
        }
        element {
          nextToken
        }
        elementCategory {
          nextToken
        }
        subElement {
          nextToken
        }
        planning {
          nextToken
        }
        timetable {
          nextToken
        }
        timetableDetail {
          nextToken
        }
        time {
          nextToken
        }
        department {
          id
          key
          departmentName
          departmentRefs
          archive
          createdAt
          typeCreated
          updatedAt
        }
        position {
          id
          key
          positionName
          positionRefs
          archive
          createdAt
          typeCreated
          updatedAt
          departmentPositionId
        }
        role {
          id
          key
          roleName
          roleRefs
          projectPrivilege
          projectManager
          projectMember
          readAllProject
          readOwnProject
          createProject
          updateProject
          deleteProject
          elementPrivilege
          readAllElement
          readOwnElement
          createElement
          updateElement
          deleteElement
          subElementPrivilege
          readAllSubElement
          readOwnSubElement
          createSubElement
          updateSubElement
          deleteSubElement
          departmentPrivilege
          readDepartment
          createDepartment
          updateDepartment
          deleteDepartment
          positionPrivilege
          readPosition
          createPosition
          updatePosition
          deletePosition
          planningPrivilege
          readAllPlanning
          readOwnPlanning
          createPlanning
          updatePlanning
          deletePlanning
          timetablePrivilege
          readAllTimetable
          readOwnTimetable
          createTimetable
          updateTimetable
          deleteTimetable
          userPrivilege
          readUser
          createUser
          updateUser
          deleteUser
          rolePrivilege
          readRole
          createRole
          updateRole
          deleteRole
          settingPrivilege
          technicalPrivilege
          managementPrivilege
          elementCategoryPrivilege
          readElementCategory
          createElementCategory
          updateElementCategory
          deleteElementCategory
          manageTimetablePrivilege
          readManageTimetable
          createManageTimetable
          updateManageTimetable
          deleteManageTimetable
          createdAt
          typeCreated
          updatedAt
        }
        avatar
        project {
          nextToken
        }
        createdAt
        typeCreated
        updatedAt
        departmentUserId
        positionUserId
        roleUserId
      }
      nextToken
    }
  }
`;
export const listTimesByCreatedDate = gql`
  query ListTimesByCreatedDate(
    $typeCreated: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimesByCreatedDate(
      typeCreated: $typeCreated
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        time
        timetableDetail {
          nextToken
        }
        user {
          id
          key
          firstName
          lastName
          email
          avatar
          createdAt
          typeCreated
          updatedAt
          departmentUserId
          positionUserId
          roleUserId
        }
        createdAt
        typeCreated
        updatedAt
        userTimeId
      }
      nextToken
    }
  }
`;
export const listRolesByCreatedDate = gql`
  query ListRolesByCreatedDate(
    $typeCreated: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRolesByCreatedDate(
      typeCreated: $typeCreated
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        roleName
        roleRefs
        user {
          nextToken
        }
        projectPrivilege
        projectManager
        projectMember
        readAllProject
        readOwnProject
        createProject
        updateProject
        deleteProject
        elementPrivilege
        readAllElement
        readOwnElement
        createElement
        updateElement
        deleteElement
        subElementPrivilege
        readAllSubElement
        readOwnSubElement
        createSubElement
        updateSubElement
        deleteSubElement
        departmentPrivilege
        readDepartment
        createDepartment
        updateDepartment
        deleteDepartment
        positionPrivilege
        readPosition
        createPosition
        updatePosition
        deletePosition
        planningPrivilege
        readAllPlanning
        readOwnPlanning
        createPlanning
        updatePlanning
        deletePlanning
        timetablePrivilege
        readAllTimetable
        readOwnTimetable
        createTimetable
        updateTimetable
        deleteTimetable
        userPrivilege
        readUser
        createUser
        updateUser
        deleteUser
        rolePrivilege
        readRole
        createRole
        updateRole
        deleteRole
        settingPrivilege
        technicalPrivilege
        managementPrivilege
        elementCategoryPrivilege
        readElementCategory
        createElementCategory
        updateElementCategory
        deleteElementCategory
        manageTimetablePrivilege
        readManageTimetable
        createManageTimetable
        updateManageTimetable
        deleteManageTimetable
        createdAt
        typeCreated
        updatedAt
      }
      nextToken
    }
  }
`;
