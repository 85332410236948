import { useState, useEffect } from "react"
import { Navigate, Outlet } from "react-router-dom"
import Cookies from 'universal-cookie';

// layout
import AppLayout from "../../layout/AppLayout/AppLayout";

// external-components
import AccessDenied from "../../pages/AccessDenied/AccessDenied";
import LottieWaiting from "../LottieWaiting/LottieWaiting";

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listUsersByCreatedDate } from "../../graphql/queries"

// graphql-sub
import { onUpdateUser } from "../../graphql/subscriptions"

// shared-method
import { checkUser } from "../../shared/methods/CheckUserAuth"
import { queryRoles } from "../../shared/methods/CreateRole"

const cookies = new Cookies();

const ProtectedRoutes = () => {
    const [roles, setRoles] = useState(null)
    const [user, setUser] = useState(null)
    const [checkRole, setCheckRole] = useState(false)
    const [auth, setAuth] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const isAuthenticated = cookies.get('isAuthenticated');
    const userEmail = cookies.get('userEmail');
    const userId = cookies.get('userId');

    let subscriptionOnUpdate;

    useEffect(() => {
        queryRoles()
    }, [])

    // execute subscription
    // useEffect(() => {
    //     subOnUpdateUser()
    //     return () => {
    //         subscriptionOnUpdate.unsubscribe()
    //     }
    // }, [])

    useEffect(() => {
        checkUser()
    }, [])

    useEffect(() => {
        queryUser()
        checkUser()
    }, [user, userEmail])

    const queryUser = async () => {
        try {
            setIsLoading(true)
            const userData = await API.graphql(graphqlOperation(listUsersByCreatedDate, {
                typeCreated: "UserCreated",
                filter: {
                    email: { eq: userEmail }
                }
            }))
            const userItem = userData.data.listUsersByCreatedDate.items[0]
            if (userItem && userItem.roleUserId) {
                setRoles(userItem.role)
            } else {
                setRoles(false)
            }
            setIsLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    // sub update
    const subOnUpdateUser = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onUpdateUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    if (isAuthenticated === "false") {
        return (
            <Navigate to="/login" />
        )
    } else {
        return roles ?
            <AppLayout />
            :
            <>
                {
                    isLoading ? <LottieWaiting /> : <AccessDenied />
                }
            </>
    }
}

export default ProtectedRoutes