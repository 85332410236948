import { useEffect } from "react"
import { Layout } from "antd"
import { useNavigate, Navigate } from "react-router-dom"

// internal-components
import Header from "./components/Header/Header"
import Content from "./components/Content/Content"

const AppLayout = () => {
    return (
        <Layout style={{ minHeight: '90vh' }}>
            <Header />
            <Content />
        </Layout>
    )
}

export default AppLayout