import { useEffect } from "react"
import { Table, Space, Row, Col } from 'antd';

// external-component
import DeleteRecordDrawer from "../../../components/DeleteRecordDrawer/DeleteRecordDrawer";
import LottieLoading from "../../../components/LottieLoading/LottieLoading";

// drawer
import CreatePositionDrawer from './CreatePositionDrawer';
import UpdatePositionDrawer from "./UpdatePositionDrawer";

// apollo-client
import { useQuery } from "@apollo/client"

// graphql-query
import { listPositionsByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreatePosition,
    onUpdatePosition,
    onDeletePosition
} from "../../../graphql/subscriptions"

const columns = [
    {
        title: 'Position Name',
        dataIndex: 'positionName',
        key: 'positionName'
    },
    {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        render: (text, record) => {
            const { department } = record
            return <>
                {department ? department.departmentName : null}
            </>
        }
    },
    {
        title: 'Action',
        key: 'action',
        width: 140,
        fixed: 'right',
        align: 'center',
        render: (text, record) => (
            <Space size="middle">
                <DeleteRecordDrawer
                    id={record.id}
                    module="position"
                    title="Delete the Position?"
                    message={`Position: ${record.positionName}`}
                />
                <UpdatePositionDrawer data={record} />
            </Space>
        ),
    },
];

const PositionDatatable = () => {
    // query position
    const { subscribeToMore, loading, error, data } = useQuery(listPositionsByCreatedDate, {
        variables: {
            typeCreated: "PositionCreated",
            sortDirection: "DESC"
        }
    })

    // on create position
    useEffect(() =>
        subscribeToMore({
            document: onCreatePosition,
            updateQuery: (prev, { subscriptionData }) => {
                const { listPositionsByCreatedDate: { items } } = prev
                if (!subscriptionData.data) return prev
                const { onCreatePosition: positionData } = subscriptionData.data
                return {
                    listPositionsByCreatedDate: {
                        items: [positionData, ...items],
                        nextToken: null,
                        __typename: "Position"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on update position
    useEffect(() =>
        subscribeToMore({
            document: onUpdatePosition,
            updateQuery: (prev) => {
                const { listPositionsByCreatedDate: { items } } = prev
                return {
                    listPositionsByCreatedDate: {
                        items: [...items],
                        nextToken: null,
                        __typename: "Position"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on delete position
    useEffect(() =>
        subscribeToMore({
            document: onDeletePosition,
            updateQuery: (prev, { subscriptionData }) => {
                const { listPositionsByCreatedDate: { items } } = prev
                if (subscriptionData) {
                    const { onDeletePosition: positionData } = subscriptionData.data
                    const lastItems = items.filter(doc => doc.id !== positionData.id)
                    return {
                        listPositionsByCreatedDate: {
                            items: [...lastItems],
                            nextToken: null,
                            __typename: "Position"
                        }
                    }
                }
            }
        }), [subscribeToMore]
    )

    if (loading) return <LottieLoading />
    if (error) console.log(error)
    if (data) {
        const { listPositionsByCreatedDate: { items } } = data
        return (
            <div>
                <Row gutter={24}>
                    {/* <Col span={20}>
                        <Form name="control-hooks">
                            <Form.Item name="note">
                                <Input size="large" onChange={handleSearch} placeholder="Search position" prefix={<SearchOutlined />} />
                            </Form.Item>
                        </Form>
                    </Col> */}
                    <Col span={4} style={{ margin: 5, marginBottom: 15 }}>
                        <CreatePositionDrawer />
                    </Col>
                </Row>
                <Table
                    size="small"
                    scroll={{ y: 900 }}
                    columns={columns}
                    dataSource={items}
                />
            </div>
        )
    }
}

export default PositionDatatable