import { useState, createRef, useEffect } from "react"
import { Button, Drawer, Row, Col, Typography, Form, Select, Input, Divider, Skeleton, Tooltip } from "antd"
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons"

// external-component
import Notification from "../../../../components/Notification/Notification"
import MySpin from "../../../../components/Spin/Spin"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// mutation-update
import { updateProject } from "../../../../graphql/mutations"

// graphql-query
import {
    listUsersByCreatedDate
} from "../../../../graphql/queries"

// graphql-sub
import {
    onCreateUser,
    onUpdateUser,
    onDeleteUser
} from "../../../../graphql/subscriptions"

const { Title } = Typography
const { Option } = Select;

const UpdateProjectDrawer = ({ data }) => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [user, setUser] = useState(null)

    const [form] = Form.useForm()
    let formRef = createRef()
    const validateMessages = {
        required: '${label} is required!'
    };

    const {
        id,
        projectNumber,
        projectTitle,
        companyName,
        location,
        projectManagerId,
        projectMemberId
    } = data

    useEffect(() => {
        queryUsers()
    }, [user])

    useEffect(() => {
        form.setFieldsValue({
            projectNumber: projectNumber,
            projectTitle: projectTitle,
            companyName: companyName,
            location: location,
            projectManagerId: projectManagerId,
            projectMemberId: projectMemberId,
            // projectMemberId: projectMembers ? projectMembers.map((o) => `${o.projectMemberId}`) : null
        })
    }, [data])

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    // execute query car parts
    useEffect(() => {
        queryUsers()
    }, [user])

    // execute subscription
    useEffect(() => {
        subOnCreateUser()
        subOnUpdateUser()
        subOnDeleteUser()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query users
    const queryUsers = async () => {
        try {
            const userData = await API.graphql(graphqlOperation(listUsersByCreatedDate, {
                typeCreated: "UserCreated",
                sortDirection: "DESC"
            }))
            const { data: { listUsersByCreatedDate: { items } } } = userData
            setUsers(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create
    const subOnCreateUser = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onCreateUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update
    const subOnUpdateUser = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onUpdateUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete
    const subOnDeleteUser = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onDeleteUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    const onFinish = async (values) => {
        try {
            values.id = id
            setIsLoading(true)
            await API.graphql(graphqlOperation(updateProject, { input: values }))
            Notification("success", "Success", "Project update successfully!")
            setIsLoading(false)
            closeDrawer()
        } catch (error) {
            console.log(error)
            Notification("error", "Failed", error.message)
        }
    };

    const closeDrawer = () => {
        setVisible(false);
    };
    return (
        // <EditOutlined style={{ fontSize: 23 }} />
        <>
            <Tooltip placement="bottom" title="Update">
                {/* <Button
                    type="text"
                    size="large"
                    icon={<EditOutlined style={{ fontSize: 23 }} />}
                    onClick={() => setVisible(true)}
                    style={{ marginLeft: 5, marginRight: 10 }}
                /> */}
                <EditOutlined
                    onClick={() => setVisible(true)}
                    style={{ fontSize: 23, marginLeft: -10, marginRight: 20 }}
                />
            </Tooltip>
            <Drawer
                title="Update Project"
                placement="right"
                onClose={closeDrawer}
                visible={visible}
                width="500"
            >
                {
                    isLoading ? <MySpin /> :
                        <Form form={form} ref={formRef} name="update-project" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <Form.Item name="projectNumber" label="Project Number" rules={[{ required: true }]}>
                                <Input size="large" />
                            </Form.Item>
                            <Form.Item name="companyName" label="Company" rules={[{ required: true }]}>
                                <Input size="large" />
                            </Form.Item>
                            <Form.Item name="location" label="Location" rules={[{ required: true }]}>
                                <Input size="large" />
                            </Form.Item>
                            <Form.Item name="projectTitle" label="Project Title" rules={[{ required: true }]}>
                                <Input size="large" />
                            </Form.Item>
                            <Divider plain>Member</Divider>
                            <Form.Item name="projectManagerId" label="Project Manager">
                                <Select size="large" allowClear>
                                    {
                                        users ? users.map((data, key) =>
                                            <Select.Option key={key} value={data.id}>{data.firstName} {data.lastName}</Select.Option>
                                        ) : null
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="projectMemberId" label="Project Member">
                                <Select size="large" mode="tags" allowClear>
                                    {
                                        users ? users.map((data, key) =>
                                            <Select.Option key={key} value={data.id}>{data.firstName} {data.lastName}</Select.Option>
                                        ) : null
                                    }
                                </Select>
                            </Form.Item>
                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button icon={<EditOutlined />} block size="large" type="primary" htmlType="submit">
                                            Update
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </>
    )
}

export default UpdateProjectDrawer