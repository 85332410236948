import { Timeline, Col, Typography } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const { Title } = Typography

const ManageTimetableRole = ({ item }) => {
    const {
        // manageTimetablePrivilege,
        readManageTimetable,
        createManageTimetable,
        updateManageTimetable,
        deleteManageTimetable
    } = item

    return (
        <Col span={12} offset={1}>
            <Title level={5} className="timeline-header">Manage Timetable</Title>
            <Timeline>
                <Timeline.Item
                    color={readManageTimetable ? "green" : "red"}
                    dot={readManageTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read
                </Timeline.Item>
                <Timeline.Item
                    color={createManageTimetable ? "green" : "red"}
                    dot={createManageTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Create
                </Timeline.Item>
                <Timeline.Item
                    color={updateManageTimetable ? "green" : "red"}
                    dot={updateManageTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Update
                </Timeline.Item>
                <Timeline.Item
                    color={deleteManageTimetable ? "green" : "red"}
                    dot={deleteManageTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Delete
                </Timeline.Item>
            </Timeline>
        </Col>
    )
}

export default ManageTimetableRole