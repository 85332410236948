import { useState, useEffect } from "react"
import { Collapse, Typography, Row, Col, Timeline, Divider } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import "./UserList.less"

// external-component
import MySpin from "../../../components/Spin/Spin";

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listRolesByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateRole,
    onUpdateRole,
    onDeleteRole
} from "../../../graphql/subscriptions"

const { Panel } = Collapse
const { Title } = Typography

const UserRoleList = (props) => {
    const [roles, setRoles] = useState([])
    const [role, setRole] = useState(null)
    const { id } = props.role

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    // execute query car parts
    useEffect(() => {
        queryRoles()
    }, [role, id])

    // execute subscription
    useEffect(() => {
        subOnCreateRole()
        subOnUpdateRole()
        subOnDeleteRole()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query roles
    const queryRoles = async () => {
        try {
            const roleData = await API.graphql(graphqlOperation(listRolesByCreatedDate, {
                typeCreated: "RoleCreated",
                sortDirection: "DESC",
                filter: {
                    id: { eq: id }
                }
            }))
            const { data: { listRolesByCreatedDate: { items } } } = roleData
            setRoles(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create subModel
    const subOnCreateRole = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateRole))
                .subscribe({
                    next: ({ value }) => {
                        setRole(value.data.onCreateRole)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update subModel
    const subOnUpdateRole = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateRole))
                .subscribe({
                    next: ({ value }) => {
                        setRole(value.data.onUpdateRole)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete subModel
    const subOnDeleteRole = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteRole))
                .subscribe({
                    next: ({ value }) => {
                        setRole(value.data.onDeleteRole)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }
    if (!roles.length) return <MySpin />
    if (roles.length === 0) return <div>No data</div>
    if (roles) {
        const {
            roleName,

            // project
            projectPrivilege,
            createProject,
            updateProject,
            deleteProject,

            // element
            elementPrivilege,
            createElement,
            updateElement,
            deleteElement,

            // sub-element
            subElementPrivilege,
            createSubElement,
            updateSubElement,
            deleteSubElement,

            // planning
            planningPrivilege,
            createPlanning,
            updatePlanning,
            deletePlanning,

            // timetable
            timetablePrivilege,
            createTimetable,
            updateTimetable,
            deleteTimetable,

            // setting
            settingPrivilege,
            technicalPrivilege,
            managementPrivilege,

            // element-category
            elementCategoryPrivilege,
            createElementCategory,
            updateElementCategory,
            deleteElementCategory,

            // manage-timetable
            manageTimetablePrivilege,
            createManageTimetable,
            updateManageTimetable,
            deleteManageTimetable,

            // department
            departmentPrivilege,
            createDepartment,
            updateDepartment,
            deleteDepartment,

            // position
            positionPrivilege,
            createPosition,
            updatePosition,
            deletePosition,

            // role
            rolePrivilege,
            createRole,
            updateRole,
            deleteRole,

            // user
            userPrivilege,
            createUser,
            updateUser,
            deleteUser,
        } = roles[0]

        return (
            <Collapse bordered={false} className="site-collapse-custom-collapse" style={{ marginTop: 20 }}>
                <Panel header={<Title level={5}>Role: {roleName}</Title>} key="1" className="site-collapse-custom-panel">
                    <Row gutter={24}>
                        {/* project */}
                        <Col span={5}>
                            <Timeline>
                                <Timeline.Item
                                    color={projectPrivilege ? "green" : "red"}
                                    dot={projectPrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    <span className="role-sub-child">Project</span>
                                </Timeline.Item>
                                <Timeline.Item
                                    color={createProject ? "green" : "red"}
                                    dot={createProject ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Create
                                </Timeline.Item>
                                <Timeline.Item
                                    color={updateProject ? "green" : "red"}
                                    dot={updateProject ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Update
                                </Timeline.Item>
                                <Timeline.Item
                                    color={deleteProject ? "green" : "red"}
                                    dot={deleteProject ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Delete
                                </Timeline.Item>
                            </Timeline>
                        </Col>

                        {/* element */}
                        <Col span={5}>
                            <Timeline>
                                <Timeline.Item
                                    color={elementPrivilege ? "green" : "red"}
                                    dot={elementPrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    <span className="role-sub-child">Element</span>
                                </Timeline.Item>
                                <Timeline.Item
                                    color={createElement ? "green" : "red"}
                                    dot={createElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Create
                                </Timeline.Item>
                                <Timeline.Item
                                    color={updateElement ? "green" : "red"}
                                    dot={updateElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Update
                                </Timeline.Item>
                                <Timeline.Item
                                    color={deleteElement ? "green" : "red"}
                                    dot={deleteElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Delete
                                </Timeline.Item>
                            </Timeline>
                        </Col>

                        {/* sub-element */}
                        <Col span={5}>
                            <Timeline>
                                <Timeline.Item
                                    color={subElementPrivilege ? "green" : "red"}
                                    dot={subElementPrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    <span className="role-sub-child">Sub Element</span>
                                </Timeline.Item>
                                <Timeline.Item
                                    color={createSubElement ? "green" : "red"}
                                    dot={createSubElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Create
                                </Timeline.Item>
                                <Timeline.Item
                                    color={updateSubElement ? "green" : "red"}
                                    dot={updateSubElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Update
                                </Timeline.Item>
                                <Timeline.Item
                                    color={deleteSubElement ? "green" : "red"}
                                    dot={deleteSubElement ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Delete
                                </Timeline.Item>
                            </Timeline>
                        </Col>

                        {/* planning */}
                        <Col span={5}>
                            <Timeline>
                                <Timeline.Item
                                    color={planningPrivilege ? "green" : "red"}
                                    dot={planningPrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    <span className="role-sub-child">Planning</span>
                                </Timeline.Item>
                                <Timeline.Item
                                    color={createPlanning ? "green" : "red"}
                                    dot={createPlanning ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Create
                                </Timeline.Item>
                                <Timeline.Item
                                    color={updatePlanning ? "green" : "red"}
                                    dot={updatePlanning ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Update
                                </Timeline.Item>
                                <Timeline.Item
                                    color={deletePlanning ? "green" : "red"}
                                    dot={deletePlanning ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Delete
                                </Timeline.Item>
                            </Timeline>
                        </Col>

                        {/* timetable */}
                        <Col span={4}>
                            <Timeline>
                                <Timeline.Item
                                    color={timetablePrivilege ? "green" : "red"}
                                    dot={timetablePrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    <span className="role-sub-child">Timetable</span>
                                </Timeline.Item>
                                <Timeline.Item
                                    color={createTimetable ? "green" : "red"}
                                    dot={createTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Create
                                </Timeline.Item>
                                <Timeline.Item
                                    color={updateTimetable ? "green" : "red"}
                                    dot={updateTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Update
                                </Timeline.Item>
                                <Timeline.Item
                                    color={deleteTimetable ? "green" : "red"}
                                    dot={deleteTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                >
                                    Delete
                                </Timeline.Item>
                            </Timeline>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={24}>
                        <Col span={24}>
                            <Timeline>
                                <Timeline.Item
                                    color={settingPrivilege ? "green" : "red"}
                                    dot={settingPrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                    style={{ marginBottom: -30 }}
                                >
                                    <span className="role-parent">Setting</span>
                                </Timeline.Item>
                            </Timeline>
                        </Col>

                        {/* technical */}
                        <Col span={10} style={{ marginLeft: 30 }}>
                            <Timeline>
                                <Timeline.Item
                                    color={technicalPrivilege ? "green" : "red"}
                                    dot={technicalPrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                    style={{ marginBottom: -30 }}
                                >
                                    <span className="role-child">Technical</span>
                                </Timeline.Item>
                            </Timeline>
                            <Row gutter={24} style={{ marginLeft: 20 }}>
                                {/* element-category */}
                                <Col span={10}>
                                    <Timeline>
                                        <Timeline.Item
                                            color={elementCategoryPrivilege ? "green" : "red"}
                                            dot={elementCategoryPrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            <span className="role-sub-child">Element Category</span>
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={createElementCategory ? "green" : "red"}
                                            dot={createElementCategory ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Create
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={updateElementCategory ? "green" : "red"}
                                            dot={updateElementCategory ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Update
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={deleteElementCategory ? "green" : "red"}
                                            dot={deleteElementCategory ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Delete
                                        </Timeline.Item>
                                    </Timeline>
                                </Col>

                                {/* manage-timetable */}
                                <Col span={12} offset={1}>
                                    <Timeline>
                                        <Timeline.Item
                                            color={manageTimetablePrivilege ? "green" : "red"}
                                            dot={manageTimetablePrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            <span className="role-sub-child">Manage Timetable</span>
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={createManageTimetable ? "green" : "red"}
                                            dot={createManageTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Create
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={updateManageTimetable ? "green" : "red"}
                                            dot={updateManageTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Update
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={deleteManageTimetable ? "green" : "red"}
                                            dot={deleteManageTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Delete
                                        </Timeline.Item>
                                    </Timeline>
                                </Col>
                            </Row>
                        </Col>

                        {/* management */}
                        <Col span={12} style={{ marginLeft: 30 }}>
                            <Timeline>
                                <Timeline.Item
                                    color={managementPrivilege ? "green" : "red"}
                                    dot={managementPrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                    style={{ marginBottom: -30 }}
                                >
                                    <span className="role-child">Management</span>
                                </Timeline.Item>
                            </Timeline>
                            <Row gutter={24} style={{ marginLeft: 20 }}>
                                {/* department */}
                                <Col span={6}>
                                    <Timeline>
                                        <Timeline.Item
                                            color={departmentPrivilege ? "green" : "red"}
                                            dot={departmentPrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            <span className="role-sub-child">Department</span>
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={createDepartment ? "green" : "red"}
                                            dot={createDepartment ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Create
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={updateDepartment ? "green" : "red"}
                                            dot={updateDepartment ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Update
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={deleteDepartment ? "green" : "red"}
                                            dot={deleteDepartment ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Delete
                                        </Timeline.Item>
                                    </Timeline>
                                </Col>

                                {/* position */}
                                <Col span={6}>
                                    <Timeline>
                                        <Timeline.Item
                                            color={positionPrivilege ? "green" : "red"}
                                            dot={positionPrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            <span className="role-sub-child">Position</span>
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={createPosition ? "green" : "red"}
                                            dot={createPosition ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Create
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={updatePosition ? "green" : "red"}
                                            dot={updatePosition ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Update
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={deletePosition ? "green" : "red"}
                                            dot={deletePosition ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Delete
                                        </Timeline.Item>
                                    </Timeline>
                                </Col>

                                {/* role */}
                                <Col span={6}>
                                    <Timeline>
                                        <Timeline.Item
                                            color={rolePrivilege ? "green" : "red"}
                                            dot={rolePrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            <span className="role-sub-child">Role</span>
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={createRole ? "green" : "red"}
                                            dot={createRole ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Create
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={updateRole ? "green" : "red"}
                                            dot={updateRole ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Update
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={deleteRole ? "green" : "red"}
                                            dot={deleteRole ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Delete
                                        </Timeline.Item>
                                    </Timeline>
                                </Col>

                                {/* user */}
                                <Col span={6}>
                                    <Timeline>
                                        <Timeline.Item
                                            color={userPrivilege ? "green" : "red"}
                                            dot={userPrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            <span className="role-sub-child">User</span>
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={createUser ? "green" : "red"}
                                            dot={createUser ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Create
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={updateUser ? "green" : "red"}
                                            dot={updateUser ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Update
                                        </Timeline.Item>
                                        <Timeline.Item
                                            color={deleteUser ? "green" : "red"}
                                            dot={deleteUser ? <CheckCircleFilled /> : <CloseCircleFilled />}
                                        >
                                            Delete
                                        </Timeline.Item>
                                    </Timeline>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        )
    }
}

export default UserRoleList