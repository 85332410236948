import { useState, forwardRef, useImperativeHandle } from "react"
import { Row, Col, Typography, Form, Divider, Checkbox, Card } from "antd"
import "../CreateRoleDrawer.less"

const { Title } = Typography
const CheckboxGroup = Checkbox.Group;

// manageTimetable-options
const manageTimetableOptions = [
    {
        label: 'Read',
        value: 'readManageTimetable'
    },
    {
        label: 'Create',
        value: 'createManageTimetable'
    },
    {
        label: 'Update',
        value: 'updateManageTimetable'
    },
    {
        label: 'Delete',
        value: 'deleteManageTimetable'
    }
];

const CheckManageTimetableGroup = forwardRef((props, ref) => {
    const [checkedManageTimetableGroup, setCheckedManageTimetableGroup] = useState(null);
    const {
        form,
        disabled,
        setIndeterminate,
        setCheckAll,
        checkedManageTimetable,
        setCheckedManageTimetable
    } = props

    useImperativeHandle(ref, () => ({
        onChangeManageTimetable(checked) {
            setCheckedManageTimetableGroup(checked ? manageTimetableOptions.map(v => v.value) : []);
            setCheckedManageTimetable(checked)
            form.setFieldsValue({
                manageTimetableGroup: checked ? manageTimetableOptions.map(v => v.value) : []
            })
        }
    }))

    const onChangeManageTimetable = (e) => {
        const checked = e.target.checked
        setCheckedManageTimetableGroup(checked ? manageTimetableOptions.map(v => v.value) : []);
        setCheckedManageTimetable(checked)
        setIndeterminate(false)
        form.setFieldsValue({
            manageTimetablePrivilege: checked,
            manageTimetableGroup: checked ? manageTimetableOptions.map(v => v.value) : []
        })
    }
    const onChangeManageTimetableGroup = (list) => {
        setIndeterminate(!!list.length && list.length < manageTimetableOptions.length);
        setCheckAll(list.length === manageTimetableOptions.length);
    };

    return (
        <Col span={12}>
            <Card bordered={false} className="small-card-container">
                <Form.Item name="manageTimetablePrivilege" valuePropName="checked" style={{ marginBottom: -10, marginTop: 0 }}>
                    <Checkbox
                        onChange={onChangeManageTimetable}
                        checked={checkedManageTimetable}
                        disabled={disabled ? false : true}
                    >
                        <span className={disabled ? 'child-checkbox-label' : 'child-checkbox-label-disabled'}>
                            Manage Timetable
                        </span>
                    </Checkbox>
                </Form.Item>
                <Divider />
                <Form.Item name="manageTimetableGroup" style={{ marginBottom: 0, marginTop: -10 }}>
                    <Checkbox.Group>
                        <Row>
                            <Col span={24}> 
                                <Checkbox
                                    value="readManageTimetable"
                                    disabled={checkedManageTimetable ? false : true}
                                    onChange={onChangeManageTimetableGroup}
                                >
                                    Read
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="createManageTimetable"
                                    disabled={checkedManageTimetable ? false : true}
                                    onChange={onChangeManageTimetableGroup}
                                >
                                    Create
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="updateManageTimetable"
                                    disabled={checkedManageTimetable ? false : true}
                                    onChange={onChangeManageTimetableGroup}
                                >
                                    Update
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="deleteManageTimetable"
                                    disabled={checkedManageTimetable ? false : true}
                                    onChange={onChangeManageTimetableGroup}
                                >
                                    Delete
                                </Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                    {/* <CheckboxGroup
                        disabled={checkedManageTimetable ? false : true}
                        options={manageTimetableOptions}
                        value={checkedManageTimetableGroup}
                        onChange={onChangeManageTimetableGroup}
                    /> */}
                </Form.Item>
            </Card>
        </Col>
    )
})

export default CheckManageTimetableGroup