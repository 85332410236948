import { useState } from "react"
import { Button, Drawer, Row, Col, TimePicker, Form, Divider } from "antd"
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons"
import { v4 as uuidv4 } from 'uuid';

// external-component
import Notification from "../../../components/Notification/Notification"
import Spin from "../../../components/Spin/Spin"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// mutation-create
import { createTime } from "../../../graphql/mutations"


const CreateTimeCategoryDrawer = () => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()

    const validateMessages = {
        required: '${label} is required!'
    };

    const onFinish = async (values) => {
        try {
            values.typeCreated = "TimeCreated"
            values.key = uuidv4()
            setIsLoading(true)
            await API.graphql(graphqlOperation(createTime, { input: values }))
            Notification("success", "Success", "Time create successfully!")
            setIsLoading(false)
            form.resetFields()
        } catch (error) {
            console.log(error)
        }
    };

    const onClose = () => {
        setVisible(false);
    };

    return (
        <div>
            <Button
                type="primary"
                size="large"
                onClick={() => setVisible(true)}
                // style={{ float: "right" }}
            >
                Create Time
            </Button>
            <Drawer
                title="Create Time"
                onClose={onClose}
                visible={visible}
                width="500"
            >
                {
                    isLoading ? <Spin /> :
                        <Form form={form} name="nest-messages" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <Form.Item name="time" label="Time" rules={[{ required: true }]}>
                                <TimePicker format="HH:mm" size="large" style={{ width: "100%" }} />
                            </Form.Item>
                            {/* <Form.Item name="date" label="Date" rules={[{ required: true }]}>
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item> */}
                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button icon={<SaveOutlined />} block size="large" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </div>
    )
}

export default CreateTimeCategoryDrawer