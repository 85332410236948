import { useState, useEffect } from "react"
import { Typography, Tabs, Radio } from "antd"
import Cookies from 'universal-cookie';

// external-component
import LottieLoading from "../../components/LottieLoading/LottieLoading";

// pages
import Department from "../Department/Department";
import Position from "../Position/Position";
import Role from "../Role/Role";
import ElementCategory from "../ElementCategory/ElementCategory";
import User from "../User/User";
import Time from "../Time/Time";

// apollo-client
import { useQuery } from "@apollo/client"

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import {
    listUsers,
    listRoles
} from "../../graphql/queries"

// graphql-sub
import {
    onUpdateUser,
    onUpdateRole
} from "../../graphql/subscriptions"

const { Title } = Typography
const { TabPane } = Tabs;
const cookies = new Cookies();

const Setting = () => {
    const [settingType, setSettingType] = useState("technical")
    const roleId = cookies.get('roleId')

    const onChangeRadio = e => {
        setSettingType(e.target.value)
    }

    // query role
    const { subscribeToMore, loading, error, data } = useQuery(listRoles, {
        variables: {
            filter: {
                id: { eq: roleId ? roleId : null }
            }
        }
    })

    // on update role
    useEffect(() =>
        subscribeToMore({
            document: onUpdateRole,
            updateQuery: (prev) => {
                const { listRoles: { items } } = prev
                return {
                    listRoles: {
                        items: [...items],
                        nextToken: null,
                        __typename: "Role"
                    }
                }
            }
        }), [subscribeToMore]
    )

    if (loading) return <LottieLoading />
    if (error) console.log(error)
    if (data) {
        const { listRoles: { items } } = data

        return (
            <div style={{ padding: 32, paddingLeft: 64, paddingRight: 64 }}>
                <Title level={4}>Settings</Title>
                <Radio.Group
                    onChange={onChangeRadio}
                    defaultValue="technical"
                    size="large"
                    buttonStyle="solid"
                    style={{ marginTop: 16, marginBottom: 16 }}
                >
                    {
                        <>
                            {
                                items[0].technicalPrivilege === true &&
                                <Radio.Button value="technical">Technical</Radio.Button>
                            }
                            {
                                items[0].managementPrivilege === true &&
                                <Radio.Button value="management">Management</Radio.Button>
                            }
                        </>
                    }
                </Radio.Group>
                {
                    settingType === "technical" ?
                        <Tabs defaultActiveKey="1">
                            {
                                <>
                                    {
                                        items[0].readElementCategory === true &&
                                        <TabPane tab="Element Category" key="1">
                                            <ElementCategory />
                                        </TabPane>
                                    }
                                    {
                                        items[0].readManageTimetable === true &&
                                        <TabPane tab="Manage Timetable" key="2">
                                            <Time />
                                        </TabPane>
                                    }
                                </>
                            }
                        </Tabs>
                        :
                        <Tabs defaultActiveKey="1">
                            {
                                <>
                                    {
                                        items[0].readDepartment === true &&
                                        <TabPane tab="Department" key="1">
                                            <Department />
                                        </TabPane>
                                    }
                                    {
                                        items[0].readPosition === true &&
                                        <TabPane tab="Position" key="2">
                                            <Position />
                                        </TabPane>
                                    }
                                </>
                            }
                            {
                                items[0].readRole === true &&
                                <TabPane tab="Role" key="3">
                                    <Role />
                                </TabPane>
                            }
                            {
                                items[0].readUser === true &&
                                <TabPane tab="User" key="4">
                                    <User />
                                </TabPane>
                            }
                        </Tabs>
                }
            </div>
        )
    }
}

export default Setting