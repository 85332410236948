import { Row, Col, Alert,Typography } from "antd"
import "./AccessDenied.less"

// external-components
import MyHeader from "../../layout/AppLayout/components/Header/Header"
import LottieAccessDenied from "../../components/LottieAccessDenied/LottieAccessDenied"

const {Title} = Typography

const AccessDenied = () => {
    return (
        <>
            <MyHeader />
            <LottieAccessDenied />
            <Row>
                <Col span={6} offset={11}>
                    <Alert
                        message={<Title level={4}>Access Denied</Title>}
                        description={<Title level={5}>Please ask the administrator to grant you access.</Title>}
                        type="warning"
                        showIcon
                        style={{ marginTop: -180}}
                    />
                </Col>
            </Row>
        </>
    )
}

export default AccessDenied