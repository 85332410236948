import { useState, useEffect } from "react"
import { Form, Col, Row, Select } from "antd"

// external-component
import SelectProject from "../../../../components/Select/SelectProject"
import SelectElement from "../../../../components/Select/SelectElement"
import SelectElementCategory from "../../../../components/Select/SelectElementCategory"

// internal-component
import SubElementReport from "./SubElementReport"

const SubElementList = () => {
    const [limitList, setLimitList] = useState([])
    const [limit, setLimit] = useState(null)

    const loadLimit = () => {
        let limits = []
        for (let i = 10; i <= 300; i += 10) {
            limits.push(i)
        }
        setLimitList(limits)
    }

    useEffect(() => {
        loadLimit()
    }, [])

    return (
        <div style={{ padding: 20 }}>
            <Form layout="vertical">
                <Row gutter={24}>
                    <Col span={4}>
                        <SelectProject path="elementList" />
                    </Col>
                    <Col span={4}>
                        <SelectElementCategory path="elementList" />
                    </Col>
                    <Col span={6}>
                        <SelectElement path="elementList" />
                    </Col>
                    {/* <Col span={4}>
                        <Form.Item label="Limit">
                            <Select
                                showSearch
                                allowClear
                                size="large"
                                style={{ width: "100%" }}
                                onSelect={(value) => setLimit(value)}
                                onClear={() => setLimit(0)}
                            // defaultValue={projectId !== "0" ? projectId : null}
                            >
                                {
                                    limitList && limitList.map((value, key) => {
                                        return <Select.Option key={key} value={value}>{value}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col> */}
                </Row>
            </Form>
            {/* limit={limit}  */}
            <SubElementReport />
        </div>
    )
}

export default SubElementList