import { Layout } from 'antd';

// router
import { Outlet } from "react-router-dom";

const { Content } = Layout;
const MyContent = () => {
    return (
        <Content style={{ marginTop: 16 }}>
            <div className="site-layout-background" style={{ minHeight: '90vh' }}>
                <Outlet />
            </div>
        </Content>
    )
}

export default MyContent