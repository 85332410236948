import { useEffect, useContext } from "react"
import { Table, Space, Tag } from "antd"
import moment from "moment"
import { useParams } from "react-router-dom"

// internal-component
import UpdatePlanningDrawer from "./UpdatePlanningDrawer"
import PlanningTimeConsuming from "./PlanningTimeConsuming"

// external-component
import DeleteRecordDrawer from "../../../components/DeleteRecordDrawer/DeleteRecordDrawer"
import LottieLoading from "../../../components/LottieLoading/LottieLoading"
import ViewImage from "../../../components/ViewImage/ViewImage"

// apollo-client
import { useLazyQuery, useQuery } from "@apollo/client"

// graphql-query
import { listPlanningsByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreatePlanning,
    onUpdatePlanning,
    onDeletePlanning
} from "../../../graphql/subscriptions"

// shared-context
import { CountTotalPlanningContext } from "../../../shared/context/CountTotalPlanning"

const { Column } = Table

const PlanningDatatable = () => {
    const { projectId, elementId, userId } = useParams()

    // context
    const { setCountTotalPlanning } = useContext(CountTotalPlanningContext)

    useEffect(() => {
        loadPlanning()
    }, [projectId, elementId, userId])

    // query planning
    const [loadPlanning, { subscribeToMore, loading, error, data }] = useLazyQuery(listPlanningsByCreatedDate, {
        variables: {
            typeCreated: "PlanningCreated",
            sortDirection: "DESC",
            limit: 500,
            // filter: {
            //     projectPlanningId: { contains: !projectId ? "" : projectId !== "0" ? projectId : "" },
            //     elementPlanningId: { contains: !elementId ? "" : elementId !== "0" ? elementId : "" },
            //     userPlanningId: { contains: !userId ? "" : userId !== "0" ? userId : "" }
            // }
        }
    })

    // on create planning
    useEffect(() =>
        subscribeToMore({
            document: onCreatePlanning,
            updateQuery: (prev, { subscriptionData }) => {
                const { listPlanningsByCreatedDate: { items } } = prev
                if (!subscriptionData.data) return prev
                const { onCreatePlanning: planningData } = subscriptionData.data
                return {
                    listPlanningsByCreatedDate: {
                        items: [planningData, ...items],
                        nextToken: null,
                        __typename: "Planning"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on update planning
    useEffect(() =>
        subscribeToMore({
            document: onUpdatePlanning,
            updateQuery: (prev) => {
                const { listPlanningsByCreatedDate: { items } } = prev
                return {
                    listPlanningsByCreatedDate: {
                        items: [...items],
                        nextToken: null,
                        __typename: "Planning"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on delete planning
    useEffect(() =>
        subscribeToMore({
            document: onDeletePlanning,
            updateQuery: (prev, { subscriptionData }) => {
                const { listPlanningsByCreatedDate: { items } } = prev
                if (subscriptionData) {
                    const { onDeletePlanning: planningData } = subscriptionData.data
                    const lastItems = items.filter(doc => doc.id !== planningData.id)
                    return {
                        listPlanningsByCreatedDate: {
                            items: [...lastItems],
                            nextToken: null,
                            __typename: "Planning"
                        }
                    }
                }
            }
        }), [subscribeToMore]
    )

    if (loading) return <LottieLoading />
    if (error) console.log(error)
    if (data) {
        const { listPlanningsByCreatedDate: { items } } = data
        setCountTotalPlanning(items.length)
        return (
            <Table
                bordered
                dataSource={items}
                size="small"
                pagination={{
                    pageSize: 100,
                }}
                scroll={{ y: 500 }}
            >
                <Column
                    title="Project"
                    dataIndex="project"
                    key="project"
                    align="center"
                    render={(_, record) => {
                        return (
                            record.project ?
                                record.project.projectNumber : ""
                        )
                    }}
                />
                {/* <Column
                    title="Element Category"
                    dataIndex="elementCategory"
                    key="elementCategory"
                    align="center"
                    // width={200}
                    render={(_, record) => {
                        return (
                            record.element ?
                                record.element.elementCategory.categoryName : ""
                        )
                    }}
                /> */}
                <Column
                    title={<NumberSub />}
                    dataIndex="numberOfDrawing"
                    key="numberOfDrawing"
                    // width={150}
                    align="center"
                    render={(_, record) => {
                        return (
                            record.subElement ?
                                record.subElement.numberOfDrawing : ""
                        )
                    }}
                />
                <Column
                    title="Element"
                    dataIndex="element"
                    key="element"
                    width={500}
                    render={(_, record) => {
                        return (
                            record.element ?
                                record.element.elementName : ""
                        )
                    }}
                />
                <Column
                    title="Ind"
                    dataIndex="index"
                    key="index"
                    align="center"
                    width={80}
                    render={(_, record) => {
                        return (
                            record.subElement ?
                                record.subElement.index : ""
                        )
                    }}
                />
                <Column
                    title="Prov"
                    dataIndex="prov"
                    key="prov"
                    align="center"
                    width={80}
                    render={(_, record) => {
                        const provNumber = record.subElement.provNumber ? record.subElement.provNumber : ""
                        const provAlphabet = record.subElement.provAlphabet ? record.subElement.provAlphabet : ""
                        return (
                            `${provNumber}${provAlphabet}`
                        )
                    }}
                />
                <Column
                    title="Date"
                    dataIndex="planningDate"
                    key="planningDate"
                    // width={150}
                    align="center"
                    render={(_, record) => {
                        return (
                            record.planningDate ?
                                moment(record.planningDate).format('DD/MM/YYYY') : ""
                        )
                    }}
                />
                <Column
                    title="Consuming"
                    dataIndex="timeConsuming"
                    key="timeConsuming"
                    // width={150}
                    align="center"
                    render={(_, record) => {
                        const { subElementPlanningId } = record
                        return <PlanningTimeConsuming subElementId={subElementPlanningId} />
                    }}
                />
                <Column
                    title="Status"
                    dataIndex="planningDate"
                    key="planningDate"
                    align="center"
                    render={(_, record) => {
                        const { status } = record
                        if (status === "todo") return <Tag color="gold">DOING</Tag>
                        if (status === "in-progress") return <Tag color="blue">IN PROGRESS</Tag>
                        if (status === "done") return <Tag color="green">DONE</Tag>
                    }}
                />
                <Column
                    title="Creator"
                    dataIndex="user"
                    key="user"
                    align="center"
                    render={(_, record) => {
                        const { user } = record
                        return (
                            <>
                                {
                                    user && <>
                                        <span>{user.firstName} {user.lastName}</span>
                                    </>
                                }
                            </>
                        )
                    }}
                />
                <Column
                    title="Action"
                    dataIndex="action"
                    key="action"
                    // width={35}
                    align="center"
                    render={(_, record) => {
                        return (
                            <Space size="middle">
                                <DeleteRecordDrawer
                                    id={record.id}
                                    module="planning"
                                    title="Delete the Planning?"
                                />
                                <UpdatePlanningDrawer data={record} />
                            </Space>
                        )
                    }}
                />
            </Table>
        )
    }
}

const NumberSub = () => {
    return (
        <div>
            N<sup>o</sup>
        </div>
    )
}

export default PlanningDatatable