import { Timeline, Col, Typography } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const { Title } = Typography

const UserRole = ({ item }) => {
    const {
        // userPrivilege,
        readUser,
        createUser,
        updateUser,
        deleteUser
    } = item

    return (
        <Col span={6}>
            <Title level={5} className="timeline-header">User</Title>
            <Timeline>
                <Timeline.Item
                    color={readUser ? "green" : "red"}
                    dot={readUser ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read
                </Timeline.Item>
                <Timeline.Item
                    color={createUser ? "green" : "red"}
                    dot={createUser ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Create
                </Timeline.Item>
                <Timeline.Item
                    color={updateUser ? "green" : "red"}
                    dot={updateUser ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Update
                </Timeline.Item>
                <Timeline.Item
                    color={deleteUser ? "green" : "red"}
                    dot={deleteUser ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Delete
                </Timeline.Item>
            </Timeline>
        </Col>
    )
}

export default UserRole