import { useState } from "react"
import { Row, Col, Form, Typography, Divider } from "antd"

// external-components
import ViewProjectInfo from "../../components/ViewProjectInfo/ViewProjectInfo"
import ViewElementInfo from "../../components/ViewElementInfo/ViewElementInfo"
import ViewUserInfo from "../../components/ViewUserInfo/ViewUserInfo"

// internal-components
import CreatePlanning from "./components/CreatePlanningDrawer"
import PlanningDatatable from "./components/PlanningDatatable"

// internal-select-components
import SelectProject from "../../components/Select/SelectProject"
import SelectElement from "../../components/Select/SelectElement"
import SelectUser from "../../components/Select/SelectUser"

// shared-context
import { CountTotalPlanningProvider } from "../../shared/context/CountTotalPlanning"

const { Title } = Typography

const Planning = () => {
    const [countTotalPlanning, setCountTotalPlanning] = useState(null)
    return (
        <div style={{ padding: 20 }}>
            <CountTotalPlanningProvider value={{ countTotalPlanning, setCountTotalPlanning }}>
                <Title level={2}>Planning (Total : {countTotalPlanning ? countTotalPlanning : 0})</Title>
                <Title level={5}>
                    <ViewProjectInfo />
                    <ViewElementInfo />
                    <ViewUserInfo />
                </Title>
                <Divider />
                <Form layout="vertical">
                    <Row gutter={24}>
                        <Col span={5}>
                            <SelectProject path="planning" />
                        </Col>
                        <Col span={5}>
                            <SelectElement path="planning" />
                        </Col>
                        <Col span={5}>
                            <SelectUser path="planning" />
                        </Col>
                        {/* <Col span={9}>
                        <CreatePlanning />
                    </Col> */}
                    </Row>
                </Form>
                <PlanningDatatable />
            </CountTotalPlanningProvider>
        </div>
    )
}

export default Planning