import { Typography, Empty } from "antd"
import "./MyEmpty.less"

const { Text, Title } = Typography

const MyEmpty = ({ title, des }) => {
    return (
        <div className="nothing-selected-wrapper">
            <Empty
                description={
                    <div>
                        <Title level={5}>{title}</Title>
                        <Text>{des}</Text>
                    </div>
                }
            />
        </div>
    )
}

export default MyEmpty