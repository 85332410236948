import { useState, createRef, useEffect } from "react"
import { Button, Drawer, Row, Col, Tooltip, Form, Input, Select, Divider, DatePicker, Typography } from "antd"
import { CloseCircleOutlined, SaveOutlined, EditOutlined } from "@ant-design/icons"
import moment from "moment"

// external-component
import Notification from "../../../../components/Notification/Notification"
import Spin from "../../../../components/Spin/Spin"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// mutation-create
import { updateSubElement, updatePlanning } from "../../../../graphql/mutations"

// graphql-query
import { listPlannings } from "../../../../graphql/queries"

const { TextArea } = Input
const { Title, Text } = Typography
const { Option } = Select

const UpdateSubElementDrawer = (props) => {
    const [visible, setVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [alphabetList, setAlphabetList] = useState(null)
    const [numberList, setNumberList] = useState(null)

    const { data } = props
    const dateFormat = 'DD/MM/YYYY'
    const [form] = Form.useForm()
    let formRef = createRef()

    const {
        id,
        priority,
        numberOfDrawing,
        index,
        description,
        ctcPredictedDate,
        provNumber,
        provAlphabet,

        // entrepris
        entreprisPaperVersion,
        entreprisNumber,
        entreprisFax,

        // contractor
        contractorPaperVersion,
        contractorNumber,
        contractorFax,

        // observations
        observationsEntrepris,
        observationsProjectDirector,
        observationsConsultant
    } = data

    useEffect(() => {
        getAlphabetList()
        getNumberList()
    }, [])

    const getAlphabetList = () => {
        let alphabet = [{
            value: "Non",
            label: "Non"
        }]
        for (let i = 0; i < 10; i++) {
            let rs = (i + 10).toString(36)
            alphabet.push({
                value: rs,
                label: rs
            })
        }
        setAlphabetList(alphabet)
    }

    const getNumberList = () => {
        let number = [{
            value: "Non",
            label: "Non"
        }]
        for (let i = 0; i <= 10; i++) {
            number.push({
                value: i.toString(),
                label: i.toString()
            })
        }
        setNumberList(number)
    }

    useEffect(() => {
        form.setFieldsValue({
            priority: priority,
            numberOfDrawing: numberOfDrawing,
            index: index,
            description: description,
            entreprisNumber: entreprisNumber,
            contractorNumber: contractorNumber,
            provNumber: provNumber,
            provAlphabet: provAlphabet,
        })
    }, [])

    const onFinish = async (values) => {
        try {
            if (id) {
                const getEntreprisFax = values.entreprisFax ? values.entreprisFax : null
                const getEntreprisPaperVersion = values.entreprisPaperVersion ? values.entreprisPaperVersion : null
                const getContractorFax = values.contractorFax ? values.contractorFax : null
                const getContractorPaperVersion = values.contractorPaperVersion ? values.contractorPaperVersion : null
                const getObservationsConsultant = values.observationsConsultant ? values.observationsConsultant : null
                const getObservationsEntrepris = values.observationsEntrepris ? values.observationsEntrepris : null
                const getObservationsProjectDirector = values.observationsProjectDirector ? values.observationsProjectDirector : null

                const planningData = await API.graphql(graphqlOperation(listPlannings, {
                    filter: {
                        subElementPlanningId: { eq: id }
                    }
                }))
                const { data: { listPlannings: { items } } } = planningData
                const planningId = items[0].id

                if (getEntreprisFax || getEntreprisPaperVersion || getContractorFax || getContractorPaperVersion || getObservationsConsultant || getObservationsEntrepris || getObservationsProjectDirector) {
                    let planningValues = {
                        id: planningId,
                        status: "done"
                    }
                    await API.graphql(graphqlOperation(updatePlanning, { input: planningValues }))
                }
                values.id = id
                setIsLoading(true)
                await API.graphql(graphqlOperation(updateSubElement, { input: values }))
                await API.graphql(graphqlOperation(updatePlanning, { input: { id: planningId } }))
                Notification("success", "Success", `Sub Element update successfully!`)
                setIsLoading(false)
                closeDrawer()
            }
        } catch (error) {
            console.log(error)
            Notification("error", "Failed", error.message)
        }
    };

    const validateMessages = {
        required: '${label} is required!'
    };

    const closeDrawer = () => {
        setVisible(false);
    };
    return (
        <div>
            <Tooltip placement="bottom" title="Update">
                <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={() => setVisible(true)}
                />
            </Tooltip>

            <Drawer
                title={`Update Sub Element`}
                onClose={closeDrawer}
                visible={visible}
                width="1500"
            >
                {
                    isLoading ? <Spin /> :
                        <Form
                            form={form}
                            ref={formRef}
                            name="nest-messages"
                            layout="vertical"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                            fields={[
                                {
                                    name: ["ctcPredictedDate"],
                                    value: ctcPredictedDate ? moment(ctcPredictedDate) : null
                                },
                                {
                                    name: ["entreprisPaperVersion"],
                                    value: entreprisPaperVersion ? moment(entreprisPaperVersion) : null
                                },
                                {
                                    name: ["entreprisFax"],
                                    value: entreprisFax ? moment(entreprisFax) : null
                                },
                                {
                                    name: ["contractorPaperVersion"],
                                    value: contractorPaperVersion ? moment(contractorPaperVersion) : null
                                },
                                {
                                    name: ["contractorFax"],
                                    value: contractorFax ? moment(contractorFax) : null
                                },
                                {
                                    name: ["observationsEntrepris"],
                                    value: observationsEntrepris ? moment(observationsEntrepris) : null
                                },
                                {
                                    name: ["observationsProjectDirector"],
                                    value: observationsProjectDirector ? moment(observationsProjectDirector) : null
                                },
                                {
                                    name: ["observationsConsultant"],
                                    value: observationsConsultant ? moment(observationsConsultant) : null
                                },
                            ]}
                        >
                            <Row gutter={24}>
                                <Col span={4}>
                                    <Title level={5} style={{ color: "#fff" }}>observations</Title>
                                    <Form.Item name="priority" label="Priority">
                                        <Input size="large" />
                                    </Form.Item>
                                    <Form.Item name="numberOfDrawing" label="Number of Drawing" >
                                        <Input size="large" />
                                    </Form.Item>
                                    <Form.Item name="index" label="Ind" >
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Title level={5} style={{ color: "#fff" }}>observations</Title>
                                    <Form.Item name="ctcPredictedDate" label="Predicted Date" >
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Text>Prov</Text>
                                            <Form.Item name="provNumber">
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    size="large"
                                                    placeholder="Prov(0)"
                                                    optionFilterProp="children"
                                                    style={{ marginTop: 8 }}
                                                >
                                                    {
                                                        numberList && numberList.map((list, index) =>
                                                            <Option key={index} value={list.value}>{list.label}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="provAlphabet">
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    size="large"
                                                    placeholder="Prov(A)"
                                                    optionFilterProp="children"
                                                    style={{ marginTop: 30 }}
                                                >
                                                    {
                                                        alphabetList && alphabetList.map((list, index) =>
                                                            <Option key={index} value={list.value}>{list.label}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={5}>
                                    <Title level={5}>Client received on:</Title>
                                    <Form.Item name="entreprisPaperVersion" label="Paper Version" >
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                    <Form.Item name="entreprisNumber" label="Nbr" >
                                        <Input size="large" />
                                    </Form.Item>
                                    <Form.Item name="entreprisFax" label="Fax/Internet" >
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Title level={5}>Controller received on:</Title>
                                    <Form.Item name="contractorPaperVersion" label="Paper Version" >
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                    <Form.Item name="contractorNumber" label="Nbr" >
                                        <Input size="large" />
                                    </Form.Item>
                                    <Form.Item name="contractorFax" label="Fax/Internet" >
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Title level={5}>Remark received on:</Title>
                                    <Form.Item name="observationsEntrepris" label="Client" >
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                    <Form.Item name="observationsProjectDirector" label="Designer" >
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                    <Form.Item name="observationsConsultant" label="Controller" >
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="description" label="Description">
                                        <TextArea rows={5} size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={4} offset={16}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} size="large" block type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button icon={<SaveOutlined />} size="large" block type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </div>
    )
}

export default UpdateSubElementDrawer