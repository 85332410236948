import { useState, forwardRef, useImperativeHandle, useEffect } from "react"
import { Col, Typography, Form, Divider, Checkbox, Card, Row } from "antd"
import "../CreateRoleDrawer.less"
import { NavLink } from "react-router-dom";

const { Title } = Typography
const CheckboxGroup = Checkbox.Group;

// project-options
const projectOptions = [
    {
        label: 'Read All Project',
        value: 'readAllProject'
    },
    {
        label: 'Read Own Project',
        value: 'readOwnProject'
    },
    {
        label: 'Create',
        value: 'createProject'
    },
    {
        label: 'Update',
        value: 'updateProject'
    },
    {
        label: 'Delete',
        value: 'deleteProject'
    }
];

const CheckProjectGroup = forwardRef((props, ref) => {
    const [checkedProjectGroup, setCheckedProjectGroup] = useState();
    const {
        form,
        setIndeterminate,
        setCheckAll,
        checkedProject,
        setCheckedProject,
    } = props

    useImperativeHandle(ref, () => ({
        onChangeProject(checked) {
            setCheckedProjectGroup(checked ? projectOptions.map(v => v.value) : []);
            setCheckedProject(checked)
            form.setFieldsValue({
                projectGroup: checked ? projectOptions.map(v => v.value) : []
            })
        }
    }))

    const onChangeProject = (e) => {
        const checked = e.target.checked
        setCheckedProjectGroup(checked ? projectOptions.map(v => v.value) : []);
        setCheckedProject(checked)
        setIndeterminate(false)
        form.setFieldsValue({
            projectPrivilege: checked,
            projectGroup: checked ? projectOptions.map(v => v.value) : []
        })
    }
    const onChangeProjectGroup = (list) => {
        setIndeterminate(!!list.length && list.length < projectOptions.length);
        setCheckAll(list.length === projectOptions.length);
    };

    return (
        <Col span={4}>
            <Card bordered={false} className="large-card-container">
                <Form.Item name="projectPrivilege" valuePropName="checked" style={{ marginBottom: -10, marginTop: 0 }}>
                    <Checkbox onChange={onChangeProject} checked={checkedProject}>
                        <Title level={5} style={{ marginBottom: 0 }}>Project</Title>
                    </Checkbox>
                </Form.Item>
                <Divider />
                <Form.Item name="projectGroup" style={{ marginBottom: 0, marginTop: -10 }}>
                    <Checkbox.Group>
                        <Row gutter={24}>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="readAllProject"
                                    disabled={checkedProject ? false : true}
                                    onChange={onChangeProjectGroup}
                                >
                                    Read All Project
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="readOwnProject"
                                    disabled={checkedProject ? false : true}
                                    onChange={onChangeProjectGroup}
                                >
                                    Read Own Project
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="createProject"
                                    disabled={checkedProject ? false : true}
                                    onChange={onChangeProjectGroup}
                                >
                                    Create
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="updateProject"
                                    disabled={checkedProject ? false : true}
                                    onChange={onChangeProjectGroup}
                                >
                                    Update
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="deleteProject"
                                    disabled={checkedProject ? false : true}
                                    onChange={onChangeProjectGroup}
                                >
                                    Delete
                                </Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                    {/* <CheckboxGroup
                        disabled={checkedProject ? false : true}
                        options={projectOptions}
                        value={checkedProjectGroup}
                        onChange={onChangeProjectGroup}
                    /> */}
                </Form.Item>
            </Card>
        </Col>
    )
})

export default CheckProjectGroup