import { useState, forwardRef, useImperativeHandle } from "react"
import { Row, Col, Typography, Form, Divider, Checkbox, Card } from "antd"
import "../CreateRoleDrawer.less"

const { Title } = Typography
const CheckboxGroup = Checkbox.Group;

// timetable-options
const timetableOptions = [
    {
        label: 'Read All Timetable',
        value: 'readAllTimetable'
    },
    {
        label: 'Read Own Timetable',
        value: 'readOwnTimetable'
    },
    {
        label: 'Create',
        value: 'createTimetable'
    },
    {
        label: 'Update',
        value: 'updateTimetable'
    },
    {
        label: 'Delete',
        value: 'deleteTimetable'
    }
];

const CheckTimetableGroup = forwardRef((props, ref) => {
    const [checkedTimetableGroup, setCheckedTimetableGroup] = useState(null);
    const {
        form,
        setIndeterminate,
        setCheckAll,
        checkedTimetable,
        setCheckedTimetable
    } = props

    useImperativeHandle(ref, () => ({
        onChangeTimetable(checked) {
            setCheckedTimetableGroup(checked ? timetableOptions.map(v => v.value) : []);
            setCheckedTimetable(checked)
            form.setFieldsValue({
                timetableGroup: checked ? timetableOptions.map(v => v.value) : []
            })
        }
    }))

    const onChangeTimetable = (e) => {
        const checked = e.target.checked
        setCheckedTimetableGroup(checked ? timetableOptions.map(v => v.value) : []);
        setCheckedTimetable(checked)
        setIndeterminate(false)
        form.setFieldsValue({
            timetablePrivilege: checked,
            timetableGroup: checked ? timetableOptions.map(v => v.value) : []
        })
    }
    const onChangeTimetableGroup = (list) => {
        setIndeterminate(!!list.length && list.length < timetableOptions.length);
        setCheckAll(list.length === timetableOptions.length);
    };

    return (
        <Col span={4}>
            <Card bordered={false} className="large-card-container">
                <Form.Item name="timetablePrivilege" valuePropName="checked" style={{ marginBottom: -10, marginTop: 0 }}>
                    <Checkbox onChange={onChangeTimetable} checked={checkedTimetable}>
                        <Title level={5} style={{ marginBottom: 0 }}>Timetable</Title>
                    </Checkbox>
                </Form.Item>
                <Divider />
                <Form.Item name="timetableGroup" style={{ marginBottom: 0, marginTop: -10 }}>
                    <Checkbox.Group>
                        <Row>
                            <Col span={24}>
                                <Checkbox
                                    value="readAllTimetable"
                                    disabled={checkedTimetable ? false : true}
                                    onChange={onChangeTimetableGroup}
                                >
                                    Read All Timetable
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="readOwnTimetable"
                                    disabled={checkedTimetable ? false : true}
                                    onChange={onChangeTimetableGroup}
                                >
                                    Read Own Timetable
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="createTimetable"
                                    disabled={checkedTimetable ? false : true}
                                    onChange={onChangeTimetableGroup}
                                >
                                    Create
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="updateTimetable"
                                    disabled={checkedTimetable ? false : true}
                                    onChange={onChangeTimetableGroup}
                                >
                                    Update
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="deleteTimetable"
                                    disabled={checkedTimetable ? false : true}
                                    onChange={onChangeTimetableGroup}
                                >
                                    Delete
                                </Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                    {/* <CheckboxGroup
                        disabled={checkedTimetable ? false : true}
                        options={timetableOptions}
                        value={checkedTimetableGroup}
                        onChange={onChangeTimetableGroup}
                    /> */}
                </Form.Item>
            </Card>
        </Col>
    )
})

export default CheckTimetableGroup