import { useEffect } from "react"
import { Menu, Row, Col, Tag } from "antd"
import { AppstoreAddOutlined } from '@ant-design/icons'
import { Link, useParams } from "react-router-dom"

// external-components
import MyEmpty from "../../../components/MyEmpty/MyEmpty"
import LottieLoading from "../../../components/LottieLoading/LottieLoading"
import DeleteRecordDrawer from "../../../components/DeleteRecordDrawer/DeleteRecordDrawer"

// internal-components
import UpdateElementDrawer from "../Drawers/UpdateElementDrawer"
import CountSubElementByElement from "./CountSubElementByElement"

// apollo-client
import { useLazyQuery } from "@apollo/client"

// graphql-query
import { listElementsByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateElement,
    onUpdateElement,
    onDeleteElement
} from "../../../graphql/subscriptions"

const ElementList = ({ elementCategoryId }) => {
    const { id: projectId } = useParams()

    useEffect(() => {
        loadElement()
    }, [projectId, elementCategoryId])

    // query element
    const [loadElement, { subscribeToMore, loading, error, data }] = useLazyQuery(listElementsByCreatedDate, {
        variables: {
            typeCreated: "ElementCreated",
            sortDirection: "DESC",
            filter: {
                projectElementId: { eq: projectId },
                elementCategoryElementId: { eq: elementCategoryId }
            },
            limit: elementCategoryId ? 500 : null
        }
    })

    // on create element
    useEffect(() =>
        subscribeToMore({
            document: onCreateElement,
            updateQuery: (prev, { subscriptionData }) => {
                const { listElementsByCreatedDate: { items } } = prev
                if (!subscriptionData.data) return prev
                const { onCreateElement: elementData } = subscriptionData.data
                return {
                    listElementsByCreatedDate: {
                        items: [elementData, ...items],
                        nextToken: null,
                        __typename: "Element"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on update element
    useEffect(() =>
        subscribeToMore({
            document: onUpdateElement,
            updateQuery: (prev) => {
                const { listElementsByCreatedDate: { items } } = prev
                return {
                    listElementsByCreatedDate: {
                        items: [...items],
                        nextToken: null,
                        __typename: "Element"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on delete element
    useEffect(() =>
        subscribeToMore({
            document: onDeleteElement,
            updateQuery: (prev, { subscriptionData }) => {
                const { listElementsByCreatedDate: { items } } = prev
                if (subscriptionData) {
                    const { onDeleteElement: elementData } = subscriptionData.data
                    const lastItems = items.filter(doc => doc.id !== elementData.id)
                    return {
                        listElementsByCreatedDate: {
                            items: [...lastItems],
                            nextToken: null,
                            __typename: "Element"
                        }
                    }
                }
            }
        }), [subscribeToMore]
    )

    if (loading) return <LottieLoading />
    if (error) console.log(error)
    if (data) {
        const { listElementsByCreatedDate: { items } } = data
        if (items.length > 0) {
            return (
                <Menu mode="inline">
                    {
                        items.map((item, index) => {
                            const { id, elementName, elementCategory: { categoryName } } = item
                            return (
                                <Menu.Item
                                    key={index}
                                    // icon={<AppstoreAddOutlined />}
                                    icon={
                                        <>
                                            <UpdateElementDrawer data={item} />
                                            <div style={{ marginLeft: -15, marginRight: 15 }}>
                                                <DeleteRecordDrawer
                                                    id={id}
                                                    module="element"
                                                    title={`Delete Element?`}
                                                    btnType="link"
                                                    btnSize="large"
                                                    iconSize={20}
                                                    message={`${categoryName} - ${elementName}`}
                                                />
                                            </div>
                                        </>
                                    }
                                >
                                    <Row>
                                        <Col span={24}>
                                            <Link to={`/${projectId}/${id}`} key={index}>
                                                <CountSubElementByElement elementId={id} /> {categoryName} - {elementName}
                                            </Link>
                                        </Col>
                                    </Row>
                                </Menu.Item>
                            )
                        })
                    }
                </Menu>
            )
        }
    }
    return (
        <Menu mode="inline">
            <Tag color="blue" style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}>
                Please select Element Category.
            </Tag>
            <MyEmpty des="Don't have the element!" />
        </Menu>
    )
}

export default ElementList