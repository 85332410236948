import { useEffect } from "react"
import { Table, Space, Row, Col } from 'antd';

// external-component
import DeleteRecordDrawer from "../../../components/DeleteRecordDrawer/DeleteRecordDrawer";
import LottieLoading from "../../../components/LottieLoading/LottieLoading";

// drawer
import CreateDepartmentDrawer from './CreateDepartmentDrawer';
import UpdateDepartmentDrawer from "./UpdateDepartmentDrawer";

// apollo-client
import { useQuery } from "@apollo/client"

// graphql-query
import { listDepartmentsByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import { onCreateDepartment, onUpdateDepartment, onDeleteDepartment } from "../../../graphql/subscriptions"

const columns = [
    {
        title: 'Department Name',
        dataIndex: 'departmentName',
        key: 'departmentName'
    },
    {
        title: 'Action',
        key: 'action',
        width: 140,
        fixed: 'right',
        align: 'center',
        render: (text, record) => (
            <Space size="middle">
                <DeleteRecordDrawer
                    id={record.id}
                    module="department"
                    title="Delete the Department?"
                    message={`Department: ${record.departmentName}`}
                />
                <UpdateDepartmentDrawer data={record} />
            </Space>
        ),
    },
];

const DepartmentDatatable = () => {
    // query department
    const { subscribeToMore, loading, error, data } = useQuery(listDepartmentsByCreatedDate, {
        variables: {
            typeCreated: "DepartmentCreated",
            sortDirection: "DESC"
        }
    })

    // on create department
    useEffect(() =>
        subscribeToMore({
            document: onCreateDepartment,
            updateQuery: (prev, { subscriptionData }) => {
                const { listDepartmentsByCreatedDate: { items } } = prev
                if (!subscriptionData.data) return prev
                const { onCreateDepartment: departmentData } = subscriptionData.data
                return {
                    listDepartmentsByCreatedDate: {
                        items: [departmentData, ...items],
                        nextToken: null,
                        __typename: "Department"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on update department
    useEffect(() =>
        subscribeToMore({
            document: onUpdateDepartment,
            updateQuery: (prev) => {
                const { listDepartmentsByCreatedDate: { items } } = prev
                return {
                    listDepartmentsByCreatedDate: {
                        items: [...items],
                        nextToken: null,
                        __typename: "Department"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on delete department
    useEffect(() =>
        subscribeToMore({
            document: onDeleteDepartment,
            updateQuery: (prev, { subscriptionData }) => {
                const { listDepartmentsByCreatedDate: { items } } = prev
                if (subscriptionData) {
                    const { onDeleteDepartment: departmentData } = subscriptionData.data
                    const lastItems = items.filter(doc => doc.id !== departmentData.id)
                    return {
                        listDepartmentsByCreatedDate: {
                            items: [...lastItems],
                            nextToken: null,
                            __typename: "Department"
                        }
                    }
                }
            }
        }), [subscribeToMore]
    )

    if (loading) return <LottieLoading />
    if (error) console.log(error)
    if (data) {
        const { listDepartmentsByCreatedDate: { items } } = data
        return (
            <div>
                <Row gutter={24}>
                    {/* <Col span={20}>
                        <Form name="control-hooks">
                            <Form.Item name="note">
                                <Input size="large" onChange={handleSearch} placeholder="Search department" prefix={<SearchOutlined />} />
                            </Form.Item>
                        </Form>
                    </Col> */}
                    <Col span={4} style={{ margin: 5, marginBottom: 15 }}>
                        <CreateDepartmentDrawer />
                    </Col>
                </Row>
                <Table
                    size="small"
                    scroll={{ y: 900 }}
                    columns={columns}
                    dataSource={items}
                />
            </div>
        )
    }
}

export default DepartmentDatatable