import { Layout, Tabs, Row, Col } from 'antd';
import logo from "../../assets/images/batigc-logo.png"
import "./LoginLayout.less"

// internal-compoents
import LoginForm from "./components/LoginForm/LoginForm"
import RegisterForm from './components/RegisterForm/RegisterForm';

const { TabPane } = Tabs;

const LoginLayout = () => {
    return (
        <Layout className="login-wrapper">
            <Row>
                <Col span={12}>
                    <img src={logo} className="login-logo" />
                </Col>
                <Col span={12} style={{ marginTop: "8%" }}>
                    <Tabs defaultActiveKey="1" size="large">
                        <TabPane tab="Login" key="1">
                            <LoginForm />
                        </TabPane>
                        <TabPane tab="Register" key="2">
                            <RegisterForm />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </Layout>
    )
}

export default LoginLayout