import { v4 as uuidv4 } from 'uuid';
export const roleValues = {
    roleName: "Super Admin",
    roleRefs: "super-admin",
    typeCreated: "RoleCreated",
    key: uuidv4(),

    // project
    projectPrivilege: true,
    readAllProject: true,
    readOwnProject: true,
    createProject: true,
    updateProject: true,
    deleteProject: true,

    // element
    elementPrivilege: true,
    readAllElement: true,
    readOwnElement: true,
    createElement: true,
    updateElement: true,
    deleteElement: true,

    // subElement
    subElementPrivilege: true,
    readAllSubElement: true,
    readOwnSubElement: true,
    createSubElement: true,
    updateSubElement: true,
    deleteSubElement: true,

    // department
    departmentPrivilege: true,
    readDepartment: true,
    createDepartment: true,
    updateDepartment: true,
    deleteDepartment: true,

    // position
    positionPrivilege: true,
    readPosition: true,
    createPosition: true,
    updatePosition: true,
    deletePosition: true,

    // planning
    planningPrivilege: true,
    readAllPlanning: true,
    readOwnPlanning: true,
    createPlanning: true,
    updatePlanning: true,
    deletePlanning: true,

    // timetable
    timetablePrivilege: true,
    readAllTimetable: true,
    readOwnTimetable: true,
    createTimetable: true,
    updateTimetable: true,
    deleteTimetable: true,

    // user
    userPrivilege: true,
    readUser: true,
    createUser: true,
    updateUser: true,
    deleteUser: true,

    // role
    rolePrivilege: true,
    readRole: true,
    createRole: true,
    updateRole: true,
    deleteRole: true,

    // setting
    settingPrivilege: true,
    technicalPrivilege: true,
    managementPrivilege: true,

    // elementCategory
    elementCategoryPrivilege: true,
    readElementCategory: true,
    createElementCategory: true,
    updateElementCategory: true,
    deleteElementCategory: true,

    // manage-timetable
    manageTimetablePrivilege: true,
    readManageTimetable: true,
    createManageTimetable: true,
    updateManageTimetable: true,
    deleteManageTimetable: true,
}