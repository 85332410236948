import { useState, forwardRef, useImperativeHandle } from "react"
import { Row, Col, Typography, Form, Divider, Checkbox, Card } from "antd"
import "../CreateRoleDrawer.less"

const { Title } = Typography
const CheckboxGroup = Checkbox.Group;

// department-options
const departmentOptions = [
    {
        label: 'Read',
        value: 'readDepartment'
    },
    {
        label: 'Create',
        value: 'createDepartment'
    },
    {
        label: 'Update',
        value: 'updateDepartment'
    },
    {
        label: 'Delete',
        value: 'deleteDepartment'
    }
];

const CheckDepartmentGroup = forwardRef((props, ref) => {
    const [checkedDepartmentGroup, setCheckedDepartmentGroup] = useState(null);
    const {
        form,
        disabled,
        setIndeterminate,
        setCheckAll,
        checkedDepartment,
        setCheckedDepartment
    } = props

    useImperativeHandle(ref, () => ({
        onChangeDepartment(checked) {
            setCheckedDepartmentGroup(checked ? departmentOptions.map(v => v.value) : []);
            setCheckedDepartment(checked)
            form.setFieldsValue({
                departmentGroup: checked ? departmentOptions.map(v => v.value) : []
            })
        }
    }))

    const onChangeDepartment = (e) => {
        const checked = e.target.checked
        setCheckedDepartmentGroup(checked ? departmentOptions.map(v => v.value) : []);
        setCheckedDepartment(checked)
        setIndeterminate(false)
        form.setFieldsValue({
            departmentPrivilege: checked,
            departmentGroup: checked ? departmentOptions.map(v => v.value) : []
        })
    }
    const onChangeDepartmentGroup = (list) => {
        setIndeterminate(!!list.length && list.length < departmentOptions.length);
        setCheckAll(list.length === departmentOptions.length);
    };

    return (
        <Col span={6}>
            <Card bordered={false} className="small-card-container">
                <Form.Item name="departmentPrivilege" valuePropName="checked" style={{ marginBottom: -10, marginTop: 0 }}>
                    <Checkbox
                        onChange={onChangeDepartment}
                        checked={checkedDepartment}
                        disabled={disabled ? false : true}
                    >
                        <span className={disabled ? 'child-checkbox-label' : 'child-checkbox-label-disabled'}>
                            Department
                        </span>
                    </Checkbox>
                </Form.Item>
                <Divider />
                <Form.Item name="departmentGroup" style={{ marginBottom: 0, marginTop: -10 }}>
                    <Checkbox.Group>
                        <Row>
                            <Col span={24}>
                                <Checkbox
                                    value="readDepartment"
                                    disabled={checkedDepartment ? false : true}
                                    onChange={onChangeDepartmentGroup}
                                >
                                    Read
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="createDepartment"
                                    disabled={checkedDepartment ? false : true}
                                    onChange={onChangeDepartmentGroup}
                                >
                                    Create
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="updateDepartment"
                                    disabled={checkedDepartment ? false : true}
                                    onChange={onChangeDepartmentGroup}
                                >
                                    Update
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="deleteDepartment"
                                    disabled={checkedDepartment ? false : true}
                                    onChange={onChangeDepartmentGroup}
                                >
                                    Delete
                                </Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                    {/* <CheckboxGroup
                        disabled={checkedDepartment ? false : true}
                        options={departmentOptions}
                        value={checkedDepartmentGroup}
                        onChange={onChangeDepartmentGroup}
                    /> */}
                </Form.Item>
            </Card>
        </Col>
    )
})

export default CheckDepartmentGroup