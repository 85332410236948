import { useState, useEffect } from "react"
import { Form, Input, Button, Row, Col, Alert } from "antd"
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom"

// external-component
import LottieWaiting from "../../../../components/LottieWaiting/LottieWaiting";
import VerifyEmailModal from "../RegisterForm/component/VerifyEmailModal";

// aws-amplify
import { API, Auth, graphqlOperation, Hub } from "aws-amplify"

// graphql-query
import { listUsers, listRoles } from "../../../../graphql/queries"

// shared-method
import { checkUser } from "../../../../shared/methods/CheckUserAuth"

const cookies = new Cookies();

const LoginForm = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const [checkAuthorized, setCheckAuthorized] = useState(false)
    const [checkUserNotFound, setCheckUserNotFound] = useState(false)
    const userEmail = cookies.get('userEmail')
    const userPassword = cookies.get('userPassword')
    const navigate = useNavigate();

    const [form] = Form.useForm()

    useEffect(() => {
        if (userEmail && userPassword) {
            form.setFieldsValue({
                email: userEmail,
                password: userPassword
            })
        }
    }, [userEmail, userPassword])

    useEffect(() => {
        checkUser()
        setAuthListener()
    }, [])

    const handleVisibleModal = (visible) => {
        setVisibleModal(visible)
    }

    const setAuthListener = async () => {
        const listener = (data) => {
            switch (data.payload.event) {
                case 'signIn':
                    // setIsLoading(false)
                    // setCheckAuthorized(false)
                    // setFormState(() => ({ ...initState, formType: "signedIn" }))
                    cookies.remove('userPassword', { path: '/' })
                    // setIsLoading(false)
                    break;
                default:
                    break;
            }
        }
        Hub.listen('auth', listener);
    }

    const onFinish = async (values) => {
        const { email, password } = values
        try {
            setIsLoading(true)
            const checkSignin = await Auth.signIn({ username: email, password })
            if (checkSignin) {
                const user = await API.graphql(graphqlOperation(listUsers, { filter: { email: { eq: email } } }))
                const { data: { listUsers: { items } } } = user
                const userId = items[0].id
                const roleId = items[0].roleUserId
                cookies.set('userId', userId, { path: '/' });
                cookies.set('userEmail', email, { path: '/' });
                cookies.set('isAuthenticated', true, { path: '/' });
                navigate(`/`);
                checkUser()

                // check exist roleId
                if (roleId) {
                    const roleData = await API.graphql(graphqlOperation(listRoles, {
                        filter: {
                            id: { eq: roleId }
                        }
                    }))
                    const { data: { listRoles: { items: roleItems } } } = roleData
                    cookies.set('roleId', roleItems[0].id, { path: '/' });
                }
                setIsLoading(false)
            }
        } catch (err) {
            console.log(err)
            const { code } = err
            if (code === "NotAuthorizedException") {
                setIsLoading(false)
                setCheckAuthorized(true)
                setCheckUserNotFound(false)
            }
            if (code === "UserNotFoundException") {
                setIsLoading(false)
                setCheckAuthorized(false)
                setCheckUserNotFound(true)
            }
            if (code === "UserNotConfirmedException") {
                setIsLoading(false)
                setVisibleModal(true)
                const username = values.email
                cookies.set('userEmail', username, { path: '/' });
                Auth.resendSignUp(username)
            }
        }
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
    };

    return (
        <Row gutter={24}>
            <Col span={9}>
                {
                    isLoading ?
                        <LottieWaiting /> :
                        <Form
                            form={form}
                            name="nest-messages"
                            layout="vertical"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                        >
                            <Form.Item name={'email'} label="Email" rules={[{ type: 'email' }]}>
                                <Input
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item name={'password'} label="Password" rules={[{ required: true }]}>
                                <Input.Password
                                    size="large"
                                />
                            </Form.Item>
                            {
                                checkAuthorized &&
                                <Alert
                                    description="Incorrect email or password."
                                    type="error"
                                    showIcon
                                    style={{ marginBottom: 20 }}
                                />
                            }
                            {
                                checkUserNotFound &&
                                <Alert
                                    description="User does not exist. Please register an account."
                                    type="error"
                                    showIcon
                                    style={{ marginBottom: 20 }}
                                />
                            }
                            <Form.Item>
                                <Button block size="large" type="primary" htmlType="submit">
                                    Login
                                </Button>
                            </Form.Item>

                            <VerifyEmailModal
                                visibleModal={visibleModal}
                                handleVisibleModal={handleVisibleModal}
                            />
                        </Form>
                }
            </Col>
        </Row>
    )
}

export default LoginForm