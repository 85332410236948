import { useState, useEffect } from "react"
import { Layout } from "antd"
import "./ProjectListContainer.less"
import Cookies from 'universal-cookie';

// external-component
import LottieLoading from "../../components/LottieLoading/LottieLoading"

// internal-components
import CreateProjectDrawer from "./components/Drawers/CreateProjectDrawer"
import SearchProject from "./components/SearchProject"
import ButtonCollapse from "./components/ButtonCollapse"
import AllProjectList from "./components/AllProjectList";
import ProjectListByProjectManager from "./components/ProjectListByProjectManager";

// apollo-client
import { useQuery } from "@apollo/client"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listRoles } from "../../graphql/queries"

// graphql-sub
import {
    onUpdateRole,
} from "../../graphql/subscriptions"

const { Sider } = Layout;
const cookies = new Cookies();

const ProjectListContainer = () => {
    const [roles, setRoles] = useState(null)
    const [role, setRole] = useState(null)
    const [collapsed, setCollapsed] = useState(false)
    const [searchParam, setSearchParam] = useState(null)

    // cookies
    const userId = cookies.get('userId')
    const roleId = cookies.get('roleId')

    let subscriptionOnUpdateRole;

    useEffect(() => {
        queryRole()
    }, [role, roleId])

    // execute subscription
    useEffect(() => {
        subOnUpdateRole()

        return () => {
            subscriptionOnUpdateRole.unsubscribe()
        }
    }, [])

    const queryRole = async () => {
        try {
            if (roleId) {
                const roleData = await API.graphql(graphqlOperation(listRoles, {
                    typeCreated: "RoleCreated",
                    filter: {
                        id: { eq: roleId }
                    }
                }))
                const { data: { listRoles: { items } } } = roleData
                setRoles(items[0])
            }
        } catch (err) {
            console.log(err)
        }
    }

    // sub-update-role
    const subOnUpdateRole = async () => {
        try {
            subscriptionOnUpdateRole = await API.graphql(graphqlOperation(onUpdateRole))
                .subscribe({
                    next: ({ value }) => {
                        setRole(value.data.onUpdateRole)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // handle search 
    const handleSearch = (e) => {
        setSearchParam(e.target.value)
    }

    const onCollapse = () => {
        setCollapsed(prevCollapsed => !prevCollapsed);
    };

    // query role
    const { subscribeToMore, loading, error, data } = useQuery(listRoles, {
        variables: {
            filter: {
                id: { eq: roleId }
            }
        }
    })

    if (loading) {
        return (
            <Sider
                className="project-list-wrapper"
                trigger={null} collapsible collapsed={collapsed} width={350}
                style={{ height: "90vh", backgroundColor: "#fff", padding: 16 }}
            >
                <LottieLoading />
            </Sider>
        )
    }
    if (error) console.log(error)
    if (data) {
        const { listRoles: { items } } = data
        const roleItem = items[0]
        const readAllProject = roleItem.readAllProject ? roleItem.readAllProject : false
        const readOwnProject = roleItem.readOwnProject ? roleItem.readOwnProject : false
        if (readAllProject === true) {
            return (
                <Sider
                    className="project-list-wrapper"
                    trigger={null} collapsible collapsed={collapsed} width={350}
                    style={{ height: "90vh", backgroundColor: "#fff", padding: 16, paddingTop: 0 }}
                >
                    <div className="project-list-header">
                        <ButtonCollapse onCollapse={onCollapse} collapsed={collapsed} />
                        <CreateProjectDrawer role={items} collapsed={collapsed} />
                        <SearchProject handleSearch={handleSearch} collapsed={collapsed} />
                    </div>
                    <AllProjectList collapsed={collapsed} searchParam={searchParam} />
                </Sider>
            )
        }
        if (readOwnProject === true && userId) {
            return (
                <Sider
                    className="project-list-wrapper"
                    trigger={null} collapsible collapsed={collapsed} width={300}
                    style={{ height: "90vh", backgroundColor: "#fff", padding: 16 }}
                >
                    <ButtonCollapse onCollapse={onCollapse} collapsed={collapsed} />
                    <CreateProjectDrawer role={items} collapsed={collapsed} />
                    <SearchProject handleSearch={handleSearch} collapsed={collapsed} />
                    <ProjectListByProjectManager searchParam={searchParam} />
                </Sider>
            )
        }
    }
}

export default ProjectListContainer