import { Spin } from "antd"
import "./Spin.less"
const MySpin = () => {
    return (
        <div className="spin-wrapper">
            <Spin size="large" />
        </div>
    )
}

export default MySpin