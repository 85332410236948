import { useEffect } from "react"
import { Table, Space, Row, Col } from 'antd';
import moment from "moment";

// external-component
import LottieLoading from "../../../components/LottieLoading/LottieLoading";

// drawer
import CreateTimeDrawer from './CreateTimeDrawer';
import UpdateTimeDrawer from "./UpdateTimeDrawer";

// apollo-client
import { useQuery } from "@apollo/client"

// graphql-query
import { listTimesByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import { onCreateTime, onUpdateTime, onDeleteTime } from "../../../graphql/subscriptions"

const columns = [
    {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        width: '90%',
        render: (text, record) => (
            record.time ?
                moment(record.time).format('HH:mm') : ""
        ),
    },
    {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record) => (
            <Space size="middle">
                {/* <DeleteRecordDrawer
                    id={record.id}
                    module="time"
                    title="Delete the Time?"
                    message={`Time: ${record.timeName}`}
                /> */}
                <UpdateTimeDrawer data={record} />
            </Space>
        ),
    },
];

const TimeDatatable = () => {
    // query time
    const { subscribeToMore, loading, error, data } = useQuery(listTimesByCreatedDate, {
        variables: {
            typeCreated: "TimeCreated",
            sortDirection: "DESC"
        }
    })

    // on create time
    useEffect(() =>
        subscribeToMore({
            document: onCreateTime,
            updateQuery: (prev, { subscriptionData }) => {
                const { listTimesByCreatedDate: { items } } = prev
                if (!subscriptionData.data) return prev
                const { onCreateTime: timeData } = subscriptionData.data
                return {
                    listTimesByCreatedDate: {
                        items: [timeData, ...items],
                        nextToken: null,
                        __typename: "Time"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on update time
    useEffect(() =>
        subscribeToMore({
            document: onUpdateTime,
            updateQuery: (prev) => {
                const { listTimesByCreatedDate: { items } } = prev
                return {
                    listTimesByCreatedDate: {
                        items: [...items],
                        nextToken: null,
                        __typename: "Time"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on delete time
    useEffect(() =>
        subscribeToMore({
            document: onDeleteTime,
            updateQuery: (prev, { subscriptionData }) => {
                const { listTimesByCreatedDate: { items } } = prev
                if (subscriptionData) {
                    const { onDeleteTime: timeData } = subscriptionData.data
                    const lastItems = items.filter(doc => doc.id !== timeData.id)
                    return {
                        listTimesByCreatedDate: {
                            items: [...lastItems],
                            nextToken: null,
                            __typename: "Time"
                        }
                    }
                }
            }
        }), [subscribeToMore]
    )

    if (loading) return <LottieLoading />
    if (error) console.log(error)
    if (data) {
        const { listTimesByCreatedDate: { items } } = data
        return (
            <div>
                <Row gutter={24}>
                    {/* <Col span={20}>
                        <Form name="control-hooks">
                            <Form.Item name="note">
                                <Input size="large" onChange={handleSearch} placeholder="Search time" prefix={<SearchOutlined />} />
                            </Form.Item>
                        </Form>
                    </Col> */}
                    <Col span={4} style={{ margin: 5, marginBottom: 15 }}>
                        <CreateTimeDrawer />
                    </Col>
                </Row>
                <Table
                    size="small"
                    scroll={{ y: 900 }}
                    columns={columns}
                    dataSource={items}
                />
            </div>
        )
    }
}

export default TimeDatatable