import { useState, useEffect } from "react"
import { Avatar, Typography } from "antd"
import { useParams } from "react-router-dom"

// aws-amplify
import { API, graphqlOperation,Storage } from "aws-amplify"

// graphql-query
import { getProject } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateProject,
    onUpdateProject,
    onDeleteProject
} from "../../../graphql/subscriptions"

const { Text } = Typography

const ProjectManagerAvatar = () => {
    const [project, setProject] = useState(null)
    const [subProject, setSubProject] = useState(null)
    const [viewAvatar, setViewAvatar] = useState(null)
    const { id: projectId } = useParams()

    // subscribe project
    let subscriptionProjectOnCreate;
    let subscriptionProjectOnUpdate;

    // execute query project
    useEffect(() => {
        queryProjectById()
    }, [subProject, projectId])

    useEffect(() => {
        viewImage()
    }, [project])

    // execute sub project
    useEffect(() => {
        subOnCreateProject()
        subOnUpdateProject()
        return () => {
            subscriptionProjectOnCreate.unsubscribe()
            subscriptionProjectOnUpdate.unsubscribe()
        }
    }, [])

    // query project by id
    const queryProjectById = async () => {
        try {
            if (projectId) {
                const projectData = await API.graphql(graphqlOperation(getProject, { id: projectId }))
                setProject(projectData.data.getProject)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // sub create project
    const subOnCreateProject = async () => {
        try {
            subscriptionProjectOnCreate = await API.graphql(graphqlOperation(onCreateProject))
                .subscribe({
                    next: ({ value }) => {
                        setSubProject(value.data.onCreateProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update project
    const subOnUpdateProject = async () => {
        try {
            subscriptionProjectOnUpdate = await API.graphql(graphqlOperation(onUpdateProject))
                .subscribe({
                    next: ({ value }) => {
                        setSubProject(value.data.onUpdateProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    const viewImage = async () => {
        if (project) {
            const { projectManager: { avatar } } = project
            const rs = await Storage.get(`avatars/${avatar}`)
            setViewAvatar(rs)
        }
    }

    return (
        <>
            <Text level={5}>Project Manager</Text><br></br>
            {
                project &&
                <Avatar style={{ marginTop: 10 }} src={viewAvatar} size="large"/>
            }
        </>
    )
}

export default ProjectManagerAvatar