import { useState, useEffect } from "react"
import { Button, Drawer, Row, Col, Select, Form, Input, Divider } from "antd"
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons"
import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom"
import Cookies from 'universal-cookie';

// external-component
import Notification from "../../../components/Notification/Notification"
import Spin from "../../../components/Spin/Spin"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listElementCategorysByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateElementCategory,
    onUpdateElementCategory,
    onDeleteElementCategory
} from "../../../graphql/subscriptions"

// mutation-create
import { createElement } from "../../../graphql/mutations"

const cookies = new Cookies();

const CreateElementDrawer = () => {
    const [visible, setVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [elementCategories, setElementCategories] = useState(null)
    const [elementCategory, setElementCategory] = useState(null)
    const { id: projectId } = useParams()

    // cookies
    const userId = cookies.get('userId')

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    // execute query 
    useEffect(() => {
        queryElementCategories()
    }, [elementCategory])

    // execute subscription
    useEffect(() => {
        subOnCreateElementCategory()
        subOnUpdateElementCategory()
        subOnDeleteElementCategory()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query elementCategories
    const queryElementCategories = async () => {
        try {
            const elementCategoryData = await API.graphql(graphqlOperation(listElementCategorysByCreatedDate, {
                typeCreated: "ElementCategoryCreated",
                sortDirection: "DESC",
            }))
            const { data: { listElementCategorysByCreatedDate: { items } } } = elementCategoryData
            setElementCategories(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create 
    const subOnCreateElementCategory = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateElementCategory))
                .subscribe({
                    next: ({ value }) => {
                        setElementCategory(value.data.onCreateElementCategory)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update 
    const subOnUpdateElementCategory = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateElementCategory))
                .subscribe({
                    next: ({ value }) => {
                        setElementCategory(value.data.onUpdateElementCategory)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete 
    const subOnDeleteElementCategory = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteElementCategory))
                .subscribe({
                    next: ({ value }) => {
                        setElementCategory(value.data.onDeleteElementCategory)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    const [form] = Form.useForm()

    const onFinish = async (values) => {
        try {
            values.key = uuidv4()
            values.projectElementId = projectId
            values.typeCreated = "ElementCreated"
            values.userElementId = userId ? userId : null
            setIsLoading(true)
            await API.graphql(graphqlOperation(createElement, { input: values }))
            Notification("success", "Success", "Element create successfully!")
            setIsLoading(false)
            form.resetFields()
        } catch (error) {
            console.log(error)
        }
    };

    const validateMessages = {
        required: '${label} is required!'
    };

    const onClose = () => {
        setVisible(false);
    };
    return (
        <div>
            <Button
                type="primary"
                size="large"
                onClick={() => setVisible(true)}
                style={{ float: "right", marginTop: -40, marginRight: -70 }}
            >
                Create Element
            </Button>
            <Drawer
                title={`Create Element`}
                onClose={onClose}
                visible={visible}
                width="500"
            >
                {
                    isLoading ? <Spin /> :
                        <Form form={form} name="nest-messages" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item name="elementCategoryElementId" label="Category" rules={[{ required: true }]}>
                                        <Select
                                            size="large"
                                        >
                                            {
                                                elementCategories && elementCategories.map((category, key) => {
                                                    const { id, categoryName } = category
                                                    return <Select.Option key={key} value={id}>{categoryName}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="elementName" label="Element Name" rules={[{ required: true }]}>
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={9} offset={6}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} size="large" block type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={9}>
                                        <Button icon={<SaveOutlined />} size="large" block type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </div>
    )
}

export default CreateElementDrawer