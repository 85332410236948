import { useState, useEffect } from "react"
import { Form, Select } from "antd"
import { useParams, useNavigate } from "react-router-dom"

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listElementCategorysByCreatedDate } from "../../graphql/queries"

// graphql-sub
import {
    onCreateElementCategory,
    onUpdateElementCategory,
    onDeleteElementCategory
} from "../../graphql/subscriptions"

const SelectElementCategory = ({ path }) => {
    const [elementCategorys, setElementCategorys] = useState([])
    const [elementCategory, setElementCategory] = useState(null)

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    // router
    const navigate = useNavigate()
    const { projectId, elementCategoryId, elementId, userId } = useParams()

    // execute query 
    useEffect(() => {
        queryElementCategorys()
    }, [elementCategory])

    // execute subscription
    useEffect(() => {
        subOnCreateElementCategory()
        subOnUpdateElementCategory()
        subOnDeleteElementCategory()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query elementCategorys
    const queryElementCategorys = async () => {
        try {
            const elementCategoryData = await API.graphql(graphqlOperation(listElementCategorysByCreatedDate, {
                typeCreated: "ElementCategoryCreated",
                sortDirection: "DESC"
            }))
            const { data: { listElementCategorysByCreatedDate: { items } } } = elementCategoryData
            setElementCategorys(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create elementCategory
    const subOnCreateElementCategory = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateElementCategory))
                .subscribe({
                    next: ({ value }) => {
                        setElementCategory(value.data.onCreateElementCategory)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update elementCategory
    const subOnUpdateElementCategory = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateElementCategory))
                .subscribe({
                    next: ({ value }) => {
                        setElementCategory(value.data.onUpdateElementCategory)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete elementCategory
    const subOnDeleteElementCategory = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteElementCategory))
                .subscribe({
                    next: ({ value }) => {
                        setElementCategory(value.data.onDeleteElementCategory)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Form layout="vertical">
            <Form.Item label="ElementCategory">
                <Select
                    showSearch
                    allowClear
                    size="large"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(value) => navigate(`/${path}/${projectId ? projectId : "0"}/${value}/${elementId ? elementId : "0"}/${userId ? userId : "0"}`)}
                    onClear={() => navigate(`/${path}/${projectId ? projectId : "0"}/0/${elementId ? elementId : "0"}/${userId ? userId : "0"}`)}
                    defaultValue={elementCategoryId !== "0" ? elementCategoryId : null}
                    style={{ width: "100%" }}
                >
                    {
                        elementCategorys && elementCategorys.map((item, key) => {
                            const { id, categoryName } = item
                            return <Select.Option key={key} value={id}>{categoryName}</Select.Option>
                        })
                    }
                </Select>
            </Form.Item>
        </Form>
    )
}

export default SelectElementCategory