import { useState, useEffect, createRef } from "react"
import { Form, Select, Row, Col, Button, Drawer, Divider, DatePicker, Card, Typography } from "antd"
import { API, graphqlOperation } from "aws-amplify"
import { SaveOutlined, CloseCircleOutlined, EditOutlined } from "@ant-design/icons"
import moment from "moment"
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';

// external-component
import MySpin from "../../../components/Spin/Spin"
import Notification from "../../../components/Notification/Notification"

// internal-component
import ListTimetableDetail from "./ListTimetableDetail"

// graphql-query
import {
    listTimesByCreatedDate
} from "../../../graphql/queries"

// graphql-sub
import {
    onCreateTime,
    onUpdateTime,
    onDeleteTime
} from "../../../graphql/subscriptions"

// apollo-client
import { useQuery } from "@apollo/client"

// mutation-create
import { updatePlanning, updateTimetable, createTimetableDetail } from "../../../graphql/mutations"

// graphql-query
import { listTimetableDetailsByCreatedDate } from "../../../graphql/queries"

const { Title, Text } = Typography
const cookies = new Cookies();

const UpdateTimetableDrawer = ({ data }) => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [times, setTimes] = useState(null)
    const [time, setTime] = useState(null)

    // cookies
    const userId = cookies.get('userId')

    const dateFormat = 'DD/MM/YYYY'

    const [form] = Form.useForm()
    let formRef = createRef();

    const {
        id,
        project,
        element,
        subElement,
        timeTimetableId,
        planningTimetableId
    } = data

    // sub-time
    let subscriptionTimeOnCreate;
    let subscriptionTimeOnDelete;
    let subscriptionTimeOnUpdate;

    useEffect(() => {
        form.setFieldsValue({
            timeTimetableId: timeTimetableId
        })
    }, [])

    useEffect(() => {
        queryTimes()
    }, [time])

    // execute subscription time
    useEffect(() => {
        subOnCreateTime()
        subOnUpdateTime()
        subOnDeleteTime()
        return () => {
            subscriptionTimeOnCreate.unsubscribe()
            subscriptionTimeOnUpdate.unsubscribe()
            subscriptionTimeOnDelete.unsubscribe()
        }
    }, [])

    // query times
    const queryTimes = async () => {
        try {
            const timeData = await API.graphql(graphqlOperation(listTimesByCreatedDate, {
                typeCreated: "TimeCreated",
                sortDirection: "DESC",
            }))
            const { data: { listTimesByCreatedDate: { items } } } = timeData
            setTimes(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create time
    const subOnCreateTime = async () => {
        try {
            subscriptionTimeOnCreate = await API.graphql(graphqlOperation(onCreateTime))
                .subscribe({
                    next: ({ value }) => {
                        setTime(value.data.onCreateTime)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update time
    const subOnUpdateTime = async () => {
        try {
            subscriptionTimeOnUpdate = await API.graphql(graphqlOperation(onUpdateTime))
                .subscribe({
                    next: ({ value }) => {
                        setTime(value.data.onUpdateTime)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete time
    const subOnDeleteTime = async () => {
        try {
            subscriptionTimeOnDelete = await API.graphql(graphqlOperation(onDeleteTime))
                .subscribe({
                    next: ({ value }) => {
                        setTime(value.data.onDeleteTime)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    const onClose = () => {
        setVisible(false);
    };

    const onFinish = async (values) => {
        try {
            values.timetableTimetableDetailId = id
            values.key = uuidv4()
            values.typeCreated = "TimetableDetailCreated"
            setIsLoading(true)
            await API.graphql(graphqlOperation(createTimetableDetail, { input: values }))
            Notification("success", "Success", "Timetable Create successfully!")
            setIsLoading(false)
            form.resetFields()
        } catch (error) {
            console.log(error)
        }
    };

    const validateMessages = {
        required: '${label} is required!'
    };

    return (
        <div>
            <Button
                type="primary"
                icon={<EditOutlined />}
                style={{ float: "right" }}
                onClick={() => setVisible(true)}
            />
            <Drawer
                title="Create Timetable"
                onClose={onClose}
                visible={visible}
                width="900"
                footer={
                    <Row gutter={24}>
                        <Col span={6} offset={12}>
                            <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="default">
                                Cancel
                            </Button>
                        </Col>
                        <Col span={6}>
                            <Button
                                icon={<SaveOutlined />}
                                block
                                size="large"
                                type="primary"
                                htmlType="submit"
                                form="formCreateTimetableDetail"
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                }
            >
                {
                    isLoading ? <MySpin /> :
                        <Form
                            id="formCreateTimetableDetail"
                            form={form}
                            ref={formRef}
                            name="nest-messages"
                            layout="vertical"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Card style={{ marginBottom: 40 }}>
                                        <Title level={5}>Project</Title>
                                        <Text className="card-label">
                                            {
                                                project && project.projectNumber
                                            }
                                        </Text>
                                        <Divider />
                                        <Title level={5}>Element</Title>
                                        <Text className="card-label">
                                            {
                                                element && element.elementName
                                            }
                                        </Text>
                                        <Divider />
                                        <Title level={5}>Number of Drawring</Title>
                                        <Text className="card-label">
                                            {
                                                subElement && subElement.numberOfDrawing
                                            }
                                        </Text>
                                    </Card>
                                    <Form.Item name="date" label="Date" rules={[{ required: true }]}>
                                        <DatePicker
                                            size="large"
                                            style={{ width: "100%" }}
                                            format={dateFormat}
                                            disabledDate={(current) => {
                                                return moment().add('days') <= current ||
                                                    moment().add(1, 'month') < current;
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item name="timeTimetableDetailId" label="Time" rules={[{ required: true }]}>
                                        <Select
                                            showSearch
                                            size="large"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                times && times.map((item, key) => {
                                                    const { id, time } = item
                                                    return <Select.Option key={key} value={id}>{moment(time).format("HH:mm")}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <ListTimetableDetail id={id} />
                                </Col>
                            </Row>
                            <Divider />
                        </Form>
                }
            </Drawer>
        </div >
    )
}

export default UpdateTimetableDrawer