import { Timeline, Col } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const SettingRole = ({ item }) => {
    const { settingPrivilege } = item

    return (
        <Col span={24}>
            <Timeline>
                <Timeline.Item
                    color={settingPrivilege ? "green" : "red"}
                    dot={settingPrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                    style={{ marginBottom: -30 }}
                >
                    <span className="role-parent">Setting</span>
                </Timeline.Item>
            </Timeline>
        </Col>
    )
}

export default SettingRole