import { useEffect } from "react"
import { List } from "antd"
import { useQuery } from "@apollo/client"
import moment from "moment"

// external-component
import LottieLoading from "../../../components/LottieLoading/LottieLoading"

// graphql-query
import { listTimetableDetailsByCreatedDate } from "../../../graphql/queries"

// query-sub
import {
    onCreateTimetableDetail,
    onUpdateTimetableDetail,
    onDeleteTimetableDetail
} from "../../../graphql/subscriptions"

const ListTimetableDetail = ({ id }) => {

    // query timetableDetail
    const { subscribeToMore, loading, error, data } = useQuery(listTimetableDetailsByCreatedDate, {
        variables: {
            typeCreated: "TimetableDetailCreated",
            sortDirection: "DESC",
            // limit: 500,
            filter: {
                timetableTimetableDetailId: { eq: id }
            }
        }
    })

    // on create timetable
    useEffect(() =>
        subscribeToMore({
            document: onCreateTimetableDetail,
            updateQuery: (prev, { subscriptionData }) => {
                const { listTimetableDetailsByCreatedDate: { items } } = prev
                if (!subscriptionData.data) return prev
                const { onCreateTimetableDetail: timetableDetailData } = subscriptionData.data
                console.log(timetableDetailData)
                return {
                    listTimetableDetailsByCreatedDate: {
                        items: [timetableDetailData, ...items],
                        nextToken: null,
                        __typename: "TimetableDetail"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on update timetable
    useEffect(() =>
        subscribeToMore({
            document: onUpdateTimetableDetail,
            updateQuery: (prev) => {
                const { listTimetableDetailsByCreatedDate: { items } } = prev
                return {
                    listTimetableDetailsByCreatedDate: {
                        items: [...items],
                        nextToken: null,
                        __typename: "TimetableDetail"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on delete timetable
    useEffect(() =>
        subscribeToMore({
            document: onDeleteTimetableDetail,
            updateQuery: (prev, { subscriptionData }) => {
                const { listTimetablesByCreatedDate: { items } } = prev
                if (subscriptionData) {
                    const { onDeleteTimetableDetail: timetableData } = subscriptionData.data
                    const lastItems = items.filter(doc => doc.id !== timetableData.id)
                    return {
                        listTimetableDetailsByCreatedDate: {
                            items: [...lastItems],
                            nextToken: null,
                            __typename: "TimetableDetail"
                        }
                    }
                }
            }
        }), [subscribeToMore]
    )

    if (loading) return <LottieLoading />
    if (error) console.log(error)
    if (data) {
        const { listTimetableDetailsByCreatedDate: { items } } = data
        return (
            <List
                itemLayout="horizontal"
                dataSource={items}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            title={item.date ? moment(item.date).format('DD.MM.YYYY') : ""}
                            description={item.time ? moment(item.time.time).format('HH:mm') : ""}
                        />
                    </List.Item>
                )}
            />
        )
    }
}

export default ListTimetableDetail