import { useState, createRef, useRef } from "react"
import { Button, Drawer, Row, Col, Typography, Form, Select, Input, Divider } from "antd"
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons"
import { v4 as uuidv4 } from 'uuid';

// external-component
import Notification from "../../../components/Notification/Notification"
import Spin from "../../../components/Spin/Spin"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// mutation-create
import { createDepartment } from "../../../graphql/mutations"

const CreateDepartmentDrawer = () => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()
    let formRef = createRef()
    const inputRef = useRef(null);
    const sharedProps = {
        ref: inputRef,
    };

    const validateMessages = {
        required: '${label} is required!'
    };

    const onFinish = async (values) => {
        try {
            values.typeCreated = "DepartmentCreated"
            values.key = uuidv4()
            values.departmentRefs = values.departmentName.toLowerCase().replace(/ /g, '-')
            setIsLoading(true)
            await API.graphql(graphqlOperation(createDepartment, { input: values }))
            Notification("success", "Success", "Department create successfully!")
            setIsLoading(false)
            form.resetFields()
            inputRef.current.focus({
                cursor: "start"
            })
        } catch (error) {
            console.log(error)
        }
    };

    const onClose = () => {
        setVisible(false);
    };

    return (
        <div>
            <Button
                type="primary"
                size="large"
                onClick={() => setVisible(true)}
                // style={{ float: "right" }}
            >
                Create Department
            </Button>
            <Drawer
                title="Create Department"
                onClose={onClose}
                visible={visible}
                width="500"
            >
                {
                    isLoading ? <Spin /> :
                        <Form form={form} ref={formRef} name="nest-messages" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <Form.Item name="departmentName" label="Department Name" rules={[{ required: true }]}>
                                <Input size="large" {...sharedProps} />
                            </Form.Item>
                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button icon={<SaveOutlined />} block size="large" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </div>
    )
}

export default CreateDepartmentDrawer