import { useState, createRef } from "react"
import { Button, Tooltip, Drawer, Row, Col, Typography, Form, Select, Divider } from "antd"
import { CloseCircleOutlined, SaveOutlined, DeleteOutlined, WarningOutlined } from "@ant-design/icons"
import { useNavigate, useParams } from "react-router-dom"
import "./DeleteRecordDrawer.less"

// external-component
import Notification from "../Notification/Notification"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import {
    listUsersByCreatedDate,
    listPlannings,
    listTimetables
} from "../../graphql/queries"

// mutation-delete
import {
    deleteProject,
    deleteDepartment,
    deleteElement,
    deleteSubElement,
    deleteElementCategory,
    deleteUser,
    deletePlanning,
    deletePosition,
    deleteTimetable,
    deleteRole
} from "../../graphql/mutations"
import MySpin from "../Spin/Spin"

// shared-method
import {
    deletePlanningById,
    deletePlanningBySubElementId
} from "../../shared/methods/DeletePlanning"

import {
    deleteTimetableById,
    deleteTimetableBySubElementId
} from "../../shared/methods/DeleteTimetable"

import {
    deleteSubElementById,
    deleteSubElementByProjectId
} from "../../shared/methods/DeleteSubElement"

const { Text, Title } = Typography

const DeleteRecordDrawer = (props) => {
    const { id, title, module, message, placement, btnSize, btnType, iconSize } = props
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()
    let formRef = createRef()

    const navigate = useNavigate()
    const { id: projectId } = useParams()

    const validateMessages = {
        required: '${label} is required!'
    };

    const onFinish = async (values) => {
        values.id = id
        switch (module) {
            case "department":
                try {
                    const checkDepartmentUsaged = await API.graphql(graphqlOperation(listUsersByCreatedDate, {
                        typeCreated: "UserCreated",
                        filter: {
                            departmentUserId: {
                                eq: id
                            }
                        }
                    }))
                    const { data: { listUsersByCreatedDate: { items: departmentItems } } } = checkDepartmentUsaged
                    if (departmentItems.length >= 1) {
                        Notification("warning", "Warning", "Department already usaged. You can't delete it.")
                    }
                    if (departmentItems.length === 0) {
                        setIsLoading(true)
                        await API.graphql(graphqlOperation(deleteDepartment, { input: { id } }))
                        Notification("success", "Success", "Department delete successfully!")
                        closeDrawer()
                        setIsLoading(false)
                    }
                } catch (err) {
                    console.log(err)
                }
                break;
            case "project":
                try {
                    setIsLoading(true)
                    await API.graphql(graphqlOperation(deleteProject, { input: { id } }))
                    Notification("success", "Success", "Shoring Plan delete successfully!")
                    closeDrawer()
                    setIsLoading(false)
                } catch (error) {
                    console.error(error)
                    Notification("error", "Failed", error)
                }
                break;
            case "element":
                try {
                    setIsLoading(true)
                    await API.graphql(graphqlOperation(deleteElement, { input: { id } }))
                    Notification("success", "Success", "Element delete successfully!")
                    navigate(`/${projectId}`)
                    closeDrawer()
                    setIsLoading(false)
                } catch (error) {
                    console.error(error)
                    Notification("error", "Failed", error)
                }
                break;
            case "subElement":
                try {
                    setIsLoading(true)
                    deletePlanningBySubElementId(id)
                    deleteTimetableBySubElementId(id)
                    deleteSubElementById(id)
                    Notification("success", "Success", "Sub Element delete successfully!")
                    closeDrawer()
                    setIsLoading(false)
                } catch (error) {
                    console.error(error)
                    Notification("error", "Failed", error)
                }
                break;
            case "elementCategory":
                try {
                    setIsLoading(true)
                    await API.graphql(graphqlOperation(deleteElementCategory, { input: { id } }))
                    Notification("success", "Success", "Element Category delete successfully!")
                    closeDrawer()
                    setIsLoading(false)
                } catch (error) {
                    console.error(error)
                    Notification("error", "Failed", error)
                }
                break;
            case "position":
                try {
                    const checkPositionUsaged = await API.graphql(graphqlOperation(listUsersByCreatedDate, {
                        typeCreated: "UserCreated",
                        filter: {
                            positionUserId: {
                                eq: id
                            }
                        }
                    }))

                    const { data: { listUsersByCreatedDate: { items: positionItems } } } = checkPositionUsaged
                    if (positionItems.length >= 1) {
                        Notification("warning", "Warning", "Position already usaged. You can't delete it.")
                    }
                    if (positionItems.length === 0) {
                        setIsLoading(true)
                        await API.graphql(graphqlOperation(deletePosition, { input: { id } }))
                        Notification("success", "Success", "Position delete successfully!")
                        closeDrawer()
                        setIsLoading(false)
                    }
                } catch (error) {
                    console.error(error)
                    Notification("error", "Failed", error)
                }
                break;
            case "user":
                try {
                    setIsLoading(true)
                    await API.graphql(graphqlOperation(deleteUser, { input: { id } }))
                    Notification("success", "Success", "User delete successfully!")
                    closeDrawer()
                    setIsLoading(false)
                } catch (error) {
                    console.error(error)
                    Notification("error", "Failed", error)
                }
                break;
            case "planning":
                try {
                    setIsLoading(true)
                    deletePlanningById(id)
                    // await API.graphql(graphqlOperation(deletePlanning, { input: { id } }))
                    Notification("success", "Success", "Planning delete successfully!")
                    closeDrawer()
                    setIsLoading(false)
                } catch (error) {
                    console.error(error)
                    Notification("error", "Failed", error)
                }
                break;
            case "timetable":
                try {
                    setIsLoading(true)
                    deleteTimetableById(id)
                    // await API.graphql(graphqlOperation(deleteTimetable, { input: { id } }))
                    Notification("success", "Success", "Timetable delete successfully!")
                    closeDrawer()
                    setIsLoading(false)
                } catch (error) {
                    console.error(error)
                    Notification("error", "Failed", error)
                }
                break;
            default: {
                break;
            }
        }

    };

    const closeDrawer = () => {
        setVisible(false);
    };

    return (
        <span>
            <Tooltip placement="bottom" title="Delete">
                <Button
                    // size={"middle"}
                    // type={null}
                    // danger
                    // icon={<DeleteOutlined />}
                    // onClick={() => setVisible(true)}
                    size={btnSize ? btnSize : "middle"}
                    type={btnType ? btnType : null}
                    danger
                    icon={<DeleteOutlined style={{ fontSize: iconSize ? iconSize : null }} />}
                    onClick={() => setVisible(true)}
                />
            </Tooltip>
            <Drawer
                onClose={closeDrawer}
                visible={visible}
                width="500"
                placement={placement ? placement : "right"}
                style={{ position: "absolute" }}
            >
                {
                    isLoading ? <MySpin /> :
                        <Form form={form} ref={formRef} name="nest-messages" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <div className="msg-wrapper">
                                <WarningOutlined style={{ fontSize: 70, marginBottom: 20, color: "#ff787" }} />
                                {/* <WarningOutlined /> */}
                                <Title level={4}>{title}</Title>
                                <Text level={5}>{message}</Text>
                            </div>

                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="primary">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button danger icon={<SaveOutlined />} block size="large" htmlType="submit">
                                            Yes
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </span>
    )
}

export default DeleteRecordDrawer