import { useState, createRef } from "react"
import { Button, Drawer, Row, Col, Form, Card, Divider, Typography } from "antd"
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons"

// internal-components
import SelectDepartment from "./SelectOptions/SelectDepartment"
import SelectPosition from "./SelectOptions/SelectPosition"
import SelectRole from "./SelectOptions/SelectRole"

// external-components
import Notification from "../../../components/Notification/Notification"
import MySpin from "../../../components/Spin/Spin"
import ViewImage from "../../../components/ViewImage/ViewImage"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// mutation-update
import { updateUser } from "../../../graphql/mutations"

// shared-context
import { DepartmentIdProvider } from "../../../shared/context/DepartmentIdContext"

const { Title, Text } = Typography

const UpdateUserDrawer = (props) => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [departmentIdContext, setDepartmentIdContext] = useState(null)
    const [form] = Form.useForm()
    let formRef = createRef()

    const { data } = props || {}
    const {
        id,
        firstName,
        lastName,
        email,
        avatar,
        departmentUserId,
        positionUserId,
        roleUserId
    } = data

    const validateMessages = {
        required: '${label} is required!'
    };

    const onFinish = async (values) => {
        try {
            values.id = id
            setIsLoading(true)
            await API.graphql(graphqlOperation(updateUser, { input: values }))
            Notification("success", "Success", "User update successfully!")
            setIsLoading(false)
            closeDrawer()
        } catch (error) {
            console.log(error)
            Notification("error", "Failed", error.message)
        }
    };

    const closeDrawer = () => {
        setVisible(false);
    };

    return (
        <div>
            <Button
                type="primary"
                icon={<EditOutlined />}
                style={{ float: "right" }}
                onClick={() => setVisible(true)}
            />
            <Drawer
                title="Update User"
                onClose={closeDrawer}
                visible={visible}
                width="800"
            >
                {
                    isLoading ? <MySpin /> :
                        <Form form={form} ref={formRef} name="nest-messages" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <Card style={{ marginBottom: 24 }}>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <ViewImage
                                            fileImage={avatar}
                                            storagePath="avatars"
                                            className="avatar-xl"
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <Title level={5}>First name</Title>
                                        <Text className="user-info-label">{firstName}</Text>
                                        <Title level={5}>Last name</Title>
                                        <Text className="user-info-label">{lastName}</Text>
                                        <Title level={5}>Email</Title>
                                        <Text className="user-info-label">{email}</Text>
                                    </Col>
                                </Row>
                            </Card>
                            <DepartmentIdProvider value={{ departmentIdContext, setDepartmentIdContext }}>
                                <SelectDepartment
                                    form={form}
                                    departmentUserId={departmentUserId}
                                />
                                <SelectPosition
                                    form={form}
                                    positionUserId={positionUserId}
                                    departmentUserId={departmentUserId}
                                />
                            </DepartmentIdProvider>
                            <SelectRole form={form} roleUserId={roleUserId} />
                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={6} offset={12}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={6}>
                                        <Button icon={<EditOutlined />} block size="large" type="primary" htmlType="submit">
                                            Update
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </div>
    )
}

export default UpdateUserDrawer