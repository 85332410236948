import { useState, createRef, useRef, useEffect } from "react"
import { Button, Drawer, Row, Col, Typography, Form, Input, Divider, Checkbox, Card } from "antd"
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons"
import { v4 as uuidv4 } from 'uuid';
import "./CreateRoleDrawer.less"

// external-component
import Notification from "../../../components/Notification/Notification"
import Spin from "../../../components/Spin/Spin"

// internal-component
import CheckProjectGroup from "./CheckGroupCard/CheckProjectGroup"
import CheckElementGroup from "./CheckGroupCard/CheckElementGroup"
import CheckSubElementGroup from "./CheckGroupCard/CheckSubElementGroup"
import CheckPlanningGroup from "./CheckGroupCard/CheckPlanningGroup"
import CheckTimetableGroup from "./CheckGroupCard/CheckTimetableGroup"
import CheckElementCategoryGroup from "./CheckGroupCard/CheckElementCategoryGroup"
import CheckManageTimetableGroup from "./CheckGroupCard/CheckManageTimetableGroup"
import CheckDepartmentGroup from "./CheckGroupCard/CheckDepartmentGroup"
import CheckPositionGroup from "./CheckGroupCard/CheckPositionGroup"
import CheckRoleGroup from "./CheckGroupCard/CheckRoleGroup"
import CheckUserGroup from "./CheckGroupCard/CheckUserGroup"

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// mutation-create"
import { createRole } from "../../../graphql/mutations"

const { Title } = Typography
const CheckboxGroup = Checkbox.Group;

const CheckboxDrawer = () => {
    const [visible, setVisible] = useState(false);
    // const childCompRef = useRef()
    const checkProjectCompRef = useRef()
    const checkElementCompRef = useRef()
    const checkSubElementCompRef = useRef()
    const checkPlanningCompRef = useRef()
    const checkTimetableCompRef = useRef()
    const checkElementCategoryCompRef = useRef()
    const checkManageTimetableCompRef = useRef()
    const checkDepartmentCompRef = useRef()
    const checkPositionCompRef = useRef()
    const checkRoleCompRef = useRef()
    const checkUserCompRef = useRef()

    const [form] = Form.useForm()
    let formRef = createRef()

    const validateMessages = {
        required: '${label} is required!'
    };

    const [isLoading, setIsLoading] = useState(false)
    const [checkedProject, setCheckedProject] = useState(false);
    const [checkedElement, setCheckedElement] = useState(false);
    const [checkedSubElement, setCheckedSubElement] = useState(false);
    const [checkedPlanning, setCheckedPlanning] = useState(false);
    const [checkedTimetable, setCheckedTimetable] = useState(false);
    const [checkedElementCategory, setCheckedElementCategory] = useState(false);
    const [checkedManageTimetable, setCheckedManageTimetable] = useState(false);
    const [checkedDepartment, setCheckedDepartment] = useState(false);
    const [checkedPosition, setCheckedPosition] = useState(false);
    const [checkedRole, setCheckedRole] = useState(false);
    const [checkedUser, setCheckedUser] = useState(false);
    const [checkedSettingPrivilege, setCheckedSettingPrivilege] = useState(false);
    const [checkedTechnicalPrivilege, setCheckedTechnicalPrivilege] = useState(false);
    const [checkedManagementPrivilege, setCheckedManagementPrivilege] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    useEffect(() => {
        const checkSelectAll = [
            checkedProject,
            checkedElement,
            checkedSubElement,
            checkedPlanning,
            checkedTimetable,
            checkedElementCategory,
            checkedManageTimetable,
            checkedDepartment,
            checkedPosition,
            checkedRole,
            checkedUser,
            checkedSettingPrivilege,
            checkedTechnicalPrivilege,
            checkedManagementPrivilege
        ].every(value => value === true)
        setCheckAll(checkSelectAll)
    }, [
        checkedProject,
        checkedElement,
        checkedSubElement,
        checkedPlanning,
        checkedTimetable,
        checkedElementCategory,
        checkedManageTimetable,
        checkedDepartment,
        checkedPosition,
        checkedRole,
        checkedUser,
        checkedSettingPrivilege,
        checkedTechnicalPrivilege,
        checkedManagementPrivilege
    ])

    const onCheckAllChange = (e) => {
        const checked = e.target.checked
        setCheckAll(checked)
        setCheckedSettingPrivilege(checked)
        setCheckedTechnicalPrivilege(checked)
        setCheckedManagementPrivilege(checked)
        checkProjectCompRef.current.onChangeProject(checked)
        checkElementCompRef.current.onChangeElement(checked)
        checkSubElementCompRef.current.onChangeSubElement(checked)
        checkPlanningCompRef.current.onChangePlanning(checked)
        checkTimetableCompRef.current.onChangeTimetable(checked)
        checkElementCategoryCompRef.current.onChangeElementCategory(checked)
        checkManageTimetableCompRef.current.onChangeManageTimetable(checked)
        checkDepartmentCompRef.current.onChangeDepartment(checked)
        checkPositionCompRef.current.onChangePosition(checked)
        checkRoleCompRef.current.onChangeRole(checked)
        checkUserCompRef.current.onChangeUser(checked)

        form.setFieldsValue({
            projectPrivilege: checked,
            elementPrivilege: checked,
            subElementPrivilege: checked,
            planningPrivilege: checked,
            timetablePrivilege: checked,
            settingPrivilege: checked,

            // technical
            technicalPrivilege: checked,
            elementCategoryPrivilege: checked,
            manageTimetablePrivilege: checked,

            // management
            managementPrivilege: checked,
            departmentPrivilege: checked,
            positionPrivilege: checked,
            rolePrivilege: checked,
            userPrivilege: checked,
        })
    };

    // on-check-setting-privilege
    const onCheckSettingPrivilege = (e) => {
        const checked = e.target.checked
        setCheckedSettingPrivilege(checked)
        setCheckedTechnicalPrivilege(checked)
        setCheckedManagementPrivilege(checked)
        checkElementCategoryCompRef.current.onChangeElementCategory(checked)
        checkManageTimetableCompRef.current.onChangeManageTimetable(checked)
        checkDepartmentCompRef.current.onChangeDepartment(checked)
        checkPositionCompRef.current.onChangePosition(checked)
        checkRoleCompRef.current.onChangeRole(checked)
        checkUserCompRef.current.onChangeUser(checked)
        form.setFieldsValue({
            settingPrivilege: checked,
            // technical
            technicalPrivilege: checked,
            elementCategoryPrivilege: checked,
            manageTimetablePrivilege: checked,

            // management
            managementPrivilege: checked,
            departmentPrivilege: checked,
            positionPrivilege: checked,
            rolePrivilege: checked,
            userPrivilege: checked,
        })
    }

    // on-check-technical-privilege
    const onCheckTechnicalPrivilege = (e) => {
        const checked = e.target.checked
        setCheckedTechnicalPrivilege(checked)
        checkElementCategoryCompRef.current.onChangeElementCategory(checked)
        checkManageTimetableCompRef.current.onChangeManageTimetable(checked)
        form.setFieldsValue({
            technicalPrivilege: checked,
            elementCategoryPrivilege: checked,
            manageTimetablePrivilege: checked,
        })
    }

    // on-check-managemet-privilege
    const onCheckManagementPrivilege = (e) => {
        const checked = e.target.checked
        setCheckedManagementPrivilege(checked)
        checkDepartmentCompRef.current.onChangeDepartment(checked)
        checkPositionCompRef.current.onChangePosition(checked)
        checkRoleCompRef.current.onChangeRole(checked)
        checkUserCompRef.current.onChangeUser(checked)
        form.setFieldsValue({
            managementPrivilege: checked,
            departmentPrivilege: checked,
            positionPrivilege: checked,
            rolePrivilege: checked,
            userPrivilege: checked,
        })
    }

    const onClose = () => {
        setVisible(false);
    };

    const findElement = (arr, name) => {
        // console.log(arr)
        return arr.find(v => v === name)
    }

    const onFinish = async (values) => {
        const {
            projectGroup,
            elementGroup,
            subElementGroup,
            planningGroup,
            timetableGroup,
            elementCategoryGroup,
            manageTimetableGroup,
            departmentGroup,
            positionGroup,
            roleGroup,
            userGroup,
        } = values

        // project
        values.createProject = findElement(projectGroup, 'createProject') ? true : false
        values.updateProject = findElement(projectGroup, 'updateProject') ? true : false
        values.deleteProject = findElement(projectGroup, 'deleteProject') ? true : false

        // element
        values.createElement = findElement(elementGroup, 'createElement') ? true : false
        values.updateElement = findElement(elementGroup, 'updateElement') ? true : false
        values.deleteElement = findElement(elementGroup, 'deleteElement') ? true : false

        // sub-element
        values.createSubElement = findElement(subElementGroup, 'createSubElement') ? true : false
        values.updateSubElement = findElement(subElementGroup, 'updateSubElement') ? true : false
        values.deleteSubElement = findElement(subElementGroup, 'deleteSubElement') ? true : false

        // planning
        values.createPlanning = findElement(planningGroup, 'createPlanning') ? true : false
        values.updatePlanning = findElement(planningGroup, 'updatePlanning') ? true : false
        values.deletePlanning = findElement(planningGroup, 'deletePlanning') ? true : false

        // timetable
        values.createTimetable = findElement(timetableGroup, 'createTimetable') ? true : false
        values.updateTimetable = findElement(timetableGroup, 'updateTimetable') ? true : false
        values.deleteTimetable = findElement(timetableGroup, 'deleteTimetable') ? true : false

        // element-category
        values.createElementCategory = findElement(elementCategoryGroup, 'createElementCategory') ? true : false
        values.updateElementCategory = findElement(elementCategoryGroup, 'updateElementCategory') ? true : false
        values.deleteElementCategory = findElement(elementCategoryGroup, 'deleteElementCategory') ? true : false

        // manage-timetable
        values.createManageTimetable = findElement(manageTimetableGroup, 'createManageTimetable') ? true : false
        values.updateManageTimetable = findElement(manageTimetableGroup, 'updateManageTimetable') ? true : false
        values.deleteManageTimetable = findElement(manageTimetableGroup, 'deleteManageTimetable') ? true : false

        // department
        values.createDepartment = findElement(departmentGroup, 'createDepartment') ? true : false
        values.updateDepartment = findElement(departmentGroup, 'updateDepartment') ? true : false
        values.deleteDepartment = findElement(departmentGroup, 'deleteDepartment') ? true : false

        // position
        values.createPosition = findElement(positionGroup, 'createPosition') ? true : false
        values.updatePosition = findElement(positionGroup, 'updatePosition') ? true : false
        values.deletePosition = findElement(positionGroup, 'deletePosition') ? true : false

        // role
        values.createRole = findElement(roleGroup, 'createRole') ? true : false
        values.updateRole = findElement(roleGroup, 'updateRole') ? true : false
        values.deleteRole = findElement(roleGroup, 'deleteRole') ? true : false

        // user
        values.createUser = findElement(userGroup, 'createUser') ? true : false
        values.updateUser = findElement(userGroup, 'updateUser') ? true : false
        values.deleteUser = findElement(userGroup, 'deleteUser') ? true : false

        delete values.projectGroup
        delete values.elementGroup
        delete values.subElementGroup
        delete values.planningGroup
        delete values.timetableGroup
        delete values.elementCategoryGroup
        delete values.manageTimetableGroup
        delete values.departmentGroup
        delete values.positionGroup
        delete values.roleGroup
        delete values.userGroup

        try {
            values.typeCreated = "RoleCreated"
            values.key = uuidv4()
            setIsLoading(true)
            await API.graphql(graphqlOperation(createRole, { input: values }))
            Notification("success", "Success", "Role create successfully!")
            setIsLoading(false)
            form.resetFields()
            setCheckAll(false)
            setIndeterminate(false)
            setCheckedProject(false)
            setCheckedElement(false)
            setCheckedSubElement(false)
            setCheckedPlanning(false)
            setCheckedTimetable(false)
            setCheckedElementCategory(false)
            setCheckedSettingPrivilege(false)
            setCheckedTechnicalPrivilege(false)
            setCheckedManagementPrivilege(false)
            setCheckedManageTimetable(false)
            setCheckedDepartment(false)
            setCheckedPosition(false)
            setCheckedRole(false)
            setCheckedUser(false)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div>
            <Button
                type="primary"
                size="large"
                onClick={() => setVisible(true)}
                style={{ float: "right" }}
            >
                Create Role
            </Button>
            <Drawer
                title="Create Role"
                onClose={onClose}
                visible={visible}
                bodyStyle={{ backgroundColor: '#ebebeb' }}
                width="100%"
                footer={
                    <Row gutter={24}>
                        <Col span={4} offset={16}>
                            <Button
                                onClick={() => setVisible(false)}
                                icon={<CloseCircleOutlined />}
                                block size="large"
                                type="default"
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col span={4}>
                            <Button
                                icon={<SaveOutlined />}
                                block
                                size="large"
                                type="primary"
                                htmlType="submit"
                                form="formCreateRole"
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Form
                    id="formCreateRole"
                    form={form}
                    ref={formRef}
                    name="nest-messages"
                    layout="vertical"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                >
                    {
                        isLoading ? <Spin /> :
                            <>
                                <Row gutter={12}>
                                    <Col span={8}>
                                        <Form.Item name="roleName" label="Role Name" rules={[{ required: true }]}>
                                            <Input size="large" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                    <Title level={4} style={{ marginBottom: 0 }}>Select All</Title>
                                </Checkbox>
                                <Row gutter={12} style={{ marginTop: 24 }}>
                                    {/* project */}
                                    <CheckProjectGroup
                                        form={form}
                                        ref={checkProjectCompRef}
                                        setIndeterminate={setIndeterminate}
                                        setCheckAll={setCheckAll}
                                        setCheckedProject={setCheckedProject}
                                        checkedProject={checkedProject}
                                    />

                                    {/* element */}
                                    <CheckElementGroup
                                        form={form}
                                        ref={checkElementCompRef}
                                        setIndeterminate={setIndeterminate}
                                        setCheckAll={setCheckAll}
                                        setCheckedElement={setCheckedElement}
                                        checkedElement={checkedElement}
                                    />

                                    {/* sub-element */}
                                    <CheckSubElementGroup
                                        form={form}
                                        ref={checkSubElementCompRef}
                                        setIndeterminate={setIndeterminate}
                                        setCheckAll={setCheckAll}
                                        setCheckedSubElement={setCheckedSubElement}
                                        checkedSubElement={checkedSubElement}
                                    />

                                    {/* planning */}
                                    <CheckPlanningGroup
                                        form={form}
                                        ref={checkPlanningCompRef}
                                        setIndeterminate={setIndeterminate}
                                        setCheckAll={setCheckAll}
                                        setCheckedPlanning={setCheckedPlanning}
                                        checkedPlanning={checkedPlanning}
                                    />

                                    {/* timetable */}
                                    <CheckTimetableGroup
                                        form={form}
                                        ref={checkTimetableCompRef}
                                        setIndeterminate={setIndeterminate}
                                        setCheckAll={setCheckAll}
                                        setCheckedTimetable={setCheckedTimetable}
                                        checkedTimetable={checkedTimetable}
                                    />
                                </Row>

                                {/* setting */}
                                <Row gutter={12} style={{ marginTop: 24 }}>
                                    <Col span={24}>
                                        <Card bordered={false} className="large-card-container">
                                            <Form.Item name="settingPrivilege">
                                                <Checkbox onChange={onCheckSettingPrivilege} checked={checkedSettingPrivilege}>
                                                    <Title level={4} style={{ marginBottom: 0 }}>Setting</Title>
                                                </Checkbox>
                                            </Form.Item>

                                            <Divider />
                                            <Row gutter={10}>
                                                <Col span={8}>
                                                    {/* technical */}
                                                    <Form.Item name="technicalPrivilege" style={{ marginBottom: 10, marginTop: 0 }}>
                                                        <Checkbox
                                                            onChange={onCheckTechnicalPrivilege}
                                                            checked={checkedTechnicalPrivilege}
                                                            disabled={checkedSettingPrivilege ? false : true}
                                                        >
                                                            <span className={checkedSettingPrivilege ? 'parent-checkbox-label' : 'parent-checkbox-label-disabled'}>
                                                                Technical
                                                            </span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Row gutter={10}>
                                                        <CheckElementCategoryGroup
                                                            form={form}
                                                            ref={checkElementCategoryCompRef}
                                                            setIndeterminate={setIndeterminate}
                                                            setCheckAll={setCheckAll}
                                                            disabled={checkedTechnicalPrivilege}
                                                            setCheckedElementCategory={setCheckedElementCategory}
                                                            checkedElementCategory={checkedElementCategory}
                                                        />
                                                        <CheckManageTimetableGroup
                                                            form={form}
                                                            ref={checkManageTimetableCompRef}
                                                            setIndeterminate={setIndeterminate}
                                                            setCheckAll={setCheckAll}
                                                            disabled={checkedTechnicalPrivilege}
                                                            setCheckedManageTimetable={setCheckedManageTimetable}
                                                            checkedManageTimetable={checkedManageTimetable}
                                                        />
                                                    </Row>
                                                </Col>

                                                {/* management */}
                                                <Col span={15} offset={1}>
                                                    <Form.Item
                                                        name="managementPrivilege"
                                                        style={{ marginBottom: 10, marginTop: 0 }}
                                                    >
                                                        <Checkbox
                                                            onChange={onCheckManagementPrivilege}
                                                            checked={checkedManagementPrivilege}
                                                            disabled={checkedSettingPrivilege ? false : true}
                                                        >
                                                            <span className={checkedSettingPrivilege ? 'parent-checkbox-label' : 'parent-checkbox-label-disabled'}>
                                                                Management
                                                            </span>
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Row gutter={10}>
                                                        <CheckDepartmentGroup
                                                            form={form}
                                                            ref={checkDepartmentCompRef}
                                                            setIndeterminate={setIndeterminate}
                                                            setCheckAll={setCheckAll}
                                                            disabled={checkedManagementPrivilege}
                                                            setCheckedDepartment={setCheckedDepartment}
                                                            checkedDepartment={checkedDepartment}
                                                        />
                                                        <CheckPositionGroup
                                                            form={form}
                                                            ref={checkPositionCompRef}
                                                            setIndeterminate={setIndeterminate}
                                                            setCheckAll={setCheckAll}
                                                            disabled={checkedManagementPrivilege}
                                                            setCheckedPosition={setCheckedPosition}
                                                            checkedPosition={checkedPosition}
                                                        />
                                                        <CheckRoleGroup
                                                            form={form}
                                                            ref={checkRoleCompRef}
                                                            setIndeterminate={setIndeterminate}
                                                            setCheckAll={setCheckAll}
                                                            disabled={checkedManagementPrivilege}
                                                            setCheckedRole={setCheckedRole}
                                                            checkedRole={checkedRole}
                                                        />
                                                        <CheckUserGroup
                                                            form={form}
                                                            ref={checkUserCompRef}
                                                            setIndeterminate={setIndeterminate}
                                                            setCheckAll={setCheckAll}
                                                            disabled={checkedManagementPrivilege}
                                                            setCheckedUser={setCheckedUser}
                                                            checkedUser={checkedUser}
                                                        />
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                    }
                </Form>
            </Drawer>
        </div>
    )
}

export default CheckboxDrawer