import { useState, forwardRef, useImperativeHandle } from "react"
import { Row, Col, Typography, Form, Divider, Checkbox, Card } from "antd"
import "../CreateRoleDrawer.less"

const { Title } = Typography
const CheckboxGroup = Checkbox.Group;

// element-options
const elementOptions = [
    {
        label: 'Read All Element',
        value: 'readAllElement'
    },
    {
        label: 'Read Own Element',
        value: 'readOwnElement'
    },
    {
        label: 'Create',
        value: 'createElement'
    },
    {
        label: 'Update',
        value: 'updateElement'
    },
    {
        label: 'Delete',
        value: 'deleteElement'
    }
];

const CheckElementGroup = forwardRef((props, ref) => {
    const [checkedElementGroup, setCheckedElementGroup] = useState(null);
    const {
        form,
        setIndeterminate,
        setCheckAll,
        checkedElement,
        setCheckedElement
    } = props

    useImperativeHandle(ref, () => ({
        onChangeElement(checked) {
            setCheckedElementGroup(checked ? elementOptions.map(v => v.value) : []);
            setCheckedElement(checked)
            form.setFieldsValue({
                elementGroup: checked ? elementOptions.map(v => v.value) : []
            })
        }
    }))

    const onChangeElement = (e) => {
        const checked = e.target.checked
        setCheckedElementGroup(checked ? elementOptions.map(v => v.value) : []);
        setCheckedElement(checked)
        setIndeterminate(false)
        form.setFieldsValue({
            elementPrivilege: checked,
            elementGroup: checked ? elementOptions.map(v => v.value) : []
        })
    }
    const onChangeElementGroup = (list) => {
        setIndeterminate(!!list.length && list.length < elementOptions.length);
        setCheckAll(list.length === elementOptions.length);
    };

    return (
        <Col span={4}>
            <Card bordered={false} className="large-card-container">
                <Form.Item name="elementPrivilege" valuePropName="checked" style={{ marginBottom: -10, marginTop: 0 }}>
                    <Checkbox onChange={onChangeElement} checked={checkedElement}>
                        <Title level={5} style={{ marginBottom: 0 }}>Element</Title>
                    </Checkbox>
                </Form.Item>
                <Divider />
                <Form.Item name="elementGroup" style={{ marginBottom: 0, marginTop: -10 }}>
                    <Checkbox.Group>
                        <Row>
                            <Col span={24}>
                                <Checkbox
                                    value="readAllElement"
                                    disabled={checkedElement ? false : true}
                                    onChange={onChangeElementGroup}
                                >
                                    Read All Element
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="readOwnElement"
                                    disabled={checkedElement ? false : true}
                                    onChange={onChangeElementGroup}
                                >
                                    Read Own Element
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="createElement"
                                    disabled={checkedElement ? false : true}
                                    onChange={onChangeElementGroup}
                                >
                                    Create
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="updateElement"
                                    disabled={checkedElement ? false : true}
                                    onChange={onChangeElementGroup}
                                >
                                    Update
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="deleteElement"
                                    disabled={checkedElement ? false : true}
                                    onChange={onChangeElementGroup}
                                >
                                    Delete
                                </Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                    {/* <CheckboxGroup
                        disabled={checkedElement ? false : true}
                        options={elementOptions}
                        value={checkedElementGroup}
                        onChange={onChangeElementGroup}
                    /> */}
                </Form.Item>
            </Card>
        </Col>
    )
})

export default CheckElementGroup