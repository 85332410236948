import { useState, useEffect } from "react"
import { Form, Select } from "antd"
import { useParams, useNavigate } from "react-router-dom"

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listProjectsByCreatedDate } from "../../graphql/queries"

// graphql-sub
import {
    onCreateProject,
    onUpdateProject,
    onDeleteProject
} from "../../graphql/subscriptions"

const SelectProject = ({ path }) => {
    const [projects, setProjects] = useState([])
    const [project, setProject] = useState(null)

    // router
    const navigate = useNavigate()
    const { projectId, elementCategoryId, elementId, userId } = useParams()

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    // execute query 
    useEffect(() => {
        queryProjects()
    }, [project])

    // execute subscription
    useEffect(() => {
        subOnCreateProject()
        subOnUpdateProject()
        subOnDeleteProject()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query projects
    const queryProjects = async (searchParam) => {
        try {
            const projectData = await API.graphql(graphqlOperation(listProjectsByCreatedDate, {
                typeCreated: "ProjectCreated",
                sortDirection: "DESC"
            }))
            const { data: { listProjectsByCreatedDate: { items } } } = projectData
            setProjects(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create project
    const subOnCreateProject = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateProject))
                .subscribe({
                    next: ({ value }) => {
                        setProject(value.data.onCreateProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update project
    const subOnUpdateProject = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateProject))
                .subscribe({
                    next: ({ value }) => {
                        setProject(value.data.onUpdateProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete project
    const subOnDeleteProject = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteProject))
                .subscribe({
                    next: ({ value }) => {
                        setProject(value.data.onDeleteProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Form.Item label="Project">
            <Select
                showSearch
                allowClear
                size="large"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
                onSelect={(value) => navigate(`/${path}/${value}/${elementCategoryId ? elementCategoryId : "0"}/${elementId ? elementId : "0"}/${userId ? userId : "0"}`)}
                onClear={() => navigate(`/${path}/0/${elementCategoryId ? elementCategoryId : "0"}/${elementId ? elementId : "0"}/${userId ? userId : "0"}`)}
                defaultValue={projectId !== "0" ? projectId : null}
            >
                {
                    projects && projects.map((item, key) => {
                        const { id, projectNumber } = item
                        return <Select.Option key={key} value={id}>{projectNumber}</Select.Option>
                    })
                }
            </Select>
        </Form.Item>
    )
}

export default SelectProject