import { useState, useEffect } from "react"
import { Layout, Row, Col } from "antd"
import Cookies from 'universal-cookie';
import "./SubElement.less"

// external-component
import Empty from "../../components/MyEmpty/MyEmpty";

// internal-component
import SubElementDatatable from "./components/Datatable/SubElementDatatable";
import ProjectMemberAvatar from "./components/ProjectMemberAvatar";
import ProjectTitleHeader from "./components/ProjectTitleHeader";
import ProjectElementHeader from "./components/ProjectElementHeader";
import ProjectManagerAvatar from "./components/ProjectManagerAvatar";

// react-router
import { useParams } from 'react-router-dom'

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import {
    listUsersByCreatedDate,
    listRolesByCreatedDate
} from "../../graphql/queries"

// graphql-sub
import {
    onUpdateUser,
    onUpdateRole
} from "../../graphql/subscriptions"

const { Content } = Layout;
const cookies = new Cookies();

const RenderProjectDetail = () => {
    const [user, setUser] = useState(null)
    const [roleId, setRoleId] = useState(null)
    const [roles, setRoles] = useState(null)
    const [role, setRole] = useState(null)
    const userEmail = cookies.get('userEmail')
    const { id: projectId } = useParams()

    let subscriptionOnUpdateUser;
    let subscriptionOnUpdateRole;

    // execute-user-and-role
    useEffect(() => {
        subOnUpdateUser()
        subOnUpdateRole()
        return () => {
            subscriptionOnUpdateUser.unsubscribe()
            subscriptionOnUpdateRole.unsubscribe()
        }
    }, [])

    useEffect(() => {
        queryUser()
    }, [user])

    useEffect(() => {
        queryRole()
    }, [role, roleId])

    const queryUser = async () => {
        try {
            const userData = await API.graphql(graphqlOperation(listUsersByCreatedDate, {
                typeCreated: "UserCreated",
                filter: {
                    email: { eq: userEmail }
                }
            }))
            const userItem = userData.data.listUsersByCreatedDate.items[0]
            setRoleId(userItem.roleUserId)
        } catch (err) {
            console.log(err)
        }
    }

    const queryRole = async () => {
        try {
            const roleData = await API.graphql(graphqlOperation(listRolesByCreatedDate, {
                typeCreated: "RoleCreated",
                filter: {
                    id: { eq: roleId ? roleId : null }
                }
            }))
            const roleItem = roleData.data.listRolesByCreatedDate.items[0]
            setRoles(roleItem)
        } catch (err) {
            console.log(err)
        }
    }

    // sub-update-user
    const subOnUpdateUser = async () => {
        try {
            subscriptionOnUpdateUser = await API.graphql(graphqlOperation(onUpdateUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onUpdateUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub-update-role
    const subOnUpdateRole = async () => {
        try {
            subscriptionOnUpdateRole = await API.graphql(graphqlOperation(onUpdateRole))
                .subscribe({
                    next: ({ value }) => {
                        setRole(value.data.onUpdateRole)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Content style={{ position: "relative", padding: 24, minHeight: "90vh", backgroundColor: "#fff" }}>
            {
                projectId && projectId !== "project" ?
                    <>
                        {
                            roles && roles.readAllSubElement === true && roles.readOwnSubElement === true ?
                                <>
                                    <ProjectTitleHeader />
                                    <ProjectElementHeader />
                                    <Row gutter={50}>
                                        <Col>
                                            <ProjectManagerAvatar />
                                        </Col>
                                        <Col>
                                            <ProjectMemberAvatar />
                                        </Col>
                                    </Row>
                                    <SubElementDatatable />
                                </> :
                                <Empty des="Please ask the administrator to grant you access." />
                        }
                    </>
                    : <Empty des="Don't have sub element!" />
            }
        </Content>
    )
}

export default RenderProjectDetail