import { API, graphqlOperation } from "aws-amplify"
import { deletePlanning } from "../../graphql/mutations"
import { listPlannings } from "../../graphql/queries"

export const deletePlanningById = async (id) => {
    try {
        await API.graphql(graphqlOperation(deletePlanning, { input: { id } }))
    } catch (err) {
        console.log(err)
    }
}

export const deletePlanningBySubElementId = async (subElementId) => {
    try {
        const planningData = await API.graphql(graphqlOperation(listPlannings, {
            filter: {
                subElementPlanningId: { eq: subElementId }
            }
        }))
        const { data: { listPlannings: { items: planningItems } } } = planningData
        if (planningItems.length > 0) {
            const planningId = planningItems[0].id
            await API.graphql(graphqlOperation(deletePlanning, { input: { id: planningId } }))
        }
    } catch (err) {
        console.log(err)
    }
}