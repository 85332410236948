import { useEffect } from "react"
import { useParams } from "react-router-dom"

// apollo-client
import { useLazyQuery } from "@apollo/client"

// graphql-query
import { listElements } from "../../graphql/queries"

// graphql-sub
import { onUpdateElement } from "../../graphql/subscriptions"

const ViewElementInfo = () => {
    const { elementId } = useParams()

    useEffect(() => {
        if (elementId) {
            loadElement()
        }
    }, [elementId])

    // query element
    const [loadElement, { subscribeToMore, loading, error, data }] = useLazyQuery(listElements, {
        variables: {
            filter: {
                id: { eq: elementId ? elementId : null }
            }
        }
    })

    // on update element
    useEffect(() =>
        subscribeToMore({
            document: onUpdateElement,
            updateQuery: (prev) => {
                const { listElements: { items } } = prev
                return {
                    listElements: {
                        items: [...items],
                        nextToken: null,
                        __typename: "Element"
                    }
                }
            }
        }), [subscribeToMore]
    )

    if (loading) return <div>...</div>
    if (error) console.log(error)
    if (data) {
        const { listElements: { items } } = data
        return (
            <span>
                {
                    items.length > 0 &&
                    <span style={{ marginLeft: 5 }}>
                        / Element: <b>{items[0].elementName}</b>
                    </span>
                }
            </span>
        )
    }
}

export default ViewElementInfo