import { useState, useEffect } from "react"
import { Form, Select } from "antd"
import { useParams, useNavigate } from "react-router-dom"

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listElementsByCreatedDate } from "../../graphql/queries"

// graphql-sub
import {
    onCreateElement,
    onUpdateElement,
    onDeleteElement
} from "../../graphql/subscriptions"

const SelectElement = ({ path }) => {
    const [elements, setElements] = useState([])
    const [element, setElement] = useState(null)
    const [form] = Form.useForm()

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    // router
    const navigate = useNavigate()
    const { projectId, elementCategoryId, elementId, userId } = useParams()
    
    // execute query 
    useEffect(() => {
        queryElements()
    }, [projectId, elementCategoryId, element])

    // reset select element
    useEffect(() => {
        console.log(elementCategoryId)
        form.resetFields()
    }, [elementCategoryId])

    // execute subscription
    useEffect(() => {
        subOnCreateElement()
        subOnUpdateElement()
        subOnDeleteElement()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query elements
    const queryElements = async () => {
        try {
            const elementData = await API.graphql(graphqlOperation(listElementsByCreatedDate, {
                typeCreated: "ElementCreated",
                sortDirection: "DESC",
                filter: {
                    projectElementId: { eq: projectId ? projectId : null },
                    elementCategoryElementId: { contains: !elementCategoryId ? "" : elementCategoryId !== "0" ? elementCategoryId : "" }
                },
                limit: 500
            }))
            const { data: { listElementsByCreatedDate: { items } } } = elementData
            setElements(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create element
    const subOnCreateElement = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateElement))
                .subscribe({
                    next: ({ value }) => {
                        setElement(value.data.onCreateElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update element
    const subOnUpdateElement = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateElement))
                .subscribe({
                    next: ({ value }) => {
                        setElement(value.data.onUpdateElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete element
    const subOnDeleteElement = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteElement))
                .subscribe({
                    next: ({ value }) => {
                        setElement(value.data.onDeleteElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Form layout="vertical" form={form}>
            <Form.Item label="Element">
                <Select
                    showSearch
                    allowClear
                    size="large"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(value) => navigate(`/${path}/${projectId ? projectId : "0"}/${elementCategoryId ? elementCategoryId : "0"}/${value}/${userId ? userId : "0"}`)}
                    onClear={() => navigate(`/${path}/${projectId ? projectId : "0"}/${elementCategoryId ? elementCategoryId : "0"}/"0"/${userId ? userId : "0"}`)}
                    defaultValue={elementId !== "0" ? elementId : null}
                    style={{ width: "100%" }}
                >
                    {
                        elements && elements.map((item, key) => {
                            const { id, elementName, elementCategory } = item
                            return <Select.Option key={key} value={id}>{elementCategory.categoryName}-{elementName}</Select.Option>
                        })
                    }
                </Select>
            </Form.Item>
        </Form>
    )
}

export default SelectElement