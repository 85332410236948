import { Button, Tooltip } from "antd"
import { CopyOutlined } from "@ant-design/icons"
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';

// external-component
import Notification from "../../../../components/Notification/Notification"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// mutation-create
import {
    createSubElement,
    createPlanning
} from "../../../../graphql/mutations"

const cookies = new Cookies();

const CopySubElement = (props) => {
    const { data } = props

    // cookies
    const userId = cookies.get('userId')

    const {
        elementSubElementId,
        index,
        numberOfDrawing,
        priority,
        projectSubElementId
    } = data

    const handleCopy = async () => {
        try {
            let subElementId = uuidv4()
            let planningId = uuidv4()

            // subelement-values
            let subElementValues = {
                id: subElementId,
                key: uuidv4(),
                index: index,
                numberOfDrawing: numberOfDrawing,
                priority: priority,
                elementSubElementId: elementSubElementId,
                projectSubElementId: projectSubElementId,
                typeCreated: "SubElementCreated",
                userSubElementId: userId
            }

            // planning-values
            let planningValues = {
                id: planningId,
                key: uuidv4(),
                typeCreated: "PlanningCreated",
                projectPlanningId: projectSubElementId,
                elementPlanningId: elementSubElementId,
                subElementPlanningId: subElementId,
                userPlanningId: userId,
                status: "todo"
            }

            // timetable-values
            // let timetableValues = {
            //     key: uuidv4(),
            //     typeCreated: "TimetableCreated",
            //     projectTimetableId: projectSubElementId,
            //     elementTimetableId: elementSubElementId,
            //     subElementTimetableId: subElementId,
            //     planningTimetableId: planningId,
            //     userTimetableId: userId
            // }

            await API.graphql(graphqlOperation(createSubElement, { input: subElementValues }))
            await API.graphql(graphqlOperation(createPlanning, { input: planningValues }))
            // await API.graphql(graphqlOperation(createTimetable, { input: timetableValues }))
            Notification("success", "Success", `Sub Element copy successfully!`)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Tooltip placement="bottom" title="Copy">
            <Button
                icon={<CopyOutlined />}
                onClick={handleCopy}
            />
        </Tooltip>
    )
}

export default CopySubElement