import { Timeline, Col, Typography } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const { Title } = Typography

const PlanningRole = ({ item }) => {
    const {
        readAllPlanning,
        readOwnPlanning,
        createPlanning,
        updatePlanning,
        deletePlanning
    } = item

    return (
        <Col span={5}>
            <Title level={5} className="timeline-header">Planning</Title>
            <Timeline>
                <Timeline.Item
                    color={readAllPlanning ? "green" : "red"}
                    dot={readAllPlanning ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read All Planning
                </Timeline.Item>
                <Timeline.Item
                    color={readOwnPlanning ? "green" : "red"}
                    dot={readOwnPlanning ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read Own Planning
                </Timeline.Item>
                <Timeline.Item
                    color={createPlanning ? "green" : "red"}
                    dot={createPlanning ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Create
                </Timeline.Item>
                <Timeline.Item
                    color={updatePlanning ? "green" : "red"}
                    dot={updatePlanning ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Update
                </Timeline.Item>
                <Timeline.Item
                    color={deletePlanning ? "green" : "red"}
                    dot={deletePlanning ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Delete
                </Timeline.Item>
            </Timeline>
        </Col>
    )
}

export default PlanningRole