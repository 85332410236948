import { useState, createRef, useRef, useEffect } from "react"
import { Button, Drawer, Row, Col, Form, Input, Divider, DatePicker, Typography, Select } from "antd"
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons"
import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom"
import Cookies from 'universal-cookie';

// external-component
import Notification from "../../../../components/Notification/Notification"
import Spin from "../../../../components/Spin/Spin"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { getProject, listUsers, getElement } from "../../../../graphql/queries"

// mutation-create
import {
    createSubElement,
    createPlanning,
    createTimetable
} from "../../../../graphql/mutations"

const { TextArea } = Input
const { Title, Text } = Typography
const { Option } = Select
const cookies = new Cookies();

const CreateSubElementDrawer = (props) => {
    const [users, setUsers] = useState(null)
    const [user, setUser] = useState(null)
    const [listUserId, setListUserId] = useState(null)
    const [elementCategoryId, setElementCategoryId] = useState(null)

    const [visible, setVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [alphabetList, setAlphabetList] = useState(null)
    const [numberList, setNumberList] = useState(null)
    const dateFormat = 'DD/MM/YYYY'
    const { id: projectId, elementId } = useParams()

    // cookies
    const userId = cookies.get('userId')

    const [form] = Form.useForm()
    let formRef = createRef()
    const inputRef = useRef(null);
    const sharedProps = {
        ref: inputRef,
    };

    useEffect(() => {
        getAlphabetList()
        getNumberList()
        queryProjectById()
        queryAllUsers()
        queryElementCategory()
    }, [])

    useEffect(() => {
        if (listUserId) {
            form.setFieldsValue({
                subElementMemberId: listUserId
            })
        }
    }, [listUserId])

    const queryProjectById = async () => {
        try {
            const projectData = await API.graphql(graphqlOperation(getProject, {
                id: projectId
            }))
            if (projectData) {
                const { data: { getProject: { projectMemberId } } } = projectData
                setListUserId(projectMemberId)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const queryAllUsers = async () => {
        try {
            const userData = await API.graphql(graphqlOperation(listUsers))
            const { data: { listUsers: { items } } } = userData
            setUsers(items)
        } catch (err) {
            console.log(err)
        }
    }

    const queryElementCategory = async () => {
        try {
            const elementCategoryData = await API.graphql(graphqlOperation(getElement, {
                id: elementId
            }))
            const { data: { getElement: elementDoc } } = elementCategoryData
            setElementCategoryId(elementDoc.elementCategoryElementId)
        } catch (err) {
            console.log(err)
        }
    }

    const getAlphabetList = () => {
        let alphabet = [{
            value: "Non",
            label: "Non"
        }]
        for (let i = 0; i < 10; i++) {
            let rs = (i + 10).toString(36)
            alphabet.push({
                value: rs,
                label: rs
            })
        }
        setAlphabetList(alphabet)
    }

    const getNumberList = () => {
        let number = [{
            value: "Non",
            label: "Non"
        }]
        for (let i = 0; i <= 10; i++) {
            number.push({
                value: i.toString(),
                label: i.toString()
            })
        }
        setNumberList(number)
    }

    const onFinish = async (values) => {
        let subElementId = uuidv4()
        console.log(values)
        try {
            values.id = subElementId
            values.key = uuidv4()
            values.projectSubElementId = projectId
            values.elementSubElementId = elementId
            values.typeCreated = "SubElementCreated"
            values.userSubElementId = userId
            values.elementCategorySubElementId = elementCategoryId

            const getEntreprisFax = values.entreprisFax ? values.entreprisFax : null
            const getEntreprisPaperVersion = values.entreprisPaperVersion ? values.entreprisPaperVersion : null
            const getContractorFax = values.contractorFax ? values.contractorFax : null
            const getContractorPaperVersion = values.contractorPaperVersion ? values.contractorPaperVersion : null
            const getObservationsConsultant = values.observationsConsultant ? values.observationsConsultant : null
            const getObservationsEntrepris = values.observationsEntrepris ? values.observationsEntrepris : null
            const getObservationsProjectDirector = values.observationsProjectDirector ? values.observationsProjectDirector : null

            let status = "todo"
            if (getEntreprisFax || getEntreprisPaperVersion || getContractorFax || getContractorPaperVersion || getObservationsConsultant || getObservationsEntrepris || getObservationsProjectDirector) {
                status = "done"
            }

            // planning-values
            let planningValues = {
                key: uuidv4(),
                typeCreated: "PlanningCreated",
                projectPlanningId: projectId,
                elementPlanningId: elementId,
                subElementPlanningId: subElementId,
                status: status,
                planningMemberId: values.subElementMemberId,
                userPlanningId: userId
            }

            // timetable-values
            let timetableValues = {
                key: uuidv4(),
                typeCreated: "TimetableCreated",
                projectTimetableId: projectId,
                elementTimetableId: elementId,
                subElementTimetableId: subElementId,
                timetableMemberId: values.subElementMemberId,
                userTimetableId: userId
            }

            setIsLoading(true)
            await API.graphql(graphqlOperation(createSubElement, { input: values }))
            await API.graphql(graphqlOperation(createPlanning, { input: planningValues }))
            await API.graphql(graphqlOperation(createTimetable, { input: timetableValues }))
            Notification("success", "Success", `Sub Element create successfully!`)
            setIsLoading(false)
            form.resetFields()
        } catch (error) {
            console.log(error)
        }
    };

    const validateMessages = {
        required: '${label} is required!'
    };

    const onClose = () => {
        setVisible(false);
    };

    return (
        <div>
            <Button
                type="primary"
                size="large"
                onClick={() => setVisible(true)}
                style={{ marginBottom: 20, float: "right" }}
            >
                Create Sub Element
            </Button>
            <Drawer
                title={`Create Sub Element`}
                onClose={onClose}
                visible={visible}
                width="1500"
            >
                {
                    isLoading ? <Spin /> :
                        <Form form={form} ref={formRef} name="nest-messages" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <Row gutter={24}>
                                <Col span={4}>
                                    <Title level={5} style={{ color: "#fff" }}>Contractor</Title>
                                    <Form.Item name="priority" label="Priority" >
                                        <Input size="large" {...sharedProps} />
                                    </Form.Item>
                                    <Form.Item name="numberOfDrawing" label="Number of Drawing">
                                        <Input size="large" />
                                    </Form.Item>
                                    <Form.Item name="index" label="Ind" >
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Title level={5} style={{ color: "#fff" }}>Contractor</Title>
                                    <Form.Item name="ctcPredictedDate" label="Predicted Date">
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Text>Prov</Text>
                                            <Form.Item name="provNumber">
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    size="large"
                                                    placeholder="Prov(0)"
                                                    optionFilterProp="children"
                                                    style={{ marginTop: 8 }}
                                                >
                                                    {
                                                        numberList && numberList.map((list, index) =>
                                                            <Option key={index} value={list.value}>{list.label}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="provAlphabet">
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    size="large"
                                                    placeholder="Prov(a)"
                                                    optionFilterProp="children"
                                                    style={{ marginTop: 30 }}
                                                >
                                                    {
                                                        alphabetList && alphabetList.map((list, index) =>
                                                            <Option key={index} value={list.value}>{list.label}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name="subElementMemberId" label="Project Member">
                                                <Select size="large" mode="tags" allowClear>
                                                    {
                                                        users ? users.map((data, key) =>
                                                            <Select.Option key={key} value={data.id}>{data.firstName} {data.lastName}</Select.Option>
                                                        ) : null
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={5}>
                                    <Title level={5}>Client received on</Title>
                                    <Form.Item name="entreprisPaperVersion" label="Paper Version">
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                    <Form.Item name="entreprisNumber" label="Nbr">
                                        <Input size="large" />
                                    </Form.Item>
                                    <Form.Item name="entreprisFax" label="Fax/Internet">
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Title level={5}>Controller received on</Title>
                                    <Form.Item name="contractorPaperVersion" label="Paper Version" >
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                    <Form.Item name="contractorNumber" label="Nbr">
                                        <Input size="large" />
                                    </Form.Item>
                                    <Form.Item name="contractorFax" label="Fax/Internet">
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Title level={5}>Remark received on</Title>
                                    <Form.Item name="observationsEntrepris" label="Client" >
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                    <Form.Item name="observationsProjectDirector" label="Designer" >
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                    <Form.Item name="observationsConsultant" label="Controller">
                                        <DatePicker size="large" style={{ width: "100%" }} format={dateFormat} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="description" label="Description">
                                        <TextArea rows={5} size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={4} offset={16}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} size="large" block type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button icon={<SaveOutlined />} size="large" block type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </div>
    )
}

export default CreateSubElementDrawer