import { Layout } from "antd"
import "./Project.less"

// parts
import ProjectListContainer from "../../parts/ProjectListContainer/ProjectListContainer"
import Element from "../../parts/Element/Element";
import SubElement from "../../parts/SubElement/SubElement";

const Project = () => {
    return (
        <Layout style={{ height: '90vh' }}>
            <ProjectListContainer />
            <Layout>
                <Element />
                <SubElement />
            </Layout>
        </Layout>
    )
}

export default Project