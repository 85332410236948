import { useState, useEffect } from "react"
import { Form, Select } from "antd"

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listRolesByCreatedDate } from "../../../../graphql/queries"

// graphql-sub
import {
    onCreateRole,
    onUpdateRole,
    onDeleteRole
} from "../../../../graphql/subscriptions"

const SelectRole = (props) => {
    const [roles, setRoles] = useState(null)
    const [role, setRole] = useState(null)
    const { form, roleUserId } = props

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    useEffect(() => {
        form.setFieldsValue({
            roleUserId: roleUserId
        })
    }, [roleUserId])

    // execute query 
    useEffect(() => {
        queryRoles()
    }, [role])

    // execute subscription
    useEffect(() => {
        subOnCreateRole()
        subOnUpdateRole()
        subOnDeleteRole()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query roles
    const queryRoles = async () => {
        try {
            const roleData = await API.graphql(graphqlOperation(listRolesByCreatedDate, {
                typeCreated: "RoleCreated",
                sortDirection: "DESC",
            }))
            const { data: { listRolesByCreatedDate: { items } } } = roleData
            setRoles(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create 
    const subOnCreateRole = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateRole))
                .subscribe({
                    next: ({ value }) => {
                        setRole(value.data.onCreateRole)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update 
    const subOnUpdateRole = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateRole))
                .subscribe({
                    next: ({ value }) => {
                        setRole(value.data.onUpdateRole)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete 
    const subOnDeleteRole = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteRole))
                .subscribe({
                    next: ({ value }) => {
                        setRole(value.data.onDeleteRole)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Form.Item name="roleUserId" label="Role" rules={[{ required: true }]}>
            <Select size="large">
                {
                    roles && roles.map((data, key) => {
                        const { id, roleName } = data
                        return <Select.Option key={key} value={id}>{roleName}</Select.Option>
                    })
                }
            </Select>
        </Form.Item>
    )
}

export default SelectRole