import { useState, useEffect } from "react"
import './App.less'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Cookies from 'universal-cookie';

// layout
import AppLayout from './layout/AppLayout/AppLayout'
import LoginLayout from './layout/LoginLayout/LoginLayout'
import RootLayout from './layout/RootLayout/RootLayout'

// pages
import Project from './pages/Project/Project';
import Planning from './pages/Planning/Planning';
import Timetable from './pages/Timetable/Timetable';
import Setting from './pages/Setting/Setting';
import NoRole from "./pages/NoRole/NoRole"
import AccessDenied from "./pages/AccessDenied/AccessDenied";
import ElementList from "./pages/ElementList/ElementList";

// external-component
import NetworkDetector from "./components/NetworkDetector/NetworkDetector";
import ProtectedRoutes from "./components/ProtectedRoutes/ProtectedRoutes";

// aws-amplify
import { API, graphqlOperation, Auth, Hub } from "aws-amplify"

// graphql-query
import { listRoles, listUsersByCreatedDate } from "./graphql/queries"

// graphql-sub
import { onUpdateUser } from "./graphql/subscriptions"

// mutation-create
import { createRole } from "./graphql/mutations"

// shared
import { roleValues } from "./shared/Role"

// shared-method
import { checkUser } from "./shared/methods/CheckUserAuth"
import { queryRoles } from "./shared/methods/CreateRole"

const cookies = new Cookies();

function App() {
  const [users, setUsers] = useState(null)
  const [user, setUser] = useState(null)
  const [checkRole, setCheckRole] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const isAuthenticated = cookies.get('isAuthenticated');
  const userEmail = cookies.get('userEmail');

  let subscriptionOnUpdate;

  // execute subscription
  // useEffect(() => {
  //   subOnUpdateUser()
  //   return () => {
  //     subscriptionOnUpdate.unsubscribe()
  //   }
  // }, [])

  // useEffect(() => {
  //   queryRoles()
  // }, [])

  useEffect(() => {
    queryUsers()
  }, [user, userEmail])

  const queryUsers = async () => {
    try {
      setIsLoading(true)
      const userData = await API.graphql(graphqlOperation(listUsersByCreatedDate, {
        typeCreated: "UserCreated",
        filter: {
          email: { eq: userEmail }
        }
      }))
      const getItem = userData.data.listUsersByCreatedDate.items[0]
      if (getItem && getItem.roleUserId) {
        setCheckRole(true)
      } else {
        setCheckRole(false)
      }
      // if (getItem.roleUserId) {
      //   setCheckRole(true)
      // } else {
      //   setCheckRole(false)
      // }
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  // sub update
  const subOnUpdateUser = async () => {
    try {
      subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateUser))
        .subscribe({
          next: ({ value }) => {
            setUser(value.data.onUpdateUser)
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginLayout />} />
        <Route path="/root" element={<RootLayout />} />
        {/* <Route path="/" element={<AppLayout replace />}> */}
        <Route path="/" element={<ProtectedRoutes />}>
          {/* <Route path="/" element={
            !isLoading && checkRole ? <AppLayout /> : <AccessDenied />
          }> */}
          <Route path="/" element={<Project />} />
          <Route path="/:id" element={<Project />} />
          <Route path="/:id/:elementId" element={<Project />} />

          {/* planning */}
          <Route path="/planning" element={<Planning />} />
          <Route path="/planning/:projectId/:elementId/:userId" element={<Planning />} />

          {/* timetable */}
          <Route path="/timetable" element={<Timetable />} />
          <Route path="/timetable/:projectId/:elementId/:userId" element={<Timetable />} />

          {/* element-list */}
          <Route path="/elementList" element={<ElementList />} />
          {/* <Route path="/elementList/:projectId/:elementId/:userId" element={<ElementList />} /> */}
          <Route path="/elementList/:projectId/:elementCategoryId/:elementId/:userId" element={<ElementList />} />

          {/* setting */}
          <Route path="/setting" element={<Setting />} />
        </Route>
        {/* </Route> */}
        <Route path="/noRole" element={<AccessDenied />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;