import { useLottie } from "lottie-react";
import "./style.less";
import timer from "../../assets/lotties/waiting.json";

const LottieWaiting = () => {
    const options = {
        animationData: timer,
        loop: true,
        autoplay: true
    };
    const { View } = useLottie(options);
    return <div className="center">{View}</div>
}

export default LottieWaiting