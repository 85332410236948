import { useState, useEffect, createRef, useRef } from "react"
import { Button, Drawer, Row, Col, Typography, Form, Select, Input, Divider, Skeleton } from "antd"
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons"
import { v4 as uuidv4 } from 'uuid';

// external-component
import Notification from "../../../components/Notification/Notification"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// mutation-create
import { createPosition } from "../../../graphql/mutations"

// graphql-query
import { listDepartmentsByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateDepartment,
    onUpdateDepartment,
    onDeleteDepartment
} from "../../../graphql/subscriptions"

import MySpin from "../../../components/Spin/Spin"

const { Title } = Typography
const { Option } = Select;

const CreatePositionDrawer = () => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [departments, setDepartments] = useState(null)
    const [department, setDepartment] = useState(null)
    const [form] = Form.useForm()
    let formRef = createRef()
    const inputRef = useRef(null);
    const sharedProps = {
        ref: inputRef,
    };

    const validateMessages = {
        required: '${label} is required!'
    };

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    // execute query 
    useEffect(() => {
        queryDepartments()
    }, [department])

    // execute subscription
    useEffect(() => {
        subOnCreateDepartment()
        subOnUpdateDepartment()
        subOnDeleteDepartment()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query departments
    const queryDepartments = async () => {
        try {
            const departmentData = await API.graphql(graphqlOperation(listDepartmentsByCreatedDate, {
                typeCreated: "DepartmentCreated",
                sortDirection: "DESC",
            }))
            const { data: { listDepartmentsByCreatedDate: { items } } } = departmentData
            setDepartments(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create 
    const subOnCreateDepartment = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateDepartment))
                .subscribe({
                    next: ({ value }) => {
                        setDepartment(value.data.onCreateDepartment)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update 
    const subOnUpdateDepartment = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateDepartment))
                .subscribe({
                    next: ({ value }) => {
                        setDepartment(value.data.onUpdateDepartment)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete 
    const subOnDeleteDepartment = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteDepartment))
                .subscribe({
                    next: ({ value }) => {
                        setDepartment(value.data.onDeleteDepartment)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    const onFinish = async (values) => {
        try {
            values.typeCreated = "PositionCreated"
            values.key = uuidv4()
            values.positionRefs = values.positionName.toLowerCase().replace(/ /g, '-')
            setIsLoading(true)
            await API.graphql(graphqlOperation(createPosition, { input: values }))
            Notification("success", "Success", "Position create successfully!")
            setIsLoading(false)
            form.resetFields()
            inputRef.current.focus({
                cursor: "start"
            })
        } catch (error) {
            console.log(error)
        }
    };

    const onClose = () => {
        setVisible(false);
    };

    return (
        <div>
            <Button
                type="primary"
                size="large"
                onClick={() => setVisible(true)}
                // style={{ float: "right" }}
            >
                Create Position
            </Button>
            <Drawer
                title="Create Position"
                onClose={onClose}
                visible={visible}
                width="500"
            >
                {
                    isLoading ? <MySpin /> :
                        <Form form={form} ref={formRef} name="nest-messages" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <Form.Item name="departmentPositionId" label="Department" rules={[{ required: true }]}>
                                <Select
                                    size="large"
                                >
                                    {
                                        departments && departments.map((data, key) => {
                                            const { id, departmentName } = data
                                            return <Select.Option key={key} value={id}>{departmentName}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="positionName" label="Position Name" rules={[{ required: true }]}>
                                <Input size="large" {...sharedProps} />
                            </Form.Item>
                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button icon={<SaveOutlined />} block size="large" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </div>
    )
}

export default CreatePositionDrawer