import { useState, useEffect, createRef, useRef } from "react"
import { Button, Drawer, Row, Col, Typography, Form, Select, Input, Divider } from "antd"
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons"
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';

// external-component
import Notification from "../../../../components/Notification/Notification"
import Spin from "../../../../components/Spin/Spin"

// amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listUsersByCreatedDate } from "../../../../graphql/queries"

// graphql-sub
import {
    onCreateUser,
    onUpdateUser,
    onDeleteUser
} from "../../../../graphql/subscriptions"

// mutation-create
import { createProject } from "../../../../graphql/mutations"

const cookies = new Cookies();

const { Title } = Typography
const { Option } = Select;

const CreateProjectDrawer = (props) => {
    const { role, collapsed } = props
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [user, setUser] = useState(null)
    const userId = cookies.get('userId')

    const [form] = Form.useForm()
    let formRef = createRef()

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    // execute query car parts
    useEffect(() => {
        queryUsers()
    }, [user])

    // execute subscription
    useEffect(() => {
        subOnCreateUser()
        subOnUpdateUser()
        subOnDeleteUser()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query users
    const queryUsers = async () => {
        try {
            const userData = await API.graphql(graphqlOperation(listUsersByCreatedDate, {
                typeCreated: "UserCreated",
                sortDirection: "DESC"
            }))
            const { data: { listUsersByCreatedDate: { items } } } = userData
            setUsers(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create
    const subOnCreateUser = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onCreateUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update
    const subOnUpdateUser = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onUpdateUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete
    const subOnDeleteUser = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onDeleteUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    const validateMessages = {
        required: '${label} is required!'
    };

    const onFinish = async (values) => {
        try {
            values.typeCreated = "ProjectCreated"
            values.key = uuidv4()
            setIsLoading(true)
            await API.graphql(graphqlOperation(createProject, { input: values }))
            Notification("success", "Success", "Project create successfully!")
            setIsLoading(false)
            form.resetFields()
        } catch (error) {
            console.log(error)
        }
    };

    const onClose = () => {
        setVisible(false);
    };

    return (
        <div>
            {
                !collapsed ?
                    <Row gutter={24}>
                        <Col span={12}>
                            <Title level={4}>Projects</Title>
                        </Col>
                        <Col span={12}>
                            {
                                role.length > 0 && role[0].createProject === true &&
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={() => setVisible(true)}
                                    style={{ float: "right" }}
                                >
                                    Create Project
                                </Button>
                            }
                        </Col>
                    </Row> : null
            }
            <Drawer
                title="Create Project"
                placement="right"
                onClose={onClose}
                visible={visible}
                width="500"
            >
                {
                    isLoading ? <Spin /> :
                        <Form form={form} ref={formRef} name="nest-messages" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <Form.Item name="projectNumber" label="Project Number" rules={[{ required: true }]}>
                                <Input size="large" />
                            </Form.Item>
                            <Form.Item name="companyName" label="Company" rules={[{ required: true }]}>
                                <Input size="large" />
                            </Form.Item>
                            <Form.Item name="location" label="Location" rules={[{ required: true }]}>
                                <Input size="large" />
                            </Form.Item>
                            <Form.Item name="projectTitle" label="Project Title" rules={[{ required: true }]}>
                                <Input size="large" />
                            </Form.Item>
                            <Divider plain>Team Member</Divider>
                            <Form.Item name="projectManagerId" label="Project Manager">
                                <Select size="large" allowClear>
                                    {
                                        users ? users.map((data, key) =>
                                            <Select.Option key={key} value={data.id}>{data.firstName} {data.lastName}</Select.Option>
                                        ) : null
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="projectMemberId" label="Project Member">
                                <Select size="large" mode="tags" allowClear>
                                    {
                                        users ? users.map((data, key) =>
                                            <Select.Option key={key} value={data.id}>{data.firstName} {data.lastName}</Select.Option>
                                        ) : null
                                    }
                                </Select>
                            </Form.Item>
                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button icon={<SaveOutlined />} block size="large" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </div>
    )
}

export default CreateProjectDrawer