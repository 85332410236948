import { useState, useEffect, useContext } from "react"
import { Form, Select } from "antd"

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listDepartmentsByCreatedDate } from "../../../../graphql/queries"

// graphql-sub
import {
    onCreateDepartment,
    onUpdateDepartment,
    onDeleteDepartment
} from "../../../../graphql/subscriptions"

// shared-context
import { DepartmentIdContext } from "../../../../shared/context/DepartmentIdContext"

const SelectDepartment = (props) => {
    const [departments, setDepartments] = useState(null)
    const [department, setDepartment] = useState(null)
    const { form, departmentUserId } = props

    // context
    const { setDepartmentIdContext } = useContext(DepartmentIdContext)

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    useEffect(() => {
        form.setFieldsValue({
            departmentUserId: departmentUserId
        })
    }, [departmentUserId])

    // execute query 
    useEffect(() => {
        queryDepartments()
    }, [department])

    // execute subscription
    useEffect(() => {
        subOnCreateDepartment()
        subOnUpdateDepartment()
        subOnDeleteDepartment()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query departments
    const queryDepartments = async () => {
        try {
            const departmentData = await API.graphql(graphqlOperation(listDepartmentsByCreatedDate, {
                typeCreated: "DepartmentCreated",
                sortDirection: "DESC",
            }))
            const { data: { listDepartmentsByCreatedDate: { items } } } = departmentData
            setDepartments(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create 
    const subOnCreateDepartment = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateDepartment))
                .subscribe({
                    next: ({ value }) => {
                        setDepartment(value.data.onCreateDepartment)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update 
    const subOnUpdateDepartment = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateDepartment))
                .subscribe({
                    next: ({ value }) => {
                        setDepartment(value.data.onUpdateDepartment)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete 
    const subOnDeleteDepartment = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteDepartment))
                .subscribe({
                    next: ({ value }) => {
                        setDepartment(value.data.onDeleteDepartment)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // handle-select-department
    const handleSelectDepartment = (value) => {
        setDepartmentIdContext(value)
        form.resetFields(["positionUserId"])
    }

    return (
        <Form.Item name="departmentUserId" label="Department" rules={[{ required: true }]}>
            <Select
                size="large"
                onSelect={(value) => handleSelectDepartment(value)}
            >
                {
                    departments && departments.map((data, key) => {
                        const { id, departmentName } = data
                        return <Select.Option
                            key={key} value={id}>{departmentName}</Select.Option>
                    })
                }
            </Select>
        </Form.Item>
    )
}

export default SelectDepartment