import { useState, useEffect } from "react"
import moment from "moment"

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listTimetablesByCreatedDate, listTimetableDetailsByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateTimetable,
    onUpdateTimetable,
    onDeleteTimetable
} from "../../../graphql/subscriptions"

const PlanningTimeConsuming = (props) => {
    const [timetable, setTimetable] = useState([])
    const [timetables, setTimetables] = useState(null)
    const [hours, setHours] = useState(null)
    const [minutes, setMinutes] = useState(null)

    const { subElementId } = props

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    // execute query timetable
    useEffect(() => {
        queryTimetables()
    }, [subElementId, timetable])

    // execute subscription
    useEffect(() => {
        subOnCreateTimetable()
        subOnUpdateTimetable()
        subOnDeleteTimetable()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query timetable
    const queryTimetables = async () => {
        try {
            const timetableData = await API.graphql(graphqlOperation(listTimetablesByCreatedDate, {
                typeCreated: "TimetableCreated",
                sortDirection: "DESC",
                limit: 500,
                filter: {
                    subElementTimetableId: { eq: subElementId }
                }
            }))
            const { data: { listTimetablesByCreatedDate: { items } } } = timetableData
            const timetableDetailData = await API.graphql(graphqlOperation(listTimetableDetailsByCreatedDate, {
                typeCreated: "TimetableDetailCreated",
                sortDirection: "DESC",
                limit: 500,
                filter: {
                    timetableTimetableDetailId: { eq: items[0].id }
                }
            }))
            console.log(timetableDetailData)
            const { data: { listTimetableDetailsByCreatedDate: { items: timetableDetail } } } = timetableDetailData

            // const timetableData = await API.graphql(graphqlOperation(listTimetablesByCreatedDate, {
            //     typeCreated: "TimetableCreated",
            //     sortDirection: "DESC",
            //     limit: 50,
            //     filter: {
            //         subElementTimetableId: { eq: subElementId }
            //     }
            // }))


            let calculateHours = 0;
            let calculateMinutes = 0
            timetableDetail.forEach((item, _) => {
                let getTime = item.time.time
                let getHour = moment(getTime).hour()
                let getMinute = moment(getTime).minutes()
                calculateHours += getHour
                calculateMinutes += getMinute
            });
            let getTotalHour = calculateHours + Math.floor(calculateHours / 60)
            const totalHours = getTotalHour.toString().length === 1 ? `0${getTotalHour}` : getTotalHour
            setHours(totalHours ? totalHours : null)

            let getTotalMinutes = calculateMinutes % 60
            const totalMinutes = getTotalMinutes.toString().length === 1 ? `${getTotalMinutes}0` : getTotalMinutes
            setMinutes(totalMinutes ? totalMinutes : null)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create timetable
    const subOnCreateTimetable = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateTimetable))
                .subscribe({
                    next: ({ value }) => {
                        setTimetable(value.data.onCreateTimetable)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update timetable
    const subOnUpdateTimetable = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateTimetable))
                .subscribe({
                    next: ({ value }) => {
                        setTimetable(value.data.onUpdateTimetable)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete timetable
    const subOnDeleteTimetable = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteTimetable))
                .subscribe({
                    next: ({ value }) => {
                        setTimetable(value.data.onDeleteTimetable)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }
    if (hours || minutes) {
        return (
            <>
                {hours}H {minutes}mn
            </>
        )
    }
}

export default PlanningTimeConsuming