import { useEffect, useContext } from "react"
import { Table, Space } from "antd"
import moment from "moment"
import { useParams } from "react-router-dom"

// internal-component
import UpdateTimetableDrawer from "./UpdateTimetableDrawer"
import TimeTimeConsuming from "./TimetableTimeConsuming"

// external-component
import DeleteRecordDrawer from "../../../components/DeleteRecordDrawer/DeleteRecordDrawer"
import LottieLoading from "../../../components/LottieLoading/LottieLoading"
import ViewImage from "../../../components/ViewImage/ViewImage";

// apollo-client
import { useLazyQuery } from "@apollo/client"

// graphql-query
import { listTimetablesByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateTimetable,
    onUpdateTimetable,
    onDeleteTimetable,
    onCreateSubElement,
    onUpdateSubElement,
    onDeleteSubElement
} from "../../../graphql/subscriptions"

// shared-context
import { CountTotalTimetableContext } from "../../../shared/context/CountTotalTimetable"

const { Column } = Table

const TimetableDatatable = () => {
    const { projectId, elementId, userId } = useParams()

    // context
    const { setCountTotalTimetable } = useContext(CountTotalTimetableContext)

    useEffect(() => {
        loadTimetable()
    }, [projectId, elementId, userId])

    // query timetable
    const [loadTimetable, { subscribeToMore, loading, error, data }] = useLazyQuery(listTimetablesByCreatedDate, {
        variables: {
            typeCreated: "TimetableCreated",
            sortDirection: "DESC",
            limit: 500,
            filter: {
                projectTimetableId: { contains: !projectId ? "" : projectId !== "0" ? projectId : "" },
                elementTimetableId: { contains: !elementId ? "" : elementId !== "0" ? elementId : "" },
                userTimetableId: { contains: !userId ? "" : userId !== "0" ? userId : "" }
            }
        }
    })

    // on create timetable
    useEffect(() =>
        subscribeToMore({
            document: onCreateTimetable,
            updateQuery: (prev, { subscriptionData }) => {
                const { listTimetablesByCreatedDate: { items } } = prev
                if (!subscriptionData.data) return prev
                const { onCreateTimetable: timetableData } = subscriptionData.data
                return {
                    listTimetablesByCreatedDate: {
                        items: [timetableData, ...items],
                        nextToken: null,
                        __typename: "Timetable"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on update timetable
    useEffect(() =>
        subscribeToMore({
            document: onUpdateTimetable,
            updateQuery: (prev) => {
                const { listTimetablesByCreatedDate: { items } } = prev
                return {
                    listTimetablesByCreatedDate: {
                        items: [...items],
                        nextToken: null,
                        __typename: "Timetable"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on delete timetable
    useEffect(() =>
        subscribeToMore({
            document: onDeleteTimetable,
            updateQuery: (prev, { subscriptionData }) => {
                const { listTimetablesByCreatedDate: { items } } = prev
                if (subscriptionData) {
                    const { onDeleteTimetable: timetableData } = subscriptionData.data
                    const lastItems = items.filter(doc => doc.id !== timetableData.id)
                    return {
                        listTimetablesByCreatedDate: {
                            items: [...lastItems],
                            nextToken: null,
                            __typename: "Timetable"
                        }
                    }
                }
            }
        }), [subscribeToMore]
    )

    if (loading) return <LottieLoading />
    if (error) console.log(error)
    if (data) {
        const { listTimetablesByCreatedDate: { items } } = data
        setCountTotalTimetable(items.length)
        return (
            <div>
                <Table
                    bordered
                    dataSource={items}
                    size="small"
                    pagination={{
                        pageSize: 100,
                    }}
                    scroll={{ y: 500 }}
                >
                    <Column
                        title="Project"
                        dataIndex="project"
                        key="project"
                        render={(_, record) => {
                            const projectNumber = record.project ? record.project.projectNumber : ""
                            // const categoryName = record.element ? record.element.elementCategory.categoryName : ""
                            const elementName = record.element ? record.element.elementName : ""
                            const numberOfDrawing = record.subElement ? record.subElement.numberOfDrawing : ""
                            const index = record.subElement ? record.subElement.index : "i"
                            return (
                                // `${projectNumber}_${categoryName}_${numberOfDrawing}_${elementName}`
                                `${projectNumber}_${numberOfDrawing}_${elementName}`
                            )
                        }}
                    />
                    <Column
                        title="Ind"
                        dataIndex="ind"
                        key="ind"
                        align="center"
                        width={80}
                        render={(_, record) => {
                            const index = record.subElement.index ? record.subElement.index : ""
                            return (
                                `${index}`
                            )
                        }}
                    />
                    <Column
                        title="Prov"
                        dataIndex="prov"
                        key="prov"
                        align="center"
                        width={80}
                        render={(_, record) => {
                            const provNumber = record.subElement.provNumber ? record.subElement.provNumber : ""
                            const provAlphabet = record.subElement.provAlphabet ? record.subElement.provAlphabet : ""
                            return (
                                `${provNumber}${provAlphabet}`
                            )
                        }}
                    />
                    <Column
                        title="Total Time"
                        dataIndex="timeUsage"
                        key="timeUsage"
                        align="center"
                        width={150}
                        render={(_, record) => {
                            const { id } = record
                            console.log(record)
                            return <TimeTimeConsuming timetableId={id} />
                            // return <PlanningTimeConsuming subElementId={subElementPlanningId} />
                            // return (
                            //     record.time ?
                            //         moment(record.time.time).format('HH:mm') : ""
                            // )
                        }}
                    />
                    <Column
                        title="Creator"
                        dataIndex="user"
                        key="user"
                        align="center"
                        width={150}
                        render={(_, record) => {
                            const { user } = record
                            return (
                                <>
                                    {
                                        user && <>
                                            <span>{user.firstName} {user.lastName}</span>
                                        </>
                                    }
                                </>
                            )
                        }}
                    />
                    <Column
                        title="Action"
                        dataIndex="usageTime"
                        key="usageTime"
                        width={150}
                        align="center"
                        render={(_, record) => {
                            return (
                                <Space size="middle">
                                    <DeleteRecordDrawer
                                        id={record.id}
                                        module="timetable"
                                        title="Delete the Timetable?"
                                    />
                                    <UpdateTimetableDrawer data={record} />
                                </Space>
                            )
                        }}
                    />
                </Table>
            </div>
        )
    }
}

export default TimetableDatatable