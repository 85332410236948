import { useState } from "react"
import { Row, Col, Form, Typography, Divider } from "antd"

// external-components
import ViewProjectInfo from "../../components/ViewProjectInfo/ViewProjectInfo"
import ViewElementInfo from "../../components/ViewElementInfo/ViewElementInfo"
import ViewUserInfo from "../../components/ViewUserInfo/ViewUserInfo"

// internal-components
import CreateTimetable from "./components/CreateTimetableDrawer"
import TimetableDatatable from "./components/TimetableDatatable"

// internal-select-components
import SelectProject from "../../components/Select/SelectProject"
import SelectElement from "../../components/Select/SelectElement"
import SelectUser from "../../components/Select/SelectUser"

// shared-contexts
import { CountTotalTimetableProvider } from "../../shared/context/CountTotalTimetable"

const { Title } = Typography

const Timetable = () => {
    const [countTotalTimetable, setCountTotalTimetable] = useState(null)
    return (
        <div style={{ padding: 20 }}>
            <CountTotalTimetableProvider value={{ countTotalTimetable, setCountTotalTimetable }}>
                <Title level={2}>Timetable (Total : {countTotalTimetable ? countTotalTimetable : 0})</Title>
                <Title level={5}>
                    <ViewProjectInfo />
                    <ViewElementInfo />
                    <ViewUserInfo />
                </Title>
                <Divider />
                <Form layout="vertical">
                    <Row gutter={24}>
                        <Col span={5}>
                            <SelectProject path="timetable" />
                        </Col>
                        <Col span={5}>
                            <SelectElement path="timetable" />
                        </Col>
                        <Col span={5}>
                            <SelectUser path="timetable" />
                        </Col>
                        <Col span={9}>
                            <CreateTimetable />
                        </Col>
                    </Row>
                </Form>
                <TimetableDatatable />
            </CountTotalTimetableProvider>
        </div>
    )
}

export default Timetable