import { useEffect } from "react"
import { Menu } from "antd"
import { Link } from "react-router-dom"
import { AppstoreOutlined } from '@ant-design/icons'
import Cookies from 'universal-cookie';

// external-components
import LottieLoading from "../../../components/LottieLoading/LottieLoading"

// internal-components
import CountElementByProject from "./CounElementByProject";

// apollo-client
import { useLazyQuery, useQuery } from "@apollo/client"

// graphql-query
import { listProjectsByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateProject,
    onUpdateProject,
    onDeleteProject
} from "../../../graphql/subscriptions"

const cookies = new Cookies();

const ProjectListByProjectMember = ({ searchParam }) => {
    // cookies
    const userId = cookies.get('userId')

    useEffect(() => {
        loadProject()
    }, [searchParam])

    // query project
    const [loadProject, { subscribeToMore, loading, error, data }] = useLazyQuery(listProjectsByCreatedDate, {
        variables: {
            typeCreated: "ProjectCreated",
            sortDirection: "DESC",
            filter: {
                projectMemberId: { contains: !userId ? "" : userId },
                projectNumber: { contains: searchParam ? searchParam : "" }
            }
        }
    })

    // on create project
    useEffect(() =>
        subscribeToMore({
            document: onCreateProject,
            updateQuery: (prev, { subscriptionData }) => {
                const { listProjectsByCreatedDate: { items } } = prev
                if (!subscriptionData.data) return prev
                const { onCreateProject: projectData } = subscriptionData.data
                return {
                    listProjectsByCreatedDate: {
                        items: [projectData, ...items],
                        nextToken: null,
                        __typename: "Project"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on update project
    useEffect(() =>
        subscribeToMore({
            document: onUpdateProject,
            updateQuery: (prev) => {
                const { listProjectsByCreatedDate: { items } } = prev
                return {
                    listProjectsByCreatedDate: {
                        items: [...items],
                        nextToken: null,
                        __typename: "Project"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on delete project
    useEffect(() =>
        subscribeToMore({
            document: onDeleteProject,
            updateQuery: (prev, { subscriptionData }) => {
                const { listProjectsByCreatedDate: { items } } = prev
                if (subscriptionData) {
                    const { onDeleteProject: projectData } = subscriptionData.data
                    const lastItems = items.filter(doc => doc.id !== projectData.id)
                    return {
                        listProjectsByCreatedDate: {
                            items: [...lastItems],
                            nextToken: null,
                            __typename: "Project"
                        }
                    }
                }
            }
        }), [subscribeToMore]
    )

    if (loading) {
        return (
            <Menu mode="inline">
                <LottieLoading />
            </Menu>
        )
    }
    if (error) console.log(error)
    if (data) {
        const { listProjectsByCreatedDate: { items } } = data
        return (
            <Menu mode="inline">
                {
                    items.length > 0 && items.map((item, index) => {
                        const { id, projectNumber, companyName, location, projectTitle } = item
                        return (
                            <Menu.Item key={index} icon={
                                <AppstoreOutlined style={{ marginTop: -60, fontSize: 18 }} />
                            } style={{ height: 90, paddingLeft: 15, paddingTop: 20 }}>
                                <Link to={`/${id}`}>
                                    <span style={{ fontSize: 16, fontWeight: "bold" }}>
                                        <CountElementByProject projectId={id} /> {projectNumber}
                                    </span>
                                    <p>{companyName}_{location}_{projectTitle}</p>
                                </Link>
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
        )
    }
}

export default ProjectListByProjectMember