import { Timeline } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const TechnicalRole = ({ item }) => {
    const { technicalPrivilege } = item

    return (
        <Timeline>
            <Timeline.Item
                color={technicalPrivilege ? "green" : "red"}
                dot={technicalPrivilege ? <CheckCircleFilled /> : <CloseCircleFilled />}
                style={{ marginBottom: -30 }}
            >
                <span className="role-child">Technical</span>
            </Timeline.Item>
        </Timeline>
    )
}

export default TechnicalRole