import { useState, useEffect, createRef } from "react"
import { Form, Row, Col, Button, DatePicker, Drawer, Typography, Card, Divider } from "antd"
import { API, graphqlOperation } from "aws-amplify"
import { SaveOutlined, CloseCircleOutlined, EditOutlined } from "@ant-design/icons"
import moment from "moment"

// external-component
import MySpin from "../../../components/Spin/Spin"
import Notification from "../../../components/Notification/Notification"

// graphql-query
import {
    listProjectsByCreatedDate,
    listElementsByCreatedDate,
    listSubElementsByCreatedDate
} from "../../../graphql/queries"

// graphql-sub
import {
    onCreateProject,
    onUpdateProject,
    onDeleteProject,
    onCreateElement,
    onUpdateElement,
    onDeleteElement,
    onCreateSubElement,
    onUpdateSubElement,
    onDeleteSubElement,
} from "../../../graphql/subscriptions"

// mutation-create
import { updatePlanning } from "../../../graphql/mutations"

const { Title, Text } = Typography

const UpdatePlanningDrawer = ({ data }) => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [projects, setProjects] = useState(null)
    const [project, setProject] = useState(null)
    const [elements, setElements] = useState(null)
    const [element, setElement] = useState(null)
    const [subElements, setSubElements] = useState(null)
    const [subElement, setSubElement] = useState(null)
    const [projectId, setProjectId] = useState(null)
    const [elementId, setElementId] = useState(null)
    const [users, setUsers] = useState(null)

    const [form] = Form.useForm()
    let formRef = createRef();

    const {
        id,
        projectPlanningId,
        elementPlanningId,
        subElementPlanningId,
        planningDate,
    } = data

    // project
    let subscriptionProjectOnCreate;
    let subscriptionProjectOnDelete;
    let subscriptionProjectOnUpdate;

    // element
    let subscriptionElementOnCreate;
    let subscriptionElementOnDelete;
    let subscriptionElementOnUpdate;

    // sub-element
    let subscriptionSubElementOnCreate;
    let subscriptionSubElementOnDelete;
    let subscriptionSubElementOnUpdate;

    // execute query project
    useEffect(() => {
        queryProjects()
    }, [project])

    // execute subscription project
    useEffect(() => {
        subOnCreateProject()
        subOnUpdateProject()
        subOnDeleteProject()
        return () => {
            subscriptionProjectOnCreate.unsubscribe()
            subscriptionProjectOnUpdate.unsubscribe()
            subscriptionProjectOnDelete.unsubscribe()
        }
    }, [])

    // query projects
    const queryProjects = async () => {
        try {
            const projectData = await API.graphql(graphqlOperation(listProjectsByCreatedDate, {
                typeCreated: "ProjectCreated",
                filter: {
                    id: { eq: projectPlanningId }
                }
            }))
            const { data: { listProjectsByCreatedDate: { items } } } = projectData
            setProjects(items[0])
        } catch (error) {
            console.error(error)
        }
    }

    // sub create project
    const subOnCreateProject = async () => {
        try {
            subscriptionProjectOnCreate = await API.graphql(graphqlOperation(onCreateProject))
                .subscribe({
                    next: ({ value }) => {
                        setProject(value.data.onCreateProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update project
    const subOnUpdateProject = async () => {
        try {
            subscriptionProjectOnUpdate = await API.graphql(graphqlOperation(onUpdateProject))
                .subscribe({
                    next: ({ value }) => {
                        setProject(value.data.onUpdateProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete project
    const subOnDeleteProject = async () => {
        try {
            subscriptionProjectOnDelete = await API.graphql(graphqlOperation(onDeleteProject))
                .subscribe({
                    next: ({ value }) => {
                        setProject(value.data.onDeleteProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // execute query element
    useEffect(() => {
        queryElements()
    }, [element, projectId])

    // execute subscription element
    useEffect(() => {
        subOnCreateElement()
        subOnUpdateElement()
        subOnDeleteElement()
        return () => {
            subscriptionElementOnCreate.unsubscribe()
            subscriptionElementOnUpdate.unsubscribe()
            subscriptionElementOnDelete.unsubscribe()
        }
    }, [])

    // query elements element
    const queryElements = async () => {
        try {
            const elementData = await API.graphql(graphqlOperation(listElementsByCreatedDate, {
                typeCreated: "ElementCreated",
                filter: {
                    id: { eq: elementPlanningId }
                }
            }))
            const { data: { listElementsByCreatedDate: { items } } } = elementData
            setElements(items[0])
        } catch (error) {
            console.error(error)
        }
    }

    // sub create element
    const subOnCreateElement = async () => {
        try {
            subscriptionElementOnCreate = await API.graphql(graphqlOperation(onCreateElement))
                .subscribe({
                    next: ({ value }) => {
                        setElement(value.data.onCreateElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update element
    const subOnUpdateElement = async () => {
        try {
            subscriptionElementOnUpdate = await API.graphql(graphqlOperation(onUpdateElement))
                .subscribe({
                    next: ({ value }) => {
                        setElement(value.data.onUpdateElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete elemet
    const subOnDeleteElement = async () => {
        try {
            subscriptionElementOnDelete = await API.graphql(graphqlOperation(onDeleteElement))
                .subscribe({
                    next: ({ value }) => {
                        setElement(value.data.onDeleteElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // execute query sub-element
    useEffect(() => {
        querySubElements()
    }, [subElement, elementId])

    // execute subscription sub-element
    useEffect(() => {
        subOnCreateSubElement()
        subOnUpdateSubElement()
        subOnDeleteSubElement()
        return () => {
            subscriptionSubElementOnCreate.unsubscribe()
            subscriptionSubElementOnUpdate.unsubscribe()
            subscriptionSubElementOnDelete.unsubscribe()
        }
    }, [])

    // query elements sub-element
    const querySubElements = async () => {
        try {
            const subElementData = await API.graphql(graphqlOperation(listSubElementsByCreatedDate, {
                typeCreated: "SubElementCreated",
                filter: {
                    id: { eq: subElementPlanningId }
                }
            }))
            const { data: { listSubElementsByCreatedDate: { items } } } = subElementData
            setSubElements(items[0])
        } catch (error) {
            console.error(error)
        }
    }

    // sub create sub-element
    const subOnCreateSubElement = async () => {
        try {
            subscriptionSubElementOnCreate = await API.graphql(graphqlOperation(onCreateSubElement))
                .subscribe({
                    next: ({ value }) => {
                        setSubElement(value.data.onCreateSubElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update sub-element
    const subOnUpdateSubElement = async () => {
        try {
            subscriptionSubElementOnUpdate = await API.graphql(graphqlOperation(onUpdateSubElement))
                .subscribe({
                    next: ({ value }) => {
                        setElement(value.data.onUpdateSubElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete sub-elemet
    const subOnDeleteSubElement = async () => {
        try {
            subscriptionSubElementOnDelete = await API.graphql(graphqlOperation(onDeleteSubElement))
                .subscribe({
                    next: ({ value }) => {
                        setElement(value.data.onDeleteSubElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    const onClose = () => {
        setVisible(false);
    };

    const onFinish = async (values) => {
        try {
            values.id = id
            values.status = "in-progress"
            setIsLoading(true)
            await API.graphql(graphqlOperation(updatePlanning, { input: values }))
            Notification("success", "Success", "Planning update successfully!")
            setIsLoading(false)
            onClose()
        } catch (error) {
            console.log(error)
        }
    };

    const validateMessages = {
        required: '${label} is required!'
    };

    return (
        <div>
            <Button
                type="primary"
                icon={<EditOutlined />}
                style={{ float: "right" }}
                onClick={() => setVisible(true)}
            />
            <Drawer
                title="Update Planning"
                onClose={onClose}
                visible={visible}
                width="500"
            >
                {
                    isLoading ? <MySpin /> :
                        <Form
                            form={form}
                            ref={formRef}
                            name="nest-messages"
                            layout="vertical"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                            fields={[
                                {
                                    name: ["planningDate"],
                                    value: planningDate ? moment(planningDate) : null
                                }
                            ]}
                        >
                            <Card style={{ marginBottom: 40 }}>
                                <Title level={5}>Project</Title>
                                <Text className="user-info-label">
                                    {
                                        projects && projects.projectNumber
                                    }
                                </Text>
                                <Divider />
                                <Title level={5}>Element</Title>
                                <Text className="user-info-label">
                                    {
                                        elements && elements.elementName
                                    }
                                </Text>
                                <Divider />
                                <Title level={5}>Number of Drawring</Title>
                                <Text className="user-info-label">
                                    {
                                        subElements && subElements.numberOfDrawing
                                    }
                                </Text>
                            </Card>
                            <Form.Item name="planningDate" label="Planning Date" rules={[{ required: true }]} style={{ marginTop: -20 }}>
                                <DatePicker size="large" style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                            </Form.Item>
                            <Form.Item>
                                <Row gutter={24} style={{ marginTop: 40 }}>
                                    <Col span={12}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button icon={<SaveOutlined />} block size="large" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </div >
    )
}

export default UpdatePlanningDrawer