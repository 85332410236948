import { useState, useEffect, useContext } from "react"
import { Form, Select } from "antd"

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// graphql-query
import { listPositionsByCreatedDate } from "../../../../graphql/queries"

// graphql-sub
import {
    onCreatePosition,
    onUpdatePosition,
    onDeletePosition
} from "../../../../graphql/subscriptions"

// shared-context
import { DepartmentIdContext } from "../../../../shared/context/DepartmentIdContext"

const SelectPosition = (props) => {
    const [positions, setPositions] = useState(null)
    const [position, setPosition] = useState(null)
    const { form, positionUserId, departmentUserId } = props

    // context
    const { departmentIdContext } = useContext(DepartmentIdContext)

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    useEffect(() => {
        form.setFieldsValue({
            positionUserId: positionUserId
        })
    }, [positionUserId])

    // execute query 
    useEffect(() => {
        queryPositions()
    }, [position, departmentIdContext])

    // execute subscription
    useEffect(() => {
        subOnCreatePosition()
        subOnUpdatePosition()
        subOnDeletePosition()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query positions
    const queryPositions = async () => {
        try {
            const positionData = await API.graphql(graphqlOperation(listPositionsByCreatedDate, {
                typeCreated: "PositionCreated",
                sortDirection: "DESC",
                filter: {
                    departmentPositionId: { eq: departmentIdContext ? departmentIdContext : departmentUserId }
                }
            }))
            const { data: { listPositionsByCreatedDate: { items } } } = positionData
            console.log(positionData)
            setPositions(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create 
    const subOnCreatePosition = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreatePosition))
                .subscribe({
                    next: ({ value }) => {
                        setPosition(value.data.onCreatePosition)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update 
    const subOnUpdatePosition = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdatePosition))
                .subscribe({
                    next: ({ value }) => {
                        setPosition(value.data.onUpdatePosition)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete 
    const subOnDeletePosition = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeletePosition))
                .subscribe({
                    next: ({ value }) => {
                        setPosition(value.data.onDeletePosition)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Form.Item name="positionUserId" label="Position" rules={[{ required: true }]}>
            <Select size="large">
                {
                    positions && positions.map((data, key) => {
                        const { id, positionName } = data
                        return <Select.Option key={key} value={id}>{positionName}</Select.Option>
                    })
                }
            </Select>
        </Form.Item>
    )
}

export default SelectPosition