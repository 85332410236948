import RoleDatatable from "./components/RoleDatatable"
import RoleList from "./components/RoleList"

const Role = () => {
    return (
        // <RoleDatatable />
        <RoleList />
    )
}

export default Role