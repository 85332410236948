import { useEffect } from "react"
import { useParams } from "react-router-dom"

// apollo-client
import { useLazyQuery } from "@apollo/client"

// graphql-query
import { listProjects } from "../../graphql/queries"

// graphql-sub
import { onUpdateProject } from "../../graphql/subscriptions"

const ViewProjectInfo = () => {
    const { projectId } = useParams()

    useEffect(() => {
        if (projectId) {
            loadProject()
        }
    }, [projectId])

    // query project
    const [loadProject, { subscribeToMore, loading, error, data }] = useLazyQuery(listProjects, {
        variables: {
            filter: {
                id: { eq: projectId ? projectId : null }
            }
        }
    })

    // on update project
    useEffect(() =>
        subscribeToMore({
            document: onUpdateProject,
            updateQuery: (prev) => {
                const { listProjects: { items } } = prev
                return {
                    listProjects: {
                        items: [...items],
                        nextToken: null,
                        __typename: "Project"
                    }
                }
            }
        }), [subscribeToMore]
    )

    if (loading) return <div>...</div>
    if (error) console.log(error)
    if (data) {
        const { listProjects: { items } } = data
        return (
            <span>
                {
                    items.length > 0 &&
                    <>
                        Project: <b>{items[0].projectNumber}</b>
                    </>
                }
            </span>
        )
    }
}

export default ViewProjectInfo