import { Timeline, Col, Typography } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const { Title } = Typography

const ElementCategoryRole = ({ item }) => {
    const {
        // elementCategoryPrivilege,
        readElementCategory,
        createElementCategory,
        updateElementCategory,
        deleteElementCategory
    } = item

    return (
        <Col span={10}>
            <Title level={5} className="timeline-header">Element Category</Title>
            <Timeline>
                <Timeline.Item
                    color={readElementCategory ? "green" : "red"}
                    dot={readElementCategory ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read
                </Timeline.Item>
                <Timeline.Item
                    color={createElementCategory ? "green" : "red"}
                    dot={createElementCategory ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Create
                </Timeline.Item>
                <Timeline.Item
                    color={updateElementCategory ? "green" : "red"}
                    dot={updateElementCategory ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Update
                </Timeline.Item>
                <Timeline.Item
                    color={deleteElementCategory ? "green" : "red"}
                    dot={deleteElementCategory ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Delete
                </Timeline.Item>
            </Timeline>
        </Col>
    )
}

export default ElementCategoryRole