import { useState, useEffect } from "react"
import { Form, Select, Row, Col, Button, Drawer, Timeline } from "antd"
import { API, graphqlOperation } from "aws-amplify"
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import Cookies from 'universal-cookie';

// external-component
import MySpin from "../../../components/Spin/Spin"
import Notification from "../../../components/Notification/Notification"

// graphql-query
import {
    listProjectsByCreatedDate,
    listElementsByCreatedDate,
    listSubElementsByCreatedDate,
    listTimesByCreatedDate,
    listPlanningsByCreatedDate
} from "../../../graphql/queries"

// graphql-sub
import {
    onCreateProject,
    onUpdateProject,
    onDeleteProject,
    onCreateElement,
    onUpdateElement,
    onDeleteElement,
    onCreateSubElement,
    onUpdateSubElement,
    onDeleteSubElement,
    onCreateTime,
    onUpdateTime,
    onDeleteTime
} from "../../../graphql/subscriptions"

// mutation-create
import { createTimetableDetail, createTimetable } from "../../../graphql/mutations"

const cookies = new Cookies();

const CreateTimetableDrawer = () => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [projects, setProjects] = useState(null)
    const [project, setProject] = useState(null)
    const [elements, setElements] = useState(null)
    const [element, setElement] = useState(null)
    const [subElements, setSubElements] = useState(null)
    const [subElement, setSubElement] = useState(null)
    const [times, setTimes] = useState(null)
    const [time, setTime] = useState(null)
    const [projectId, setProjectId] = useState(null)
    const [elementId, setElementId] = useState(null)

    // cookies
    const userId = cookies.get('userId')

    // project
    let subscriptionProjectOnCreate;
    let subscriptionProjectOnDelete;
    let subscriptionProjectOnUpdate;

    // element
    let subscriptionElementOnCreate;
    let subscriptionElementOnDelete;
    let subscriptionElementOnUpdate;

    // sub-element
    let subscriptionSubElementOnCreate;
    let subscriptionSubElementOnDelete;
    let subscriptionSubElementOnUpdate;

    // sub-time
    let subscriptionTimeOnCreate;
    let subscriptionTimeOnDelete;
    let subscriptionTimeOnUpdate;

    // execute query project
    useEffect(() => {
        queryProjects()
    }, [project])

    // execute subscription project
    useEffect(() => {
        subOnCreateProject()
        subOnUpdateProject()
        subOnDeleteProject()
        return () => {
            subscriptionProjectOnCreate.unsubscribe()
            subscriptionProjectOnUpdate.unsubscribe()
            subscriptionProjectOnDelete.unsubscribe()
        }
    }, [])

    // query projects
    const queryProjects = async () => {
        try {
            const projectData = await API.graphql(graphqlOperation(listProjectsByCreatedDate, {
                typeCreated: "ProjectCreated",
                sortDirection: "DESC",
            }))
            const { data: { listProjectsByCreatedDate: { items } } } = projectData
            setProjects(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create project
    const subOnCreateProject = async () => {
        try {
            subscriptionProjectOnCreate = await API.graphql(graphqlOperation(onCreateProject))
                .subscribe({
                    next: ({ value }) => {
                        setProject(value.data.onCreateProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update project
    const subOnUpdateProject = async () => {
        try {
            subscriptionProjectOnUpdate = await API.graphql(graphqlOperation(onUpdateProject))
                .subscribe({
                    next: ({ value }) => {
                        setProject(value.data.onUpdateProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete project
    const subOnDeleteProject = async () => {
        try {
            subscriptionProjectOnDelete = await API.graphql(graphqlOperation(onDeleteProject))
                .subscribe({
                    next: ({ value }) => {
                        setProject(value.data.onDeleteProject)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // execute query element
    useEffect(() => {
        queryElements()
    }, [element, projectId])

    // execute subscription element
    useEffect(() => {
        subOnCreateElement()
        subOnUpdateElement()
        subOnDeleteElement()
        return () => {
            subscriptionElementOnCreate.unsubscribe()
            subscriptionElementOnUpdate.unsubscribe()
            subscriptionElementOnDelete.unsubscribe()
        }
    }, [])

    // query elements element
    const queryElements = async () => {
        try {
            if (projectId) {
                const elementData = await API.graphql(graphqlOperation(listElementsByCreatedDate, {
                    typeCreated: "ElementCreated",
                    sortDirection: "DESC",
                    filter: { projectElementId: { eq: projectId } },
                    limit: 500
                }))
                const { data: { listElementsByCreatedDate: { items } } } = elementData
                setElements(items)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // sub create element
    const subOnCreateElement = async () => {
        try {
            subscriptionElementOnCreate = await API.graphql(graphqlOperation(onCreateElement))
                .subscribe({
                    next: ({ value }) => {
                        setElement(value.data.onCreateElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update element
    const subOnUpdateElement = async () => {
        try {
            subscriptionElementOnUpdate = await API.graphql(graphqlOperation(onUpdateElement))
                .subscribe({
                    next: ({ value }) => {
                        setElement(value.data.onUpdateElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete elemet
    const subOnDeleteElement = async () => {
        try {
            subscriptionElementOnDelete = await API.graphql(graphqlOperation(onDeleteElement))
                .subscribe({
                    next: ({ value }) => {
                        setElement(value.data.onDeleteElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // execute query sub-element
    useEffect(() => {
        querySubElements()
    }, [subElement, elementId])

    // execute subscription sub-element
    useEffect(() => {
        subOnCreateSubElement()
        subOnUpdateSubElement()
        subOnDeleteSubElement()
        return () => {
            subscriptionSubElementOnCreate.unsubscribe()
            subscriptionSubElementOnUpdate.unsubscribe()
            subscriptionSubElementOnDelete.unsubscribe()
        }
    }, [])

    // query elements sub-element
    const querySubElements = async () => {
        try {
            if (elementId) {
                // const planningData = await API.graphql(graphqlOperation(listPlanningsByCreatedDate, {
                //     typeCreated: "PlanningCreated",
                //     sortDirection: "DESC",
                //     filter: {
                //         // status: { eq: "in-progress" },
                //         elementPlanningId: { eq: elementId }
                //     }
                // }))
                const subElementData = await API.graphql(graphqlOperation(listSubElementsByCreatedDate, {
                    typeCreated: "SubElementCreated",
                    sortDirection: "DESC",
                    filter: { elementSubElementId: { eq: elementId } },
                    limit: 500
                }))
                const { data: { listSubElementsByCreatedDate: { items } } } = subElementData
                setSubElements(items)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // sub create sub-element
    const subOnCreateSubElement = async () => {
        try {
            subscriptionSubElementOnCreate = await API.graphql(graphqlOperation(onCreateSubElement))
                .subscribe({
                    next: ({ value }) => {
                        setSubElement(value.data.onCreateSubElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update sub-element
    const subOnUpdateSubElement = async () => {
        try {
            subscriptionSubElementOnUpdate = await API.graphql(graphqlOperation(onUpdateSubElement))
                .subscribe({
                    next: ({ value }) => {
                        setElement(value.data.onUpdateSubElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete sub-elemet
    const subOnDeleteSubElement = async () => {
        try {
            subscriptionSubElementOnDelete = await API.graphql(graphqlOperation(onDeleteSubElement))
                .subscribe({
                    next: ({ value }) => {
                        setElement(value.data.onDeleteSubElement)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // execute query time
    useEffect(() => {
        queryTimes()
    }, [time])

    // execute subscription project
    useEffect(() => {
        subOnCreateTime()
        subOnUpdateTime()
        subOnDeleteTime()
        return () => {
            subscriptionTimeOnCreate.unsubscribe()
            subscriptionTimeOnUpdate.unsubscribe()
            subscriptionTimeOnDelete.unsubscribe()
        }
    }, [])

    // query times
    const queryTimes = async () => {
        try {
            const timeData = await API.graphql(graphqlOperation(listTimesByCreatedDate, {
                typeCreated: "TimeCreated",
                sortDirection: "DESC",
            }))
            const { data: { listTimesByCreatedDate: { items } } } = timeData
            setTimes(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create time
    const subOnCreateTime = async () => {
        try {
            subscriptionTimeOnCreate = await API.graphql(graphqlOperation(onCreateTime))
                .subscribe({
                    next: ({ value }) => {
                        setTime(value.data.onCreateTime)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update time
    const subOnUpdateTime = async () => {
        try {
            subscriptionTimeOnUpdate = await API.graphql(graphqlOperation(onUpdateTime))
                .subscribe({
                    next: ({ value }) => {
                        setTime(value.data.onUpdateTime)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete project
    const subOnDeleteTime = async () => {
        try {
            subscriptionTimeOnDelete = await API.graphql(graphqlOperation(onDeleteTime))
                .subscribe({
                    next: ({ value }) => {
                        setTime(value.data.onDeleteTime)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    const [form] = Form.useForm()

    const onClose = () => {
        setVisible(false);
    };

    const onFinish = async (values) => {
        try {
            values.key = uuidv4()
            values.typeCreated = "TimetableCreated"
            values.userTimetableId = userId
            setIsLoading(true)
            await API.graphql(graphqlOperation(createTimetable, { input: values }))
            Notification("success", "Success", "Timetable create successfully!")
            setIsLoading(false)
            form.resetFields()
        } catch (error) {
            console.log(error)
        }
    };

    const validateMessages = {
        required: '${label} is required!'
    };

    const handleSelectProject = (value) => {
        setProjectId(value)
        form.resetFields(["elementTimetableId"])
        form.resetFields(["subElementTimetableId"])
    }

    const handleSelectElement = (value) => {
        setElementId(value)
        form.resetFields(["subElementTimetableId"])
    }

    return (
        <div>
            <Button
                type="primary"
                size="large"
                onClick={() => setVisible(true)}
                style={{ float: "right", marginTop: 30 }}
            >
                Create Timetable
            </Button>
            <Drawer
                title="Create Timetable"
                onClose={onClose}
                visible={visible}
                width="1200"
            >
                {
                    isLoading ? <MySpin /> :
                        <Form form={form} name="nest-messages" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <Timeline>
                                <Timeline.Item>
                                    <Form.Item name="projectTimetableId" label="Project" rules={[{ required: true }]}>
                                        <Select
                                            showSearch
                                            size="large"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onSelect={(value) => handleSelectProject(value)}
                                        >
                                            {
                                                projects && projects.map((item, key) => {
                                                    const { id, projectNumber } = item
                                                    return <Select.Option key={key} value={id}>{projectNumber}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Timeline.Item>
                                <Timeline.Item>
                                    <Form.Item name="elementTimetableId" label="Element" rules={[{ required: true }]}>
                                        <Select
                                            showSearch
                                            size="large"
                                            onSelect={(value) => handleSelectElement(value)}
                                        >
                                            {
                                                elements && elements.map((item, key) => {
                                                    const { id, elementName, elementCategory: { categoryName } } = item
                                                    return <Select.Option key={key} value={id}>{categoryName}-{elementName}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Timeline.Item>
                                <Timeline.Item>
                                    <Form.Item name="subElementTimetableId" label="Sub Element(Number of Drawing(Prov))" rules={[{ required: true }]}>
                                        <Select
                                            showSearch
                                            size="large"
                                        >
                                            {
                                                subElements && subElements.map((item, key) => {
                                                    // const { subElement: { id, numberOfDrawing, provNumber, provAlphabet } } = item
                                                    const { id, numberOfDrawing, provNumber, provAlphabet } = item
                                                    const provAlpha = provAlphabet ? provAlphabet : null
                                                    return <Select.Option key={key} value={id}>{numberOfDrawing}-({provNumber}{provAlpha})</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Timeline.Item>
                            </Timeline>
                            <Form.Item name="timeTimetableId" label="Time" rules={[{ required: true }]} style={{ marginTop: -20 }}>
                                <Select
                                    showSearch
                                    size="large"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onSelect={(value) => setElementId(value)}
                                >
                                    {
                                        times && times.map((item, key) => {
                                            const { id, time } = item
                                            return <Select.Option key={key} value={id}>{moment(time).format("HH:mm")}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Row gutter={24} style={{ marginTop: 40 }}>
                                    <Col span={12}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button icon={<SaveOutlined />} block size="large" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </div>
    )
}

export default CreateTimetableDrawer