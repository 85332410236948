import { Timeline, Col, Typography } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const { Title } = Typography

const TimetableRole = ({ item }) => {
    const {
        readAllTimetable,
        readOwnTimetable,
        createTimetable,
        updateTimetable,
        deleteTimetable
    } = item

    return (
        <Col span={4}>
            <Title level={5} className="timeline-header">Timetable</Title>
            <Timeline>
                <Timeline.Item
                    color={readAllTimetable ? "green" : "red"}
                    dot={readAllTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read All Timetable
                </Timeline.Item>
                <Timeline.Item
                    color={readOwnTimetable ? "green" : "red"}
                    dot={readOwnTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Read Own Timetable
                </Timeline.Item>
                <Timeline.Item
                    color={createTimetable ? "green" : "red"}
                    dot={createTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Create
                </Timeline.Item>
                <Timeline.Item
                    color={updateTimetable ? "green" : "red"}
                    dot={updateTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Update
                </Timeline.Item>
                <Timeline.Item
                    color={deleteTimetable ? "green" : "red"}
                    dot={deleteTimetable ? <CheckCircleFilled /> : <CloseCircleFilled />}
                >
                    Delete
                </Timeline.Item>
            </Timeline>
        </Col>
    )
}

export default TimetableRole