import { useState, createRef, useRef, useEffect } from "react"
import { Form, Input, Button, Row, Col, Upload, message, Image, Tooltip, Alert } from "antd"
import { v4 as uuidv4 } from 'uuid';
import { UploadOutlined, CloseOutlined } from '@ant-design/icons'
import Notification from "../../../../components/Notification/Notification"
import Cookies from 'universal-cookie';
import { useLocation } from "react-router-dom"

// external-component
import MySpin from "../../../../components/Spin/Spin";

// component
import VerifyEmailModal from "./component/VerifyEmailModal";

// aws-amplify
import { API, graphqlOperation, Auth, Storage } from "aws-amplify"

// mutation-create
import { createUser } from "../../../../graphql/mutations"

// graphql-query
import { listRoles } from "../../../../graphql/queries"

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
};
const cookies = new Cookies();

const RegisterForm = () => {
    const [roleId, setRoleId] = useState([])
    const [visibleModal, setVisibleModal] = useState(false)
    const [fileList, setFileList] = useState([]);
    const [checkPassword, setCheckPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [checkConfirmPassword, setCheckConfirmPassword] = useState(true)
    const [checkPasswordLength, setCheckPasswordLength] = useState(true)
    const [checkExistEmail, setCheckExistEmail] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation()
    const { pathname } = location

    const [form] = Form.useForm()
    let formRef = createRef();
    const inputRef = useRef(null);

    useEffect(() => {
        normFile()
    }, [fileList])

    useEffect(() => {
        queryRoles()
    }, [])

    const queryRoles = async () => {
        try {
            const roleDatas = await API.graphql(graphqlOperation(listRoles, {
                filter: {
                    roleRefs: { eq: "super-admin" }
                }
            }))
            const { data: { listRoles: { items } } } = roleDatas
            setRoleId(items[0].id)
        } catch (err) {
            console.log(err)
        }
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        if (e && e.fileList.length > 1) {
            e.fileList.shift()
        }
        return e && e.fileList
    };

    const onFinish = async (values) => {
        const { email, password, avatar } = values
        try {
            const originFileObj = avatar[0].originFileObj
            const splitFileName = originFileObj.name.split(".")
            const fileName = uuidv4() + "." + splitFileName[1]
            delete values.confirmPassword
            delete values.password
            values.avatar = fileName
            values.typeCreated = "UserCreated"
            values.key = uuidv4()
            setIsLoading(true)
            await Auth.signUp({ username: email, password, attributes: { email } })
            await Storage.put(`avatars/${fileName}`, originFileObj)
            if (pathname === "/root") {
                if (roleId) {
                    values.roleUserId = roleId
                    await API.graphql(graphqlOperation(createUser, { input: values }))
                }
            } else {
                await API.graphql(graphqlOperation(createUser, { input: values }))
            }
            cookies.set('userEmail', email, { path: '/' });
            cookies.set('userPassword', password, { path: '/' });
            setIsLoading(false)
            setVisibleModal(true)
        } catch (err) {
            const { code } = err
            console.log(err)
            if (code === "UsernameExistsException") {
                setIsLoading(false)
                setCheckExistEmail(true)
            }
        }
    };

    const handleVisibleModal = (visible) => {
        setVisibleModal(visible)
    }

    const handleRemove = (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        newFileList.map((data) => {
            form.setFieldsValue({ logo: data });
        })
        form.setFieldsValue({ logo: newFileList });
        setFileList(newFileList)
    }

    const uploadProps = {
        beforeUpload: file => {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error('You can only upload JPG/PNG file!');
            }
            const isLt2M = file.size / 1024 / 1024 < 1;
            if (!isLt2M) {
                message.error('Image must smaller than 1MB!');
            }
            if (isJpgOrPng && isLt2M) {
                setFileList([file]);
            }
            return false
        },
        fileList
    };

    const uploadButton = (
        <div>
            <UploadOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Row gutter={24}>
            <Col span={20}>
                {
                    isLoading ? <MySpin /> :
                        <Form
                            name="nest-messages"
                            layout="vertical"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                        >
                            <Row gutter={100}>
                                <Col span={12}>
                                    <Form.Item name={'firstName'} label="First Name" rules={[{ required: true }]}>
                                        <Input
                                            size="large"
                                        />
                                    </Form.Item>
                                    <Form.Item name={'lastName'} label="Last Name" rules={[{ required: true }]}>
                                        <Input
                                            size="large"
                                        />
                                    </Form.Item>
                                    <Form.Item name={'email'} label="Email" rules={[{ type: 'email', required: true }]}>
                                        <Input
                                            size="large"
                                        />
                                    </Form.Item>
                                    <Tooltip placement="rightBottom" title="Password must be at least 8 characters.">
                                        <Form.Item name={'password'} label="Password" rules={[{ required: true }]}>
                                            <Input.Password
                                                size="large"
                                                onChange={(e) => {
                                                    const { value } = e.target
                                                    if (value.length >= 8) {
                                                        setCheckPassword(value)
                                                        setCheckPasswordLength(true)
                                                    } else {
                                                        setCheckPassword("")
                                                        setCheckPasswordLength(false)
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                    <Tooltip placement="rightBottom" title="Password must be at least 8 characters.">
                                        <Form.Item name={'confirmPassword'} label="Confirm Password" rules={[{ required: true }]}>
                                            <Input.Password
                                                size="large"
                                                onChange={(e) => {
                                                    const { value } = e.target
                                                    if (value.length >= 8) {
                                                        setCheckPasswordLength(true)
                                                        if (value !== checkPassword) {
                                                            setCheckConfirmPassword(false)
                                                        } else {
                                                            setCheckConfirmPassword(true)
                                                        }
                                                    } else {
                                                        setConfirmPassword("")
                                                        setCheckPasswordLength(false)
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                    {
                                        !checkConfirmPassword &&
                                        <Alert
                                            message="Error"
                                            description="Confirm password is not match."
                                            type="error"
                                            showIcon
                                            style={{ marginBottom: 20 }}
                                        />
                                    }
                                    {
                                        !checkPasswordLength &&
                                        <Alert
                                            // message="Error"
                                            description="Password must be at least 8 characters."
                                            type="warning"
                                            showIcon
                                            style={{ marginBottom: 20 }}
                                        />
                                    }
                                    {
                                        checkExistEmail &&
                                        <Alert
                                            description="An account with the given email already exists."
                                            type="error"
                                            showIcon
                                            style={{ marginBottom: 20 }}
                                        />
                                    }

                                    <Form.Item>
                                        {/* htmlType="submit" */}
                                        <Button block size="large" type="primary" htmlType="submit">
                                            Register
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="avatar"
                                        label="Profile Picture"
                                        getValueFromEvent={normFile}
                                        valuePropName="file"
                                        rules={[{ required: true }]}
                                    >
                                        <Upload
                                            {...uploadProps}
                                            listType="picture-card"
                                            showUploadList={false}
                                        >
                                            {uploadButton}
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item>
                                        <div>
                                            {
                                                fileList.map((file, index) => {
                                                    const src = URL.createObjectURL(file)
                                                    return (
                                                        <div key={index} className="banner-image-wrapper">
                                                            <Image src={src} key={index} style={{ height: 130 }} />
                                                            <Button
                                                                shape="circle"
                                                                icon={<CloseOutlined />}
                                                                onClick={() => handleRemove(file)}
                                                                className="delete-button"
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <VerifyEmailModal
                                visibleModal={visibleModal}
                                handleVisibleModal={handleVisibleModal}
                            />
                        </Form>
                }
            </Col>
        </Row>
    )
}

export default RegisterForm