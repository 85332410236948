import { useState, useEffect } from "react"
import { Space, Col, Row, Typography, Divider, Collapse, Button } from "antd"
import { DeleteOutlined } from '@ant-design/icons';
import "./RoleList.less"

// internal-component
import CreateRoleDrawer from "./CreateRoleDrawer";
import UpdateRoleDrawer from "./UpdateRoleDrawer";
import DeleteRoleDrawer from "./DeleteRoleDrawer";

// external-component
import LottieLoading from "../../../components/LottieLoading/LottieLoading";

// internal-components
import ProjectRole from "./RoleGroup/ProjectRole";
import ElementRole from "./RoleGroup/ElementRole";
import SubElementRole from "./RoleGroup/SubElementRole";
import PlanningRole from "./RoleGroup/PlanningRole";
import TimetableRole from "./RoleGroup/TimetableRole";
import SettingRole from "./RoleGroup/SettingRole";
import TechnicalRole from "./RoleGroup/TechnicalRole";
import ElementCategoryRole from "./RoleGroup/ElementCategoryRole";
import ManageTimetableRole from "./RoleGroup/ManageTimetableRole";
import ManagementRole from "./RoleGroup/ManagementRole";
import DepartmentRole from "./RoleGroup/DepartmentRole";
import PositionRole from "./RoleGroup/PositionRole";
import Role from "./RoleGroup/Role";
import UserRole from "./RoleGroup/UserRole";

// apollo-client
import { useQuery } from "@apollo/client"

// graphql-query
import { listRolesByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import { onCreateRole, onUpdateRole, onDeleteRole } from "../../../graphql/subscriptions"

const { Title } = Typography
const { Panel } = Collapse;

const RoleList = () => {
    const [visibleDeleteDrawer, setVisibleDeleteDrawer] = useState(false);
    const [extraData, setExtraData] = useState(null)

    const closeDeleteDrawer = () => {
        setVisibleDeleteDrawer(false);
    };

    // query role
    const { subscribeToMore, loading, error, data } = useQuery(listRolesByCreatedDate, {
        variables: {
            typeCreated: "RoleCreated",
            sortDirection: "DESC"
        }
    })

    // on create role
    useEffect(() =>
        subscribeToMore({
            document: onCreateRole,
            updateQuery: (prev, { subscriptionData }) => {
                const { listRolesByCreatedDate: { items } } = prev
                if (!subscriptionData.data) return prev
                const { onCreateRole: roleData } = subscriptionData.data
                return {
                    listRolesByCreatedDate: {
                        items: [roleData, ...items],
                        nextToken: null,
                        __typename: "Role"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on update role
    useEffect(() =>
        subscribeToMore({
            document: onUpdateRole,
            updateQuery: (prev) => {
                const { listRolesByCreatedDate: { items } } = prev
                return {
                    listRolesByCreatedDate: {
                        items: [...items],
                        nextToken: null,
                        __typename: "Role"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on delete role
    useEffect(() =>
        subscribeToMore({
            document: onDeleteRole,
            updateQuery: (prev, { subscriptionData }) => {
                const { listRolesByCreatedDate: { items } } = prev
                if (subscriptionData) {
                    const { onDeleteRole: roleData } = subscriptionData.data
                    const lastItems = items.filter(doc => doc.id !== roleData.id)
                    return {
                        listRolesByCreatedDate: {
                            items: [...lastItems],
                            nextToken: null,
                            __typename: "Role"
                        }
                    }
                }
            }
        }), [subscribeToMore]
    )

    const genExtra = (getExtraData) => {
        const { roleRefs } = getExtraData
        return (
            <>
                {
                    roleRefs !== "super-admin" ?
                        <Space size="middle">
                            <Button
                                size={"middle"}
                                type={null}
                                danger
                                icon={<DeleteOutlined />}
                                onClick={(event) => {
                                    setVisibleDeleteDrawer(true)
                                    setExtraData(getExtraData)
                                    event.stopPropagation();
                                }}
                            />
                            <UpdateRoleDrawer data={getExtraData} />
                        </Space> : null
                }
            </>
        )
    }

    if (loading) return <LottieLoading />
    if (error) console.log(error)
    if (data) {
        const { listRolesByCreatedDate: { items } } = data
        return (
            <Row gutter={24}>
                <Col span={24}>
                    <CreateRoleDrawer />
                </Col>
                <Col span={24} style={{ marginTop: 20 }}>
                    <Collapse accordion>
                        {
                            items.map((item, index) => {
                                const { roleName } = item
                                return (
                                    <Panel
                                        header={<Title level={5}>{roleName}</Title>}
                                        key={index} extra={genExtra(item)}
                                    >
                                        <Row gutter={24}>
                                            <ProjectRole item={item} />
                                            <ElementRole item={item} />
                                            <SubElementRole item={item} />
                                            <PlanningRole item={item} />
                                            <TimetableRole item={item} />

                                        </Row>
                                        <Divider />
                                        <Row gutter={24}>
                                            <SettingRole item={item} />

                                            {/* technical */}
                                            <Col span={10} style={{ marginLeft: 30 }}>
                                                <TechnicalRole item={item} />
                                                <Row gutter={24} style={{ marginLeft: 20 }}>
                                                    <ElementCategoryRole item={item} />
                                                    <ManageTimetableRole item={item} />
                                                </Row>
                                            </Col>

                                            {/* management */}
                                            <Col span={12} style={{ marginLeft: 30 }}>
                                                <ManagementRole item={item} />
                                                <Row gutter={24} style={{ marginLeft: 20 }}>
                                                    <DepartmentRole item={item} />
                                                    <PositionRole item={item} />
                                                    <Role item={item} />
                                                    <UserRole item={item} />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Panel>
                                )
                            })
                        }
                    </Collapse>
                </Col>
                <DeleteRoleDrawer
                    data={extraData}
                    title="Delete the Role?"
                    visible={visibleDeleteDrawer}
                    closeDrawer={closeDeleteDrawer}
                />
            </Row >
        )
    }
}

export default RoleList