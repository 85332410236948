import { Button } from "antd"
import { FileExcelOutlined } from "@ant-design/icons"
import { Excel } from "antd-table-saveas-excel";
import moment from "moment"

const columns = [
    {
        title: "Priority",
        dataIndex: "priority",
        key: "priority"
    },
    {
        title: "Number of Drawing",
        dataIndex: "numberOfDrawing",
        key: "numberOfDrawing"
    },
    {
        title: "Ind",
        dataIndex: "index",
        key: "index"
    },
    {
        title: "Prov",
        dataIndex: "provNumber",
        key: "prov",
        render: ((_, record) => {
            const provNumber = record.provNumber ? record.provNumber : ""
            const provAlphabet = record.provAlphabet ? record.provAlphabet : ""
            return (
                `${provNumber}${provAlphabet}`
            )
        })
    },
    {
        title: "CTC",
        children: [
            {
                title: "Predicted Date",
                dataIndex: "ctcPredictedDate",
                key: "ctcPredictedDate",
                render: ((_, record) => {
                    return (
                        record.ctcPredictedDate !== null ?
                            moment(record.ctcPredictedDate).format('DD/MM/YYYY') : ""
                    )
                })
            },
        ]
    },
    {
        title: 'Client received on',
        children: [
            {
                title: 'Paper Version',
                dataIndex: 'entreprisPaperVersion',
                key: 'entreprisPaperVersion',
                render: ((_, record) => {
                    return (
                        record.entreprisPaperVersion !== null ?
                            moment(record.entreprisPaperVersion).format('DD/MM/YYYY') : ""
                    )
                })
            },
            {
                title: 'Nbr',
                dataIndex: 'entreprisNumber',
                key: 'entreprisNumber',
                render: ((_, record) => {
                    return (
                        record.entreprisNumber !== null ? record.entreprisNumber : ""
                    )
                })
            },
            {
                title: 'Fax/Internet',
                dataIndex: 'entreprisFax',
                key: 'entreprisFax',
                align: 'center',
                render: ((_, record) => {
                    return (
                        record.entreprisFax !== null ?
                            moment(record.entreprisFax).format('DD/MM/YYYY') : ""
                    )
                })
            },
        ],
    },
    {
        title: 'Controller received on',
        children: [
            {
                title: 'Paper Version',
                dataIndex: 'contractorPaperVersion',
                key: 'contractorPaperVersion',
                render: ((_, record) => {
                    return (
                        record.contractorPaperVersion !== null ?
                            moment(record.contractorPaperVersion).format('DD/MM/YYYY') : ""
                    )
                })
            },
            {
                title: 'Nbr',
                dataIndex: 'contractorNumber',
                key: 'contractorNumber',
                render: ((_, record) => {
                    return (
                        record.contractorNumber !== null ? record.contractorNumber : ""
                    )
                })
            },
            {
                title: 'Fax/Internet',
                dataIndex: 'contractorFax',
                key: 'contractorFax',
                align: 'center',
                render: ((_, record) => {
                    return (
                        record.contractorFax !== null ?
                            moment(record.contractorFax).format('DD/MM/YYYY') : ""
                    )
                })
            },
        ],
    },
    {
        title: 'Remard received on',
        children: [
            {
                title: 'Client',
                dataIndex: 'observationsEntrepris',
                key: 'observationsEntrepris',
                render: ((_, record) => {
                    return (
                        record.observationsEntrepris !== null ?
                            moment(record.observationsEntrepris).format('DD/MM/YYYY') : ""
                    )
                })
            },
            {
                title: 'Designer',
                dataIndex: 'observationsProjectDirector',
                key: 'observationsProjectDirector',
                render: ((_, record) => {
                    return (
                        record.observationsProjectDirector !== null ?
                            moment(record.observationsProjectDirector).format('DD/MM/YYYY') : ""
                    )
                })
            },
            {
                title: 'Controller',
                dataIndex: 'observationsConsultant',
                key: 'observationsConsultant',
                align: 'center',
                render: ((_, record) => {
                    return (
                        record.observationsConsultant !== null ?
                            moment(record.observationsConsultant).format('DD/MM/YYYY') : ""
                    )
                })
            },
        ],
    },
    {
        title: 'Dscription',
        dataIndex: 'description',
        key: 'description'
    }
];

const ExportToExcel = ({ dataSource }) => {
    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("SubElementList")
            .addColumns(columns)
            .addDataSource(dataSource, {
                str2Percent: true
            })
            .saveAs("SubElementList.xlsx");
    };

    return (
        <Button
            type="primary"
            size="large"
            onClick={handleClick}
            icon={<FileExcelOutlined />}
            style={{ float: "right", marginTop: -65, marginBottom: 20 }}
        >
            Export To Excel
        </Button>
    )
}

export default ExportToExcel