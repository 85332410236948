import { useState, useEffect } from "react"
import { Layout, Button, Row, Col } from "antd"
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import "./Element.less"
import Cookies from 'universal-cookie';

// react-router
import { useParams } from 'react-router-dom'

// internal-component
import CreateElementDrawer from "./Drawers/CreateElementDrawer"
import SelectElementCategory from "./components/SelectElementCategory";
import ElementList from "./components/ElementList";

// amplify
import { API, graphqlOperation } from "aws-amplify"

// mutation-query
import {
    listUsersByCreatedDate,
    listRolesByCreatedDate
} from "../../graphql/queries"

// graphql-sub
import {
    onUpdateUser,
    onUpdateRole
} from "../../graphql/subscriptions"

const { Sider } = Layout;
const cookies = new Cookies();

const Element = () => {
    const [collapsed, setCollapsed] = useState(false)

    // check-role-state
    const [user, setUser] = useState(null)
    const [roleId, setRoleId] = useState(null)
    const [roles, setRoles] = useState(null)
    const [role, setRole] = useState(null)
    const userEmail = cookies.get('userEmail')

    const { id: projectId } = useParams()

    let subscriptionOnUpdateUser;
    let subscriptionOnUpdateRole;

    // execute sub user and role
    useEffect(() => {
        subOnUpdateUser()
        subOnUpdateRole()
        return () => {
            subscriptionOnUpdateUser.unsubscribe()
            subscriptionOnUpdateRole.unsubscribe()
        }
    }, [])

    useEffect(() => {
        queryUser()
    }, [user])

    useEffect(() => {
        queryRole()
    }, [role, roleId])

    const queryUser = async () => {
        try {
            const userData = await API.graphql(graphqlOperation(listUsersByCreatedDate, {
                typeCreated: "UserCreated",
                filter: {
                    email: { eq: userEmail }
                }
            }))
            const userItem = userData.data.listUsersByCreatedDate.items[0]
            setRoleId(userItem.roleUserId)
        } catch (err) {
            console.log(err)
        }
    }

    const queryRole = async () => {
        try {
            const roleData = await API.graphql(graphqlOperation(listRolesByCreatedDate, {
                typeCreated: "RoleCreated",
                filter: {
                    id: { eq: roleId ? roleId : null }
                }
            }))
            const roleItem = roleData.data.listRolesByCreatedDate.items[0]
            setRoles(roleItem)
        } catch (err) {
            console.log(err)
        }
    }

    // sub-update-user
    const subOnUpdateUser = async () => {
        try {
            subscriptionOnUpdateUser = await API.graphql(graphqlOperation(onUpdateUser))
                .subscribe({
                    next: ({ value }) => {
                        setUser(value.data.onUpdateUser)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub-update-role
    const subOnUpdateRole = async () => {
        try {
            subscriptionOnUpdateRole = await API.graphql(graphqlOperation(onUpdateRole))
                .subscribe({
                    next: ({ value }) => {
                        setRole(value.data.onUpdateRole)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    const onCollapse = () => {
        setCollapsed(prevCollapsed => !prevCollapsed);
    };

    return (
        <Sider
            className="element-list-wrapper"
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={450}
            style={{ position: "relative", height: "90vh", marginRight: 5, backgroundColor: "#fff", padding: 16,paddingTop:0, marginLeft: 5 }}
        >
            <Row style={{ marginBottom: 20 }}>
                <div className="element-list-header">
                    <Col span={collapsed ? 20 : 4}>
                        <Button
                            onClick={onCollapse}
                            type={collapsed ? null : "primary"}
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            style={{ marginBottom: 10 }}
                        />
                    </Col>
                    <Col span={!collapsed && 20}>
                        {/* <CreateElementDrawer collapsed={collapsed}/> */}
                        {!collapsed && roles && roles.createElement === true && <CreateElementDrawer />}
                    </Col>
                </div>
            </Row>
            <SelectElementCategory collapsed={collapsed} />
        </Sider>
    )
}

export default Element