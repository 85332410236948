import { useState, forwardRef, useImperativeHandle } from "react"
import { Row, Col, Typography, Form, Divider, Checkbox, Card } from "antd"
import "../CreateRoleDrawer.less"

const { Title } = Typography
const CheckboxGroup = Checkbox.Group;

// planning-options
const planningOptions = [
    {
        label: 'Read All Planning',
        value: 'readAllPlanning'
    },
    {
        label: 'Read Own Planning',
        value: 'readOwnPlanning'
    },
    {
        label: 'Create',
        value: 'createPlanning'
    },
    {
        label: 'Update',
        value: 'updatePlanning'
    },
    {
        label: 'Delete',
        value: 'deletePlanning'
    }
];

const CheckPlanningGroup = forwardRef((props, ref) => {
    const [checkedPlanningGroup, setCheckedPlanningGroup] = useState(null);
    const {
        form,
        setIndeterminate,
        setCheckAll,
        checkedPlanning,
        setCheckedPlanning
    } = props

    useImperativeHandle(ref, () => ({
        onChangePlanning(checked) {
            setCheckedPlanningGroup(checked ? planningOptions.map(v => v.value) : []);
            setCheckedPlanning(checked)
            form.setFieldsValue({
                planningGroup: checked ? planningOptions.map(v => v.value) : []
            })
        }
    }))

    const onChangePlanning = (e) => {
        const checked = e.target.checked
        setCheckedPlanningGroup(checked ? planningOptions.map(v => v.value) : []);
        setCheckedPlanning(checked)
        setIndeterminate(false)
        form.setFieldsValue({
            planningPrivilege: checked,
            planningGroup: checked ? planningOptions.map(v => v.value) : []
        })
    }
    const onChangePlanningGroup = (list) => {
        setIndeterminate(!!list.length && list.length < planningOptions.length);
        setCheckAll(list.length === planningOptions.length);
    };

    return (
        <Col span={4}>
            <Card bordered={false} className="large-card-container">
                <Form.Item name="planningPrivilege" valuePropName="checked" style={{ marginBottom: -10, marginTop: 0 }}>
                    <Checkbox onChange={onChangePlanning} checked={checkedPlanning}>
                        <Title level={5} style={{ marginBottom: 0 }}>Planning</Title>
                    </Checkbox>
                </Form.Item>
                <Divider />
                <Form.Item name="planningGroup" style={{ marginBottom: 0, marginTop: -10 }}>
                    <Checkbox.Group>

                        <Row>
                            <Col span={24}>
                                <Checkbox
                                    value="readAllPlanning"
                                    disabled={checkedPlanning ? false : true}
                                    onChange={onChangePlanningGroup}
                                >
                                    Read All Planning
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="readOwnPlanning"
                                    disabled={checkedPlanning ? false : true}
                                    onChange={onChangePlanningGroup}
                                >
                                    Read Own Planning
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="createPlanning"
                                    disabled={checkedPlanning ? false : true}
                                    onChange={onChangePlanningGroup}
                                >
                                    Create
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="updatePlanning"
                                    disabled={checkedPlanning ? false : true}
                                    onChange={onChangePlanningGroup}
                                >
                                    Update
                                </Checkbox>
                            </Col>
                            <Col span={24} className="checkbox-group">
                                <Checkbox
                                    value="deletePlanning"
                                    disabled={checkedPlanning ? false : true}
                                    onChange={onChangePlanningGroup}
                                >
                                    Delete
                                </Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                    {/* <CheckboxGroup
                        disabled={checkedPlanning ? false : true}
                        options={planningOptions}
                        value={checkedPlanningGroup}
                        onChange={onChangePlanningGroup}
                    /> */}
                </Form.Item>
            </Card>
        </Col>
    )
})

export default CheckPlanningGroup