import { useState, useEffect, createRef } from "react"
import { Button, Drawer, Row, Col, Form, Input, Select, Divider } from "antd"
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons"

// external-component
import Notification from "../../../components/Notification/Notification"
import MySpin from "../../../components/Spin/Spin"

// aws-amplify
import { API, graphqlOperation } from "aws-amplify"

// mutation-update
import { updatePosition } from "../../../graphql/mutations"

// graphql-query
import { listDepartmentsByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateDepartment,
    onUpdateDepartment,
    onDeleteDepartment
} from "../../../graphql/subscriptions"

const UpdatePositionDrawer = (props) => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [departments, setDepartments] = useState(null)
    const [department, setDepartment] = useState(null)
    const [form] = Form.useForm()
    let formRef = createRef()

    const {
        id,
        positionName,
        departmentPositionId
    } = props.data || {}

    useEffect(() => {
        form.setFieldsValue({
            positionName: positionName,
            departmentPositionId: departmentPositionId
        })
    }, [])

    let subscriptionOnCreate;
    let subscriptionOnDelete;
    let subscriptionOnUpdate;

    // execute query 
    useEffect(() => {
        queryDepartments()
    }, [department])

    // execute subscription
    useEffect(() => {
        subOnCreateDepartment()
        subOnUpdateDepartment()
        subOnDeleteDepartment()
        return () => {
            subscriptionOnCreate.unsubscribe()
            subscriptionOnUpdate.unsubscribe()
            subscriptionOnDelete.unsubscribe()
        }
    }, [])

    // query departments
    const queryDepartments = async () => {
        try {
            const departmentData = await API.graphql(graphqlOperation(listDepartmentsByCreatedDate, {
                typeCreated: "DepartmentCreated",
                sortDirection: "DESC",
            }))
            const { data: { listDepartmentsByCreatedDate: { items } } } = departmentData
            setDepartments(items)
        } catch (error) {
            console.error(error)
        }
    }

    // sub create 
    const subOnCreateDepartment = async () => {
        try {
            subscriptionOnCreate = await API.graphql(graphqlOperation(onCreateDepartment))
                .subscribe({
                    next: ({ value }) => {
                        setDepartment(value.data.onCreateDepartment)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub update 
    const subOnUpdateDepartment = async () => {
        try {
            subscriptionOnUpdate = await API.graphql(graphqlOperation(onUpdateDepartment))
                .subscribe({
                    next: ({ value }) => {
                        setDepartment(value.data.onUpdateDepartment)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    // sub on delete 
    const subOnDeleteDepartment = async () => {
        try {
            subscriptionOnDelete = await API.graphql(graphqlOperation(onDeleteDepartment))
                .subscribe({
                    next: ({ value }) => {
                        setDepartment(value.data.onDeleteDepartment)
                    }
                })
        } catch (error) {
            console.error(error)
        }
    }

    const validateMessages = {
        required: '${label} is required!'
    };

    const onFinish = async (values) => {
        try {
            values.id = id
            setIsLoading(true)
            await API.graphql(graphqlOperation(updatePosition, { input: values }))
            Notification("success", "Success", "Position update successfully!")
            setIsLoading(false)
            closeDrawer()
        } catch (error) {
            console.log(error)
            Notification("error", "Failed", error.message)
        }
    };

    const closeDrawer = () => {
        setVisible(false);
    };

    return (
        <div>
            <Button
                type="primary"
                icon={<EditOutlined />}
                style={{ float: "right" }}
                onClick={() => setVisible(true)}
            />
            <Drawer
                title="Update Position"
                onClose={closeDrawer}
                visible={visible}
                width="500"
            >
                {
                    isLoading ? <MySpin /> :
                        <Form form={form} ref={formRef} name="nest-messages" layout="vertical" onFinish={onFinish} validateMessages={validateMessages}>
                            <Form.Item name="departmentPositionId" label="Department" rules={[{ required: true }]}>
                                <Select
                                    size="large"
                                >
                                    {
                                        departments && departments.map((data, key) => {
                                            const { id, departmentName } = data
                                            return <Select.Option key={key} value={id}>{departmentName}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="positionName" label="Position Name" rules={[{ required: true }]}>
                                <Input size="large" />
                            </Form.Item>
                            <Divider />
                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Button onClick={() => setVisible(false)} icon={<CloseCircleOutlined />} block size="large" type="default">
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button icon={<EditOutlined />} block size="large" type="primary" htmlType="submit">
                                            Update
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                }
            </Drawer>
        </div>
    )
}

export default UpdatePositionDrawer