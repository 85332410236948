
import { useState, useEffect } from "react"
import { Table, Space, Form, Input, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

// external-component
import DeleteRecordDrawer from "../../../components/DeleteRecordDrawer/DeleteRecordDrawer";
import LottieLoading from "../../../components/LottieLoading/LottieLoading";

// drawer
import CreateElementCategoryDrawer from './CreateElementCategoryDrawer';
import UpdateElementCategoryDrawer from "./UpdateElementCategoryDrawer";

// apollo-client
import { useQuery, useLazyQuery } from "@apollo/client"

// graphql-query
import { listElementCategorysByCreatedDate } from "../../../graphql/queries"

// graphql-sub
import {
    onCreateElementCategory,
    onUpdateElementCategory,
    onDeleteElementCategory
} from "../../../graphql/subscriptions"

const columns = [
    {
        title: 'ElementCategory Name',
        dataIndex: 'categoryName',
        key: 'categoryName',
        width: '90%'
    },
    {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record) => (
            <Space size="middle">
                <DeleteRecordDrawer
                    id={record.id}
                    module="elementCategory"
                    title="Delete the Element Category?"
                    message={`Category: ${record.categoryName}`}
                />
                <UpdateElementCategoryDrawer data={record} />
            </Space>
        ),
    },
];

const ElementCategoryDatatable = () => {
    const [searchParams, setSetParams] = useState(null)

    useEffect(() => {
        searchElementCategory()
    }, [])

    // query element category
    const [searchElementCategory, { subscribeToMore, loading, error, data }] = useLazyQuery(listElementCategorysByCreatedDate, {
        variables: {
            typeCreated: "ElementCategoryCreated",
            sortDirection: "DESC",
            // filter: {
            //     elementName: {
            //         contains: searchParams ? searchParams : ""
            //     }
            // }
        }
    })

    // on create element category
    useEffect(() =>
        subscribeToMore({
            document: onCreateElementCategory,
            updateQuery: (prev, { subscriptionData }) => {
                const { listElementCategorysByCreatedDate: { items } } = prev
                if (!subscriptionData.data) return prev
                const { onCreateElementCategory: elementCategoryData } = subscriptionData.data
                return {
                    listElementCategorysByCreatedDate: {
                        items: [elementCategoryData, ...items],
                        nextToken: null,
                        __typename: "ElementCategory"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on update element category
    useEffect(() =>
        subscribeToMore({
            document: onUpdateElementCategory,
            updateQuery: (prev) => {
                const { listElementCategorysByCreatedDate: { items } } = prev
                return {
                    listElementCategorysByCreatedDate: {
                        items: [...items],
                        nextToken: null,
                        __typename: "ElementCategory"
                    }
                }
            }
        }), [subscribeToMore]
    )

    // on delete element category
    useEffect(() =>
        subscribeToMore({
            document: onDeleteElementCategory,
            updateQuery: (prev, { subscriptionData }) => {
                const { listElementCategorysByCreatedDate: { items } } = prev
                if (subscriptionData) {
                    const { onDeleteElementCategory: elementCategoryData } = subscriptionData.data
                    const lastItems = items.filter(doc => doc.id !== elementCategoryData.id)
                    return {
                        listElementCategorysByCreatedDate: {
                            items: [...lastItems],
                            nextToken: null,
                            __typename: "ElementCategory"
                        }
                    }
                }
            }
        }), [subscribeToMore]
    )

    const handleSearch = (e) => {
        console.log(e)
    }

    if (loading) return <LottieLoading />
    if (error) console.log(error)
    if (data) {
        const { listElementCategorysByCreatedDate: { items } } = data
        return (
            <div>
                <Row gutter={24}>
                    <Col span={20}>
                        <Form name="control-hooks">
                            <Form.Item name="note">
                                <Input size="large" onChange={(e) => handleSearch(e)} placeholder="Search elementCategory" prefix={<SearchOutlined />} />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={4}>
                        <CreateElementCategoryDrawer />
                    </Col>
                </Row>
                <Table
                    size="small"
                    scroll={{ y: 900 }}
                    columns={columns}
                    dataSource={items}
                />
            </div>
        )
    }
}

export default ElementCategoryDatatable