import { API, graphqlOperation } from "aws-amplify"
import { deleteSubElement } from "../../graphql/mutations"
import { listSubElements } from "../../graphql/queries"

export const deleteSubElementById = async (id) => {
    try {
        await API.graphql(graphqlOperation(deleteSubElement, { input: { id } }))
    } catch (err) {
        console.log(err)
    }
}

export const deleteSubElementByProjectId = async (projectId) => {
    try {
        const subElementData = await API.graphql(graphqlOperation(listSubElements, {
            filter: {
                projectSubElementId: { eq: projectId }
            }
        }))
        const { data: { listSubElements: { items: subElementItems } } } = subElementData
        if (subElementItems.length > 0) {
            subElementItems.forEach(async (value) => {
                const subElementId = value.id
                await API.graphql(graphqlOperation(deleteSubElement, { input: { id: subElementId } }))
            })
        }
    } catch (err) {
        console.log(err)
    }
}